import * as yup from "yup";
import { TFunction } from "i18next";
import { IChangeStatusTriggerFormField } from "../../../../models/Automation";

export const getChangeStatusTriggerFormField = (t: TFunction) =>
  yup.object().shape({
    statusesFrom: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string(),
        })
      )
      .nullable()
      .transform((val) => (!val ? [] : val))
      .min(1, t("register.message") as string),
    statusesTo: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string(),
        })
      )
      .nullable()
      .transform((val) => (!val ? [] : val))
      .min(1, t("register.message") as string),
  });

export const changeStatusTriggerFormFieldsDefaultValue: IChangeStatusTriggerFormField =
  {
    statusesFrom: [],
    statusesTo: [],
  };
