import React from "react";
import { TextField, InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import { IKOQuestionControlledTextField } from "../../../models/JobPreview";
import { StyledKoQuestionContainer } from "./styles";

const KoQuestionControlledTextField = ({
  control,
  errors,
  title,
  value,
  placeholder,
}: IKOQuestionControlledTextField) => (
  <StyledKoQuestionContainer
    pt={errors.location && value === "highestDegree" ? 1.5 : 0}
  >
    <InputLabel className="required-label">{title}</InputLabel>
    <Controller
      name={value}
      control={control}
      render={({ field }) => (
        <TextField
          placeholder={placeholder}
          error={Boolean(errors[value])}
          helperText={errors[value]?.message}
          fullWidth
          {...field}
        />
      )}
    />
  </StyledKoQuestionContainer>
);

export default KoQuestionControlledTextField;
