import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ApplicationStatusUpdatedTriggerComponent from "./component";
import { getAutomationState } from "../../../../store/selectors/Automation";
import {
  setCurrentAutomation,
  setSelectedFromStatuses,
  setSelectedToStatuses,
  setTrigger,
} from "../../../../store/reducers/Automation";
import { getWorkflowState } from "../../../../store/selectors/Workflow";
import { useForm } from "react-hook-form";
import {
  changeStatusTriggerFormFieldsDefaultValue,
  getChangeStatusTriggerFormField,
} from "./config";
import { yupResolver } from "@hookform/resolvers/yup";
import { TListOption } from "../../../../models/common";
import { IAutomationCondition } from "../../../../models/Automation";

const ApplicationStatusUpdatedTrigger = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { currentAutomation, selectedFromStatuses, selectedToStatuses } =
    useSelector(getAutomationState);

  const { workflows } = useSelector(getWorkflowState);

  const {
    formState: { errors },
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    defaultValues: changeStatusTriggerFormFieldsDefaultValue,
    resolver: yupResolver(getChangeStatusTriggerFormField(t)),
  });

  const allStatuses = workflows.flatMap((workflow) =>
    workflow.statuses.map((status) => ({
      label:
        workflow.title === t("workflow.defaultWorkflow")
          ? `${status.label} (${t("workflow.allWorkflows")})`
          : `${status.label} (Workflow:${workflow.title})`,
      value: status.value,
      color: status.color,
    }))
  );

  const allUniqueStatuses = allStatuses.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.value === item.value)
  );

  const defaultStatuses = [
    {
      label: "New (All Workflows)",
      value: "new",
      color: "#818CF8",
    },
    {
      label: "In Progress (All Workflows)",
      value: "review",
      color: "#F59E0B",
    },
    {
      label: "Hired (All Workflows)",
      value: "hired",
      color: "#22C55E",
    },
    {
      label: "Rejected (All Workflows)",
      value: "rejected",
      color: "#EF4444",
    },
  ];

  const formattedStatuses = allUniqueStatuses.length
    ? allUniqueStatuses
    : defaultStatuses;

  const statuses = useMemo(() => {
    let toStatuses = formattedStatuses;
    let fromStatuses = formattedStatuses;
    if (currentAutomation?.conditions[1]?.value) {
      const filtredFromStatuses = formattedStatuses.filter(
        (status) => status.value !== currentAutomation?.conditions[1]?.value
      );
      fromStatuses = filtredFromStatuses;
    }
    if (currentAutomation?.conditions[0]?.value) {
      const filteredToStatuses = formattedStatuses.filter(
        (status) => status.value !== currentAutomation?.conditions[0]?.value
      );
      toStatuses = filteredToStatuses;
    }
    return {
      toStatuses,
      fromStatuses,
    };
  }, [currentAutomation.conditions]);

  const handleChangeFromStatuses = (
    values: TListOption[] | string | string[]
  ) => {
    dispatch(setSelectedFromStatuses(values as TListOption[]));
    if (errors.statusesFrom && values.length) {
      clearErrors("statusesFrom");
    }
    const fromStatusesConditionOperator = values.length
      ? Array(values.length - 1).fill("OR")
      : [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const formattedFromStatuses = values?.map((status: TListOption) => ({
      condition: "equals",
      param: "old_status",
      value: status.value,
    }));

    const toStatusConditions =
      currentAutomation?.conditions_operators &&
      currentAutomation?.conditions_operators[1]
        ? currentAutomation?.conditions_operators[1]
        : [];
    const conditionsOperators = [
      fromStatusesConditionOperator,
      toStatusConditions,
    ];
    if (currentAutomation.trigger) {
      dispatch(
        setCurrentAutomation({
          ...currentAutomation,
          trigger: {
            ...currentAutomation.trigger,
            params: {
              ...currentAutomation.trigger.params,
            },
          },
          conditions: [formattedFromStatuses, currentAutomation.conditions[1]],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          conditions_operators: conditionsOperators,
        })
      );
    }
  };

  const handleChangeToStatuses = (
    values: TListOption[] | string[] | string
  ) => {
    dispatch(setSelectedToStatuses(values as TListOption[]));

    if (errors.statusesTo && values.length) {
      clearErrors("statusesTo");
    }
    const fromStatusConditionsOperator =
      currentAutomation?.conditions_operators &&
      currentAutomation?.conditions_operators[0]
        ? currentAutomation?.conditions_operators[0]
        : [];
    const toStatusesConditionOperator = values.length
      ? Array(values.length - 1).fill("OR")
      : [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const formattedToStatuses = values?.map((status: TListOption) => ({
      condition: "equals",
      param: "new_status",
      value: status.value,
    }));
    const conditionsOperators = [
      fromStatusConditionsOperator,
      toStatusesConditionOperator,
    ];
    if (currentAutomation.trigger) {
      dispatch(
        setCurrentAutomation({
          ...currentAutomation,
          trigger: {
            ...currentAutomation.trigger,
            params: {
              ...currentAutomation.trigger.params,
            },
          },
          conditions: [currentAutomation.conditions[0], formattedToStatuses],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          conditions_operators: conditionsOperators,
        })
      );
    }
  };

  const handleSave = () => {
    if (currentAutomation.trigger) {
      dispatch(
        setTrigger({
          ...currentAutomation?.trigger,
          isCompleted: true,
          isOpen: false,
        })
      );
    }
  };

  useEffect(() => {
    if (
      currentAutomation?.conditions &&
      currentAutomation.trigger &&
      !currentAutomation.trigger.isOpen
    ) {
      if (currentAutomation.conditions[0]) {
        const currentFromStatuses = currentAutomation.conditions[0].map(
          (condition: IAutomationCondition) => {
            const formattedCondition = allStatuses.find(
              (status) => status.value === condition.value
            );
            return formattedCondition;
          }
        );
        dispatch(setSelectedFromStatuses(currentFromStatuses));
      }
      if (currentAutomation.conditions[1]) {
        const currentToStatuses = currentAutomation.conditions[1].map(
          (condition: IAutomationCondition) => {
            const formattedCondition = allStatuses.find(
              (status) => status.value === condition.value
            );
            return formattedCondition;
          }
        );
        dispatch(setSelectedToStatuses(currentToStatuses));
      }
    }
  }, [currentAutomation]);

  useEffect(() => {
    setValue("statusesFrom", selectedFromStatuses);
  }, [selectedFromStatuses]);

  useEffect(() => {
    setValue("statusesTo", selectedToStatuses);
  }, [selectedToStatuses]);

  return (
    <ApplicationStatusUpdatedTriggerComponent
      t={t}
      currentAutomation={currentAutomation}
      statuses={statuses}
      selectedFromStatuses={selectedFromStatuses}
      selectedToStatuses={selectedToStatuses}
      errors={errors}
      onChangeFromStatuses={handleChangeFromStatuses}
      onChangeToStatuses={handleChangeToStatuses}
      onSubmit={handleSave}
      handleSubmit={handleSubmit}
    />
  );
};

export default ApplicationStatusUpdatedTrigger;
