import React, { useEffect, useMemo } from "react";
import Flag from "react-country-flag";
import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Typography,
  InputLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { debounce } from "debounce";
import { useSelector, useDispatch } from "react-redux";
import {
  StyledInputContainer,
  StyledInputFlagContainer,
  StyledOptionContainer,
} from "../../../components/filters/styles";
import { fetchCities } from "../../../store/reducers/JobPreview";
import { getJobPreviewState } from "../../../store/selectors/JobPreview";
import { IKOQuestionField } from "../../../models/JobPreview";
import { StyledKoQuestionContainer } from "./styles";

const KOQuestionLocation = ({ t, control, errors }: IKOQuestionField) => {
  const dispatch = useDispatch();

  const { cities } = useSelector(getJobPreviewState);

  useEffect(() => {
    dispatch(fetchCities({ city: "Berlin" }));
  }, []);

  const handleOnSearch = useMemo(
    () =>
      debounce((city: string) => {
        dispatch(fetchCities({ city }));
      }, 500),
    []
  );

  return (
    <StyledKoQuestionContainer
      mt={errors.phone ? "0px !important" : "12px !important"}
    >
      <InputLabel className="required-label">
        {t("joblist.location_placeholder")}
      </InputLabel>
      <Controller
        name="location"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            noOptionsText={t("additional_questions.city_no_options")}
            options={cities}
            getOptionLabel={(option: any) =>
              option?.state
                ? `${option.name}, ${option.state},  ${option.country}`
                : `${option.name},  ${option.country}`
            }
            onInputChange={(_, value) => {
              handleOnSearch(value);
            }}
            onChange={(_, value) => {
              onChange(value || "");
            }}
            renderInput={(params) => (
              <StyledInputContainer>
                <TextField
                  {...params}
                  error={Boolean(errors.location)}
                  helperText={errors.location?.message}
                  placeholder={t("update_company_information.city") as string}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: value?.countrycode && (
                      <InputAdornment position="start">
                        <StyledInputFlagContainer>
                          <Flag countryCode={value?.countrycode} svg />
                        </StyledInputFlagContainer>
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledInputContainer>
            )}
            renderOption={(optionProps, option) => (
              <StyledOptionContainer
                {...optionProps}
                translate="no"
                key={option.countrycode}
              >
                <Box>
                  <Flag countryCode={option.countrycode} svg />
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    {option?.state
                      ? `${option.name}, ${option.state},  ${option.country}`
                      : `${option.name},  ${option.country}`}
                  </Typography>
                </Box>
              </StyledOptionContainer>
            )}
          />
        )}
      />
    </StyledKoQuestionContainer>
  );
};

export default KOQuestionLocation;
