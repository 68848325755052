// chart configs
import moment from "moment";
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";
import { ApexOptions } from "apexcharts";
import { RangeType } from "rsuite/esm/DateRangePicker/types";
import { TFunction } from "i18next";
import translationEn from "../../utils/translationEn";

export const NOAChartOptions: ApexOptions = {
  chart: { toolbar: { show: false }, zoom: { enabled: false }, type: "area" },
  dataLabels: { enabled: false },
  stroke: { curve: "straight" },
  yaxis: {
    min: 0,
    labels: {
      formatter: (val: number) => val.toFixed(0),
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      type: "horizontal",
      colorStops: [
        { color: "#FF6E7F", offset: 0, opacity: 0.5 },
        { color: "#BFE9FF", offset: 100, opacity: 0.5 },
      ],
    },
  },
};

export const getPredefinedRanges = (t: TFunction): RangeType[] => {
  const ranges: RangeType[] = [
    {
      label: t("analytics.today") as string,
      value: [new Date(), new Date()],
      placement: "left",
    },
    {
      label: t("analytics.yesterday") as string,
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: "left",
    },
    {
      label: t("analytics.thisWeek") as string,
      value: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
      placement: "left",
    },
    {
      label: t("analytics.last7Days") as string,
      value: [subDays(new Date(), 6), new Date()],
      placement: "left",
    },
    {
      label: t("analytics.thisMonth") as string,
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
    },
    {
      label: t("analytics.lastMonth") as string,
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
    },
  ];

  if (moment(new Date()).day() === 0) ranges.splice(3, 1); // remove "Last 7 days" if today is Sunday

  return ranges;
};

export const getPerformedName = ({
  name,
  path,
}: {
  name: string;
  path: "defaultStatuses" | "source";
}) =>
  [...Object.keys(translationEn.analytics[path])].includes(name)
    ? `analytics.${path}.${name}`
    : name;
