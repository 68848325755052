import {
  TApiAddAutomationPayload,
  TApiFetchAutomationEntity,
  TApiFetchAutomationsPayload,
} from "../models/Automation";
import { apiRequest } from "./config";

export const apiFetchAutomations = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiFetchAutomationsPayload;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiFetchTriggers = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiFetchActions = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiDeleteAutomation = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiFetchEntity = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiFetchAutomationEntity;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiAddUpdateAutomation = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiAddAutomationPayload;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiAddUpdateAutomationStatus = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });
