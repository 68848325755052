import * as yup from "yup";
import { TAdditionalQuestionsFormFields } from "../../models/JobPreview";

export const additionalQuestionsFormFieldsSchema = yup.object().shape({
  phone: yup.string(),
  city: yup.string(),
  country: yup.string(),
  workingPermitEU: yup.string(),
  salary: yup.string(),
  earliestStartDate: yup.string(),
  professionalStatus: yup.string(),
  education: yup.string(),
  driverLicense: yup.array().of(
    yup.object().shape({
      value: yup.string(),
      label: yup.string(),
    })
  ),
  languages: yup.array().of(
    yup.object().shape({
      language: yup.string(),
      level: yup.string(),
    })
  ),
  skills: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      title: yup.string(),
    })
  ),
  documents: yup.array().of(
    yup.object().shape({
      doc: yup.mixed().nullable(),
      name: yup.string(),
    })
  ),
  photo: yup.object().shape({
    image: yup.mixed().nullable(),
    name: yup.string(),
  }),
  questions: yup.array().of(
    yup.object().shape({
      answer: yup.string(),
      question_id: yup.string(),
    })
  ),
});

export const defaultAdditionalQuestionsValues: TAdditionalQuestionsFormFields =
  {
    phone: "",
    city: "",
    country: "",
    workingPermitEU: "1",
    salary: "",
    earliestStartDate: "",
    professionalStatus: "",
    education: "",
    driverLicense: [],
    languages: [{ language: "", level: "" }],
    skills: [],
    documents: [
      { file: undefined, name: "" },
      { file: undefined, name: "" },
      { file: undefined, name: "" },
    ],
    photo: { file: undefined, name: "" },
    questions: [],
  };
