import React from "react";
import { Stack, Typography } from "@mui/material";
import Integration from "./Integration";
import ConnectIntegrationDialog from "../../components/dialogs/ConnectIntegrationDialog";
import ConnectDialog from "../SingleJob/BasicJobBoard/ConnectDialog";
import { IIntegrationsComponent } from "../../models/Integrations";

const IntegrationsComponent = ({
  t,
  integrations,
  externalProfiles,
  isConnectExternalProfileDialogOpen,
  isConnectIntegrationDialogOpen,
  selectedIntegration,
  showPassword,
  errors,
  companyProfileUrl,
  quickApplyInterfaces,
  register,
  onChangeExternalProfileUrl,
  onChangeShowPassword,
  onClose,
  onConnect,
  onSaveExternalProfile,
  onOpenConnectDialog,
  handleSubmit,
}: IIntegrationsComponent) => (
  <Stack>
    <Stack>
      <Typography variant="body1" pb={1.5}>
        {t("integrations.platforms")}
      </Typography>
      <Stack direction="row" flexWrap="wrap" gap={1.5}>
        {integrations.map((integration) => (
          <Integration
            t={t}
            key={integration.title}
            integration={integration}
            onOpenConnectDialog={onOpenConnectDialog}
          />
        ))}
      </Stack>
    </Stack>
    <Stack mt={3}>
      <Typography variant="body1" pb={1.5}>
        {t("integrations.externalProfiles")}
      </Typography>
      <Stack direction="row" flexWrap="wrap" gap={1.5}>
        {externalProfiles.map((integration) => (
          <Integration
            t={t}
            key={integration.title}
            integration={integration}
            onOpenConnectDialog={onOpenConnectDialog}
          />
        ))}
      </Stack>
    </Stack>
    <Stack mt={3}>
      <Typography variant="body1" pb={1.5}>
        {t("integrations.quickApplyInterfaces")}
      </Typography>
      <Stack direction="row" flexWrap="wrap" gap={1.5}>
        {quickApplyInterfaces.map((integration) => (
          <Integration
            t={t}
            key={integration.title}
            integration={integration}
            onOpenConnectDialog={onOpenConnectDialog}
          />
        ))}
      </Stack>
    </Stack>
    <ConnectIntegrationDialog
      t={t}
      isOpen={isConnectIntegrationDialogOpen}
      selectedIntegration={selectedIntegration}
      showPassword={showPassword}
      errors={errors}
      register={register}
      onChangeShowPassword={onChangeShowPassword}
      onSave={onConnect}
      onClose={onClose}
      handleSubmit={handleSubmit}
    />
    <ConnectDialog
      t={t}
      companyProfileUrl={companyProfileUrl}
      jobBoard={selectedIntegration}
      isLoading={false}
      isOpen={isConnectExternalProfileDialogOpen}
      onClose={onClose}
      onSave={onSaveExternalProfile}
      onChangeUrl={onChangeExternalProfileUrl}
    />
  </Stack>
);

export default IntegrationsComponent;
