import { DefaultTFuncReturn, TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { ISingleJobState } from "../../../../models/SingleJob";
import { formatSalaryValue } from "../../../../utils";

export const getJobDescriptionFields = (
  jobTemplate: ISingleJobState["jobTemplate"]
) => [
  {
    title: jobTemplate?.introduction_title,
    body: jobTemplate?.introduction,
  },
  {
    title: jobTemplate?.tasks_title,
    body: jobTemplate?.tasks,
  },
  {
    title: jobTemplate?.profile_title,
    body: jobTemplate?.profile,
  },
  {
    title: jobTemplate?.offer_title,
    body: jobTemplate?.offer,
  },
];

export const getJobDescriptionOptionalFields = (
  jobTemplate: ISingleJobState["jobTemplate"]
) => [
  {
    title: jobTemplate?.contact_title,
    body: jobTemplate?.contact,
  },
  {
    title: "Video",
    body: jobTemplate?.video_url,
  },
];

export const getJobDetailsFields = ({
  t,
  jobDetails,
}: {
  t: TFunction;
  jobDetails: ISingleJobState["jobDetails"];
}): {
  flags?: string[];
  placeholder: DefaultTFuncReturn;
  value?: string | null;
}[][] => [
  [
    {
      placeholder: t("create_job_second_step.contract_type"),
      value: jobDetails?.contract_type,
    },
    {
      placeholder: t("create_job_second_step.field_of_work"),
      value: jobDetails?.work_type,
    },
  ],
  [
    {
      placeholder: t("create_job_second_step.seniority"),
      value: jobDetails?.seniority_level,
    },
    {
      placeholder: t("create_job_second_step.job_type"),
      value: jobDetails?.position_type,
    },
  ],
  [
    {
      placeholder: t("create_job_fourth_step.industry"),
      value: jobDetails?.industry,
    },
    {
      placeholder: t("create_job_second_step.qualification_level"),
      value: jobDetails?.qualification_title,
    },
  ],
  [
    {
      placeholder: t("create_job_fourth_step.reference_number"),
      value: jobDetails?.reference_number,
    },
    {
      placeholder: t("create_job_second_step.keywords"),
      value: jobDetails?.keywords || " ",
    },
  ],
  [
    {
      placeholder: t("create_job_second_step.minimum_salary"),
      value: formatSalaryValue(jobDetails?.min_salary as unknown as number),
    },
    {
      placeholder: t("create_job_second_step.maximum_salary"),
      value: formatSalaryValue(jobDetails?.max_salary as unknown as number),
    },
  ],
  [
    {
      placeholder: t("create_job_second_step.currency"),
      value: jobDetails?.salary_currency
        ? t(
            `singleJob.jobDetails.salary.currencies.${jobDetails?.salary_currency}`
          )
        : "",
    },
    {
      placeholder: t("create_job_second_step.interval"),
      value: jobDetails?.salary_type
        ? t(`singleJob.jobDetails.salary.type.${jobDetails?.salary_type}`)
        : "",
    },
  ],
];

const getParsedStatus = (
  koStatus?: string | number,
  showStatus?: string | number
) => {
  const { t } = useTranslation();
  const formattedStatus =
    koStatus == "1"
      ? t("create_job_third_step.ko_question")
      : showStatus == "1"
        ? t("status.active").toLowerCase()
        : t("status.inactive");

  return formattedStatus;
};

export const getJobBasicQuestionsFields = ({
  t,
  jobDetails,
}: {
  t: TFunction;
  jobDetails: ISingleJobState["jobDetails"];
}) => [
  {
    title: t("create_job_third_step.phone_number"),
    status: getParsedStatus(jobDetails?.phone_required, jobDetails?.phone_show),
  },
  {
    title: t("joblist.location_placeholder"),
    status: getParsedStatus(
      jobDetails?.location_required,
      jobDetails?.location_show
    ),
  },
  {
    title: t("create_job_third_step.eu_working_permit"),
    status: getParsedStatus(
      jobDetails?.working_permit_eu_required,
      jobDetails?.working_permit_eu_show
    ),
  },
  {
    title: t("create_job_third_step.salary_expectation"),
    status: getParsedStatus(
      jobDetails?.salary_expectation_required,
      jobDetails?.salary_expectation_show
    ),
  },
  {
    title: t("create_job_third_step.earliest_start_date"),
    status: getParsedStatus(
      jobDetails?.earliest_start_date_required,
      jobDetails?.earliest_start_date_show
    ),
  },
  {
    title: t("create_job_third_step.driving_licenses"),
    status: getParsedStatus(
      jobDetails?.drivers_license_required,
      jobDetails?.drivers_license_show
    ),
  },
  {
    title: t("create_job_third_step.current_position"),
    status: getParsedStatus(
      jobDetails?.current_job_required,
      jobDetails?.current_job_show
    ),
  },
  {
    title: t("create_job_third_step.highest_degree"),
    status: getParsedStatus(
      jobDetails?.highest_degree_required,
      jobDetails?.highest_degree_show
    ),
  },
  {
    title: t("create_job_third_step.language_skills"),
    status: getParsedStatus(jobDetails?.langs_required, jobDetails?.langs_show),
  },
  {
    title: t("create_job_third_step.skills"),
    status: getParsedStatus(
      jobDetails?.skills_required,
      jobDetails?.skills_show
    ),
  },
];

export const getJobDocumentsFields = ({
  t,
  jobDetails,
}: {
  t: TFunction;
  jobDetails: ISingleJobState["jobDetails"];
}) => [
  {
    title: "CV",
    status: getParsedStatus(jobDetails?.cv_required, jobDetails?.cv_show),
  },
  {
    title: t("create_job_third_step.additional_documents"),
    status: getParsedStatus(
      jobDetails?.other_documents_required,
      jobDetails?.other_documents_show
    ),
  },
];
