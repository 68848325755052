import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  StyledBackgroundImageContainer,
  StyledBackgroundTextContainer,
} from "./styles";

const BackgroundImage = ({
  isApplySuccessPage = false,
}: {
  readonly isApplySuccessPage?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <StyledBackgroundImageContainer item xs={6}>
      {!isApplySuccessPage && (
        <StyledBackgroundTextContainer>
          <Typography variant="h4" color="#fff" lineHeight={2.5}>
            {t("login.with_karierra")}
          </Typography>
          <Typography variant="body2" color="#fff" lineHeight={1.75}>
            {t("login.your_design")}
          </Typography>
          <Typography variant="body2" color="#fff" lineHeight={1.75}>
            {t("login.reach_more")}
          </Typography>
          <Typography variant="body2" color="#fff" lineHeight={1.75}>
            {t("login.hire")}
          </Typography>
        </StyledBackgroundTextContainer>
      )}
    </StyledBackgroundImageContainer>
  );
};

export default BackgroundImage;
