import React from "react";
import { Stack, Typography, InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import UploadDocument from "../../../components/UploadDocument";
import { IKOQuestionField } from "../../../models/JobPreview";

const KOQuestionDocuments = ({
  t,
  control,
  errors,
  companyColor,
}: IKOQuestionField) => (
  <Stack spacing={1.5}>
    <InputLabel className="required-label">
      {t("create_job_third_step.documents")}
    </InputLabel>
    {[0, 1, 2].map((index) => (
      <Controller
        key={index}
        name={`otherDocuments.${index}`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <UploadDocument
            companyColor={companyColor}
            placeholder={
              t("additional_questions.documents_placeholder") as string
            }
            value={value?.name || ""}
            onChange={(file) => {
              onChange(file);
            }}
          />
        )}
      />
    ))}
    {errors.otherDocuments && (
      <Typography mt="4px !important" color="error" variant="subtitle2">
        {errors.otherDocuments.message}
      </Typography>
    )}
  </Stack>
);

export default KOQuestionDocuments;
