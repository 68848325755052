import React from "react";
import User from "./User";
import NoMessages from "./NoMessages";
import { StyledChatUsersContainer } from "./styles";
import { IUserListComponent } from "../../models/Messages";

const UserListComponent = ({
  t,
  desktop,
  recruiterId,
  latestMessages,
  anchorEl,
  isSearchActive,
  handleCloseUserMenu,
  handleOpenUserMenu,
  handleReadUnread,
  handleSetActiveUser,
}: IUserListComponent) => {
  const fullContainer: HTMLElement | null = document.querySelector(
    "#messages-container"
  );
  const containerHeight: number = fullContainer?.offsetHeight || 0;
  const headerHeight = isSearchActive ? 142 : 88;
  const leftSideHeight = containerHeight - headerHeight;

  return (
    <StyledChatUsersContainer
      minWidth={desktop ? "345px" : "100%"}
      maxWidth={desktop ? "345px" : "100%"}
      height={desktop ? leftSideHeight : "auto"}
      borderRight={desktop ? "1px solid #E5E7EB" : "none"}
    >
      {latestMessages.length > 0 ? (
        latestMessages.map((item, index) => (
          <User
            key={index}
            t={t}
            recruiterId={recruiterId}
            user={item}
            desktop={desktop}
            users={latestMessages}
            index={index}
            anchorEl={anchorEl}
            handleCloseUserMenu={handleCloseUserMenu}
            handleOpenUserMenu={handleOpenUserMenu}
            handleReadUnread={handleReadUnread}
            handleSetActiveUser={handleSetActiveUser}
          />
        ))
      ) : (
        <NoMessages t={t} />
      )}
    </StyledChatUsersContainer>
  );
};

export default UserListComponent;
