import moment from "moment/moment";
import { createSelector } from "@reduxjs/toolkit";
import { getFilters } from "../../utils/common";
import { RootState } from "../config";
import {
  getCandidateApplications,
  getCandidateJobUrlKey,
  getIsCandidateState,
} from "./Candidate";
import {
  TCandidateApplication,
  TMainQuestionsKeys,
} from "../../models/ApplicationPage";
import { getStatuses } from "./CurrentUser";
import { formatSalaryValue } from "../../utils";

export const getApplicationsFiltersState = (state: RootState) =>
  state.applications.filters;

export const getApplicationsPaginationState = (state: RootState) =>
  state.applications.pagination;

export const getApplicationListState = (state: RootState) =>
  state.applications.applications;

export const getApplicationsViewState = (state: RootState) =>
  state.applications.view;

export const getApplicationsIsListLoading = (state: RootState) =>
  state.applications.isListLoading;

export const getApplicationsIsDeleteApplicationLoading = (state: RootState) =>
  state.applications.isDeleteApplicationLoading;

export const getSelectedApplications = (state: RootState) =>
  state.applications.selectedApplications;

export const getCandidateAnswers = (state: RootState) =>
  state.applications.candidateAnswers;

export const getAllApplications = (state: RootState) =>
  state.applications.allApplications;

export const getSelectedJobApplications = (state: RootState) =>
  state.applications.applications.selectedJobApplications;

export const getJobApplicationsLoadingState = (state: RootState) =>
  state.applications.isLoading;

export const getActivityLogLoadingState = (state: RootState) =>
  state.applications.isActivityLogLoading;

export const getStatusLoadingState = (state: RootState) =>
  state.applications.isStatusLoading;

export const getJobApplicationsAnswerUpdateLoadingState = (state: RootState) =>
  state.applications.isUpdateAnswersLoading;

export const getJobApplicationsOwnersLoadingState = (state: RootState) =>
  state.applications.isOwnersLoading;

export const getJobWorkflow = (state: RootState) =>
  state.applications.jobWorkflow;

export const getSelectedJobOwners = (state: RootState) =>
  state.applications.selectedJobOwners;

export const getActivityLog = (state: RootState) =>
  state.applications.activityLog;

export const getSingleJobUrlKey = (state: RootState) =>
  state.applications.jobUrlKey;

export const getRequestedDataList = (state: RootState) =>
  state.applications.requestedDataList;

export const getRequestedDataTitle = (state: RootState) =>
  state.applications.requestedDataTitle;

export const getSelectedApplication = (state: RootState) =>
  state.applications.selectedApplication;

export const getSelectedApplicationNoteList = createSelector(
  [getSelectedApplication],
  (selectedApplication) => selectedApplication.notes.list
);

export const getSelectedApplicationDeletedNoteList = createSelector(
  [getSelectedApplication],
  (selectedApplication) => selectedApplication.deletedNotes
);

export const getSelectedApplicationNoteListLoadingState = createSelector(
  [getSelectedApplication],
  (selectedApplication) => selectedApplication.notes.isLoading
);

export const getSelectedApplicationErrorState = createSelector(
  [getSelectedApplication],
  (selectedApplication) => selectedApplication.error
);

export const getCurrentApplication = createSelector(
  [
    getSelectedJobApplications,
    getCandidateApplications,
    getIsCandidateState,
    getCandidateJobUrlKey,
  ],
  (
    selectedJobApplications,
    candidateApplications,
    isCandidate,
    candidateJobUrlKey
  ) => {
    if (isCandidate) {
      return candidateApplications?.find(
        (application) => application.job.url_key === candidateJobUrlKey
      );
    }
    return selectedJobApplications ? selectedJobApplications[0] : undefined;
  }
);

export const getApplicationAdditionalInfo = createSelector(
  [getCurrentApplication],
  (currentApplication) => {
    let additionalQuestions: {
      label: string;
      value: string | number;
      isTypeYesNo: boolean;
    }[] = [];
    let mainQuestions: {
      labelKey: string;
      value: string | number;
      type: string;
    }[] = [];
    let driverLicenses: string[] = [];
    let skills: string[] = [];
    let languages: { label: string; value: number; level: string }[] = [];
    if (currentApplication) {
      additionalQuestions =
        currentApplication.answers?.map(
          (item: {
            question: string;
            answer: string;
            is_answer_type_yesno: number;
          }) => ({
            label: item.question,
            value: item.answer,
            isTypeYesNo: !!item.is_answer_type_yesno,
          })
        ) || [];
      mainQuestions = mainQuestionsTemplate().map((item) => ({
        ...item,
        value: getQuestionValue({ type: item.type, currentApplication }),
      }));
      driverLicenses = currentApplication.drivers_license
        ?.split(",")
        .filter((item) => item);
      skills = currentApplication.skills?.split(",").filter((item) => item);

      const parsedLanguages = JSON.parse(currentApplication.languages);
      languages = getFormattedLanguages(parsedLanguages);
    }

    return {
      additionalQuestions,
      mainQuestions,
      driverLicenses,
      skills,
      languages,
    };
  }
);

export const getApplicationsFilters = createSelector(
  [getStatuses, getApplicationListState, getApplicationsFiltersState],
  (statuses, applications, filters) => {
    const { list } = applications as unknown as {
      list: { [x: string]: string }[];
    };
    const statusFilterOptions = getFilters({
      list,
      targetField: "status",
      targetValue: "status",
    });

    const appliedToFilterOptions = getFilters({
      list,
      targetField: "title",
      targetValue: "job_id",
    });
    return {
      ...filters,
      status: {
        ...filters.status,
        options: statusFilterOptions.map((option) => ({
          ...option,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          label: statuses[option.label],
        })),
      },
      appliedTo: { ...filters.appliedTo, options: appliedToFilterOptions },
    };
  }
);

const mainQuestionsTemplate = (): {
  labelKey: string;
  type: TMainQuestionsKeys;
}[] => [
  {
    labelKey: "candidate_questions.eu_permit",
    type: "working_permit_eu",
  },
  {
    labelKey: "candidate_questions.salary_expectation",
    type: "salary_expectation",
  },
  {
    labelKey: "candidate_questions.earliest_start_date",
    type: "earliest_start_date",
  },
  {
    labelKey: "candidate_questions.current_professional_status",
    type: "current_professional_status",
  },
  {
    labelKey: "candidate_questions.highest_degree",
    type: "highest_degree",
  },
];

const getQuestionValue = ({
  type,
  currentApplication,
}: {
  type: TMainQuestionsKeys;
  currentApplication: TCandidateApplication;
}) => {
  const value = currentApplication[type];
  const isWorkingPermitType = type === "working_permit_eu";
  const hasWorkingPermitValue = value == "1" || value == "0";
  const isWorkingPermit = isWorkingPermitType && hasWorkingPermitValue;
  const language = localStorage.getItem("lang");
  const yesAnswer = language === "en_US" ? "Yes" : "Ja";
  const noAnswer = language === "en_US" ? "No" : "Nein";

  if (isWorkingPermit) {
    if (value == "1") {
      return yesAnswer;
    }
    if (value == "0") {
      return noAnswer;
    }
  }
  if (type === "current_professional_status") {
    return (
      currentApplication["current_professional_status"] ||
      currentApplication["current_job"] ||
      ""
    );
  }
  if (value && type === "salary_expectation") {
    if (parseInt(value as string) === 0) {
      return 0;
    }
    return `${formatSalaryValue(value as number)} €`;
  }
  if (value && type === "earliest_start_date")
    return moment(value).format("DD.MM.YYYY");

  return value || "";
};

const getFormattedLanguages = (languages: {
  [x: string]: string;
}): { label: string; value: number; level: string }[] => {
  return Object.keys(languages || {})
    ?.map((langKey: string) => {
      if (langKey === "null") return { label: langKey, value: 0, level: "" };
      const currentLangValue = languages[langKey];
      let value = 0;
      switch (currentLangValue) {
        case "GRUNDKENNTNISSE":
        case "BASIC":
          value = 20;

          break;
        case "FORTGESCHRITTEN":
        case "INTERMEDIARY":
          value = 40;
          break;
        case "SEHR GUT":
        case "ADVANCED":
          value = 60;
          break;
        case "FLIEßEND":
        case "FLUENT":
          value = 80;
          break;
        case "MUTTERSPRACHE":
        case "NATIVE":
          value = 100;
          break;
      }
      return { label: langKey, value, level: currentLangValue || "" };
    })
    .filter((item) => item.value);
};
