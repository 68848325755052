import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import GeneralPageComponent from "./component";
import {
  getCurrentUserData,
  getCurrentUserNavigationState,
} from "../../store/selectors/CurrentUser";
import { setSelectedSettingsTab } from "../../store/reducers/CurrentUser";
import { SETTINGS_PAGE_TYPES } from "../../models/TopHeader";
import { useTranslation } from "react-i18next";
import { setSelectedUserStatus } from "../../store/reducers/Team";
import { TEAM_USER_STATUSES } from "../../models/Team";

const GeneralPage = ({ desktop }: { readonly desktop: boolean }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tab } = useParams();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const { lang, isAdmin } = useSelector(getCurrentUserData);
  const navigation = useSelector(getCurrentUserNavigationState);
  const { selectedSettingsTab, settingsTabs } = navigation;

  const noAdminTabs = [...settingsTabs].filter(
    (tab) => tab !== SETTINGS_PAGE_TYPES.TEAM
  );

  const tabs = isAdmin ? settingsTabs : noAdminTabs;

  const handleOnChangeTab = (
    _: React.SyntheticEvent,
    tab: SETTINGS_PAGE_TYPES
  ) => {
    dispatch(setSelectedSettingsTab(tab));
    navigate(`/settings/${tab}`);
    setAnchorEl(null);
  };

  const handleSelectedTeamTab = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAnchorEl(event.currentTarget);
  };

  const handleSelectMenuItem = (option?: TEAM_USER_STATUSES) => {
    const value = SETTINGS_PAGE_TYPES.TEAM;
    if (option) {
      dispatch(setSelectedSettingsTab(value));
      navigate(`/settings/${value}`);
      dispatch(setSelectedUserStatus(option));
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(setSelectedSettingsTab(tab as SETTINGS_PAGE_TYPES));
  }, [tab]);

  return (
    <GeneralPageComponent
      t={t}
      desktop={desktop}
      selectedSettingsTab={selectedSettingsTab}
      tabs={tabs}
      menuOpen={Boolean(anchorEl)}
      lang={lang}
      anchorEl={anchorEl}
      onSelectMenuItem={handleSelectMenuItem}
      onClickTeamTab={handleSelectedTeamTab}
      onChangeTab={handleOnChangeTab}
    />
  );
};

export default GeneralPage;
