import React from "react";
import { InputLabel, Radio, RadioGroup, Stack, Tooltip } from "@mui/material";
import { Controller } from "react-hook-form";
import { IKOQuestionField } from "../../../models/JobPreview";

const KOQuestionsWorkingPermitEU = ({ t, control }: IKOQuestionField) => (
  <Stack>
    <InputLabel className="required-label">
      {t("create_job_third_step.eu_working_permit")}
    </InputLabel>
    <Controller
      name="workingPermitEU"
      control={control}
      render={({ field: { onChange, value } }) => (
        <RadioGroup
          row
          value={value}
          onChange={(_, newValue) => onChange(newValue)}
        >
          <Tooltip title={t("yes")}>
            <Radio
              value="1"
              sx={{
                color: "#22C55E !important",
                "&.Mui-checked": { color: "#22C55E !important" },
                "&:hover": {
                  backgroundColor: "#9f9d9d29 !important",
                },
              }}
            />
          </Tooltip>
          <Tooltip title={t("no")}>
            <Radio
              value="2"
              sx={{
                color: "#EF4444 !important",
                "&.Mui-checked": { color: "#EF4444 !important" },
                "&:hover": {
                  backgroundColor: "#9f9d9d29 !important",
                },
              }}
            />
          </Tooltip>
        </RadioGroup>
      )}
    />
  </Stack>
);

export default KOQuestionsWorkingPermitEU;
