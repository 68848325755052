import React, { useEffect, useMemo, useState } from "react";
import { TFunction } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import linkednLogo from "../../../assets/linkedinIcon.png";
import BasicJobBoardComponent from "./component";
import ConnectDialog from "./ConnectDialog";
import { DIALOG_TYPE } from "../../../models/common";
import {
  fetchCompanyData,
  updateCompanyData,
} from "../../../store/reducers/CompanySettings";
import {
  fetchBasicJobBoards,
  updateHeaderImage,
} from "../../../store/reducers/SingleJob";
import { getSingleJobState } from "../../../store/selectors/SingleJob";
import { getModifiedJobBoards } from "../../../utils/basicJobBoards";
import { getCompanySettingsCompanyState } from "../../../store/selectors/Company";
import {
  IintegrationInfo,
  INTEGRATION_TYPES,
} from "../../../models/Integrations";

const BasicJobBoard = ({ t }: { t: TFunction }) => {
  const dispatch = useDispatch();
  const { jobDetails, basicJobBoards, isLoading, isListLoading } =
    useSelector(getSingleJobState);
  const { company } = useSelector(getCompanySettingsCompanyState);
  const [selectedJobBoard, setSelectedJobBoard] =
    useState<IintegrationInfo | null>(null);
  const [companyProfileUrl, setCompanyProfileUrl] = useState("");
  const [openedDialogType, setOpenedDialogType] = useState<DIALOG_TYPE | null>(
    null
  );
  useEffect(() => {
    dispatch(fetchBasicJobBoards());
  }, []);

  const isDataLoading = useMemo(
    () => isListLoading || isLoading,
    [isLoading, isListLoading]
  );

  const jobList = useMemo(
    () => getModifiedJobBoards({ company, jobDetails, basicJobBoards }),
    [basicJobBoards, company, jobDetails]
  );

  const handleOnUploadFile = (file?: File) => {
    if (file && jobDetails) {
      const {
        title,
        template: template_name,
        url_key: jobUrlKey,
        company_id,
      } = jobDetails;
      dispatch(
        updateHeaderImage({
          title,
          template_name,
          jobUrlKey,
          company_id,
          template_header_1: file,
        })
      );
    }
  };

  const handleOnOpenConnectDialog = (item: IintegrationInfo) => {
    if (item.title.toLowerCase() === INTEGRATION_TYPES.LINKEDIN.toLowerCase()) {
      const formattedItem = {
        ...item,
        logo: linkednLogo,
      };
      setSelectedJobBoard(formattedItem);
    } else {
      setSelectedJobBoard(item);
    }

    setOpenedDialogType(DIALOG_TYPE.CONNECTED);
  };

  const handleOnChangeCompanyProfileUrl = (url: string) => {
    setCompanyProfileUrl(url);
  };

  const handleOnSaveCompanyProfileUrl = () => {
    const data =
      selectedJobBoard?.title?.toLowerCase() ===
      INTEGRATION_TYPES.LINKEDIN.toLowerCase()
        ? { linkedin_url: companyProfileUrl }
        : { xing_url: companyProfileUrl };
    if (selectedJobBoard)
      dispatch(
        updateCompanyData({
          type: "form",
          data,
          callback: onSaveCompanyProfileUrlSuccess,
        })
      );
  };

  const onSaveCompanyProfileUrlSuccess = () => {
    if (jobDetails) {
      dispatch(fetchCompanyData());
      handleOnCloseDialog();
    }
  };

  useEffect(() => {
    // TODO refactor later, switch to callback
    if (isLoading) {
      handleOnCloseDialog();
    }
  }, [isLoading]);

  const handleOnCloseDialog = () => {
    setOpenedDialogType(null);
    setCompanyProfileUrl("");
  };

  return (
    <>
      <BasicJobBoardComponent
        t={t}
        isListLoading={isDataLoading}
        jobList={jobList}
        actions={{
          onConnect: handleOnOpenConnectDialog,
          onUpload: handleOnUploadFile,
        }}
      />
      <ConnectDialog
        t={t}
        companyProfileUrl={companyProfileUrl}
        jobBoard={selectedJobBoard}
        isLoading={isLoading}
        isOpen={openedDialogType === DIALOG_TYPE.CONNECTED}
        onClose={handleOnCloseDialog}
        onSave={handleOnSaveCompanyProfileUrl}
        onChangeUrl={handleOnChangeCompanyProfileUrl}
      />
    </>
  );
};

export default BasicJobBoard;
