import React, { useEffect } from "react";
import EditMessageComponent from "./EditMessage";
import MessageComponent from "./Message";
import NewMessage from "./NewMessage";
import {
  StyledFullMessagesContainer,
  StyledMessagesListContainer,
} from "./styles";
import { IMessagesListComponent } from "../../models/Messages";

const MessagesListComponent = ({
  t,
  desktop,
  recruiterId,
  messagesInfo,
  messages,
  firstname,
  lastname,
  isCandidate,
  editMessageId,
  deleteMessageId,
  profilePhoto,
  activeMessageId,
  editMessageDefaultValue,
  isSearchActive,
  setDeleteMessageId,
  setActiveMessageId,
  handleEdit,
  onOpenDialog,
  handleSendMessage,
}: IMessagesListComponent) => {
  const loggedUser = isCandidate ? "candidate" : "recruiter";
  const chatContainer: HTMLElement | null =
    document.querySelector("#chat-container");
  const fullContainer: HTMLElement | null = document.querySelector(
    "#messages-container"
  );
  const containerHeight: number = fullContainer?.offsetHeight || 0;
  const headerHeight = 88;
  const messagesArea: HTMLElement | null =
    document.querySelector("#messages-area");
  const messageAreaHeight: number = messagesArea?.offsetHeight || 0;
  const messagesHeight = containerHeight - headerHeight - messageAreaHeight;
  const mobileHeight: string = "100vh";

  useEffect(() => {
    if (chatContainer && !editMessageId && !deleteMessageId) {
      chatContainer.scrollTop = chatContainer?.scrollHeight;
    }
  }, [messagesInfo, recruiterId]);

  return (
    <StyledFullMessagesContainer>
      <StyledMessagesListContainer
        id="chat-container"
        height={desktop ? messagesHeight : mobileHeight}
        paddingTop={desktop ? 3 : "0px !important"}
        paddingLeft={desktop ? 3 : "0px !important"}
        mt={isSearchActive ? -6.85 : 0}
      >
        {messages?.map((item, index) =>
          item.message_id === editMessageId ? (
            <EditMessageComponent
              t={t}
              key={index}
              item={item}
              messagesInfo={messagesInfo}
              loggedUser={loggedUser}
              firstname={firstname}
              lastname={lastname}
              profilePhoto={profilePhoto}
              editMessageDefaultValue={editMessageDefaultValue}
              onOpenDialog={onOpenDialog}
            />
          ) : (
            <MessageComponent
              key={index}
              t={t}
              message={item}
              messagesInfo={messagesInfo}
              loggedUser={loggedUser}
              firstname={firstname}
              lastname={lastname}
              profilePhoto={profilePhoto}
              activeMessageId={activeMessageId}
              onOpenDialog={onOpenDialog}
              handleEdit={handleEdit}
              setDeleteMessageId={setDeleteMessageId}
              setActiveMessageId={setActiveMessageId}
            />
          )
        )}
      </StyledMessagesListContainer>
      <NewMessage t={t} desktop={desktop} sendMessage={handleSendMessage} />
    </StyledFullMessagesContainer>
  );
};

export default MessagesListComponent;
