import React from "react";
import { Stack, Typography, Divider, Button, InputLabel } from "@mui/material";
import AutocompleteFilter from "../../../../components/filters/AutocompleteFilter";
import { IApplicationStatusUpdatedTriggerComponent } from "../../../../models/Automation";
import { TListOption } from "../../../../models/common";

const ApplicationStatusUpdatedTriggerComponent = ({
  t,
  statuses,
  currentAutomation,
  errors,
  selectedFromStatuses,
  selectedToStatuses,
  onChangeFromStatuses,
  onChangeToStatuses,
  onSubmit,
  handleSubmit,
}: IApplicationStatusUpdatedTriggerComponent) => (
  <Stack>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Stack
          mt="0px !important"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize={16} fontWeight={500}>
            {currentAutomation?.trigger?.name}
          </Typography>
        </Stack>
        <Divider />

        <Stack spacing={0.25}>
          <InputLabel>{t("automation.oldStatus")}</InputLabel>
          <AutocompleteFilter
            hasColors
            listBoxProps={{
              className: "multiple-selection-autocomplete",
            }}
            multiple
            size="small"
            placeholder={t("automation.oldStatus")}
            options={statuses.fromStatuses as unknown as TListOption[]}
            value={selectedFromStatuses}
            errors={errors.statusesFrom}
            onChange={onChangeFromStatuses}
          />
        </Stack>
        <Stack spacing={0.25}>
          <InputLabel>{t("automation.newStatus")}</InputLabel>
          <AutocompleteFilter
            hasColors
            listBoxProps={{
              className: "multiple-selection-autocomplete",
            }}
            multiple
            size="small"
            placeholder={t("automation.newStatus")}
            options={statuses.fromStatuses as unknown as TListOption[]}
            value={selectedToStatuses}
            errors={errors.statusesTo}
            onChange={onChangeToStatuses}
          />
        </Stack>
      </Stack>
      <Stack
        mt={2}
        direction="row"
        width="100%"
        justifyContent="flex-end"
        spacing={1.5}
      >
        <Button variant="contained" type="submit">
          {t("button.save")}
        </Button>
      </Stack>
    </form>
  </Stack>
);

export default ApplicationStatusUpdatedTriggerComponent;
