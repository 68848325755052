import React from "react";
import { Button } from "@mui/material";
import SimpleFilter from "../../components/filters/SimpleFilter";
import { DIALOG_TYPE } from "../../models/common";
import { ITeamsToolbar } from "../../models/Team";
import { StyledTeamsToolbarContainer } from "./styles";

const TeamSettingsToolbar = ({
  t,
  quickSearch,
  onChangeFilters,
  onOpenDialog,
}: ITeamsToolbar) => (
  <StyledTeamsToolbarContainer direction={"row"}>
    <SimpleFilter
      testId={"teams-toolbar-simple-filter"}
      value={quickSearch}
      onChange={onChangeFilters}
      placeholder={t("team_members.search_placeholder")}
    />

    <Button
      data-testid={"teams-toolbar-invite-button"}
      variant={"contained"}
      onClick={() => onOpenDialog({ type: DIALOG_TYPE.INVITE })}
    >
      {t("team_members.invite")}
    </Button>
  </StyledTeamsToolbarContainer>
);

export default TeamSettingsToolbar;
