import { TFunction } from "i18next";
import { DIALOG_TYPE, TListOption } from "./common";
import {
  Control,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { TJobDetails } from "./SingleJob";
import { TJobListItem } from "./JobList";
import { ChangeEvent, MouseEvent } from "react";
import {
  TAdditionalQuestionsLanguage,
  TRecruiterJobActionRegisterFormFields,
} from "./JobPreview";

// api

export type TApiGetUserPayload = {
  readonly pagenum: number;
  readonly pagesize: number;
  readonly email?: string;
};

export type TApiDeleteUserPayload = {
  readonly id: string;
  readonly email: string;
};

export type TApiInviteUserPayload = {
  readonly emails: string[];
};

//

export type TTeamsInvitation = {
  readonly id: string;
  readonly email: string;
  readonly url_key: string;
  readonly company_id: string;
  readonly timestamp: string;
};

export type TTeamsMember = {
  readonly id: string;
  readonly username: string;
  readonly firstname: string;
  readonly lastname: string;
  readonly profile_photo: string | null;
  readonly photo: string | null;
  readonly phonenumber: string;
  readonly password: string;
  readonly salt: string;
  readonly status: string;
  readonly company_id: string;
  readonly activation_key: string | null;
  readonly access_token: string;
  readonly access_token_expiration_timestamp: string;
  readonly is_admin: string;
  readonly is_superadmin: string;
  readonly disabled: string;
  readonly activated: string;
  readonly registered: string;
  readonly lang: string;
};

export interface ITeamsState {
  readonly filters: TTeamsFilters;
  readonly navigation: TTeamsNavigation;
  readonly pagination: TTeamsPagination;
  readonly users: TTeamsUsers;
  readonly error: unknown;
  readonly isLoading: boolean;
  readonly isListLoading: boolean;
}

export type TTeamsFilters = {
  readonly quickSearch: string;
};

export type TTeamsNavigation = {
  readonly userStatuses: TEAM_USER_STATUSES[];
  readonly selectedUserStatus: TEAM_USER_STATUSES;
};

export type TTeamsPagination = {
  readonly joined: {
    readonly pageNum: number;
    readonly pageSize: number;
  };
  readonly pending: {
    readonly pageNum: number;
    readonly pageSize: number;
  };
};

export type TTeamsUsers = {
  readonly list: (TTeamsMember & TTeamsInvitation)[];
  readonly totalCount: number;
};

export interface ITeamsComponent {
  readonly t: TFunction;
  readonly userList: TTeamsUsers["list"];
  readonly isLoading: boolean;
  readonly isListLoading: boolean;
  readonly openedDialogType: DIALOG_TYPE | null;
  readonly pageNum: number;
  readonly pageSize: number;
  readonly userCount: number;
  readonly quickSearch: string;
  readonly emailsToInvite: string[];
  readonly actions: {
    readonly onDelete?: (data: TTeamsMember | TTeamsInvitation) => void;
    readonly onReInviteTeamMember?: (email: string) => void;
  };
  readonly onPaginate: (pageNum: number) => void;
  readonly onChangeFilters: (value: string) => void;
  readonly onCloseDialog: () => void;
  readonly onDelete: () => void;
  readonly onEmailsChange: (emails: string[]) => void;
  readonly onOpenDialog: ({
    type,
    user,
  }: {
    type: DIALOG_TYPE;
    user?: TTeamsMember | TTeamsInvitation;
  }) => void;
  readonly onInvite: () => void;
}

export interface ITeamsToolbar {
  readonly t: TFunction;
  readonly quickSearch: ITeamsComponent["quickSearch"];
  readonly onChangeFilters: ITeamsComponent["onChangeFilters"];
  readonly onOpenDialog: ITeamsComponent["onOpenDialog"];
}

export interface ITeamsNoResultOverlay {
  readonly t: TFunction;
  readonly onOpenDialog: ITeamsComponent["onOpenDialog"];
}

export interface ITeamsNoResultComponent {
  readonly actionText: string;
  readonly titleText: string;
  readonly hasFilters: boolean;
  readonly onResetFilters: () => void;
  readonly onOpenDialog: () => void;
}

export interface ITeamsInviteDialog {
  readonly t: TFunction;
  readonly title: string;
  readonly isLoading: boolean;
  readonly isOpen: boolean;
  readonly emailsToInvite: string[];
  readonly onCancel: () => void;
  readonly onEmailsChange: ITeamsComponent["onEmailsChange"];
  readonly onInvite: () => void;
}

export interface IApplyJobDialog {
  isOpen: boolean;
  onCancel: () => void;
}

export interface IApplyJobDialogComponent {
  readonly t: TFunction;
  readonly key?: string;
  readonly isOpen: boolean;
  readonly isLoading: boolean;
  readonly isCvRequired: boolean;
  readonly isApplyInProgress: boolean;
  readonly isPhoneRequired: boolean;
  readonly isLocationRequired: boolean;
  readonly isWorkingPermitEuRequired: boolean;
  readonly isSalaryExpectationRequired: boolean;
  readonly isEarliestStartDateRequired: boolean;
  readonly isCurrentJobRequired: boolean;
  readonly isHighestDegreeRequired: boolean;
  readonly isDriversLicenseRequired: boolean;
  readonly isSkillsRequired: boolean;
  readonly isLangsRequired: boolean;
  readonly isOtherDocumentsRequired: boolean;
  readonly languages: TListOption[];
  readonly levels: TListOption[];
  readonly fields: TAdditionalQuestionsLanguage[];
  readonly skills: TListOption[];
  readonly filterOptions: any;
  readonly driverLicenses: TListOption[];
  readonly jobDetails: TJobDetails | null;
  readonly selectedJobs: TJobListItem[];
  readonly uploadedCV?: FileList | null;
  readonly jobCity: string;
  readonly locale: string;
  readonly control: Control<TRecruiterJobActionRegisterFormFields>;
  readonly errors: FieldErrors<TRecruiterJobActionRegisterFormFields>;
  readonly register: UseFormRegister<TRecruiterJobActionRegisterFormFields>;
  readonly onClose: () => void;
  readonly onSubmit: (data: TRecruiterJobActionRegisterFormFields) => void;
  readonly onUploadCV: (event: ChangeEvent<HTMLInputElement>) => void;
  readonly onResetCV: (event: MouseEvent) => void;
  readonly onAddNewLanguage: () => void;
  readonly onRemoveLanguage: (index: number) => void;
  readonly handleSubmit: UseFormHandleSubmit<TRecruiterJobActionRegisterFormFields>;
}

export enum TEAM_USER_STATUSES {
  JOINED = "joined",
  PENDING = "pending",
}

export enum TEAM_USER_ROLES {
  ADMIN = "Admin",
  RECRUITER = "Recruiter",
}
