import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import linkedinLogo from "../../../assets/linkedinIcon.png";
import xingLogo from "../../../assets/xingIcon.png";
import ConnectDialog from "../../SingleJob/BasicJobBoard/ConnectDialog";
import FirstStepsBoxComponent from "./component";
import {
  fetchCompanyData,
  updateCompanyData,
} from "../../../store/reducers/CompanySettings";
import { fetchBasicJobBoards } from "../../../store/reducers/SingleJob";
import { getJobListJobsState } from "../../../store/selectors/JobList";
import { openJobEditor } from "../../../store/reducers/JobEditor";
import {
  setIsLimitDialogOpen,
  setIsUploadPictureDialogOpen,
} from "../../../store/reducers/dialogs";
import { getCompanySettingsCompanyState } from "../../../store/selectors/Company";
import {
  IintegrationInfo,
  INTEGRATION_GROUP,
  INTEGRATION_TYPES,
} from "../../../models/Integrations";

const FirstStepsBox = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { company } = useSelector(getCompanySettingsCompanyState);
  const { totalCount: jobCount } = useSelector(getJobListJobsState);

  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState(false);
  const [currentSocialMediaUrl, setCurrentSocialMediaUrl] = useState("");
  const [currentSocialMedia, setCurrentSocialMedia] =
    useState<IintegrationInfo | null>(null);

  const { isLogoExist, isLinkedinExist, isXingExist, isCompanyInfoFull } =
    useMemo(
      () => ({
        isLogoExist: Boolean(company?.logo),
        isLinkedinExist: Boolean(company?.linkedin),
        isXingExist: Boolean(company?.xing),
        isCompanyInfoFull: Boolean(
          company?.website &&
            company.employees &&
            company.industry_id &&
            company.company_color &&
            company.about_us
        ),
      }),
      [company]
    );

  useEffect(() => {
    if (!isXingExist || !isLinkedinExist) dispatch(fetchBasicJobBoards());
  }, [isXingExist, isLinkedinExist]);

  const handleOnOpenUploadLogoDialog = () => {
    dispatch(setIsUploadPictureDialogOpen(true));
  };
  const handleOnOpenConnectDialog = (social: "Linkedin" | "Onlyfy") => {
    const currentMedia =
      social.toLowerCase() == "linkedin"
        ? {
            title: INTEGRATION_TYPES.LINKEDIN,
            description: t("integrations.linkedinDescription"),
            logo: linkedinLogo,
            isConnected: Boolean(company?.linkedin?.length),
            group: INTEGRATION_GROUP.EXTERNAL_PROFILES,
          }
        : {
            title: INTEGRATION_TYPES.XING,
            description: t("integrations.xingDescription"),
            logo: xingLogo,
            isConnected: Boolean(company?.xing?.length),
            group: INTEGRATION_GROUP.EXTERNAL_PROFILES,
          };
    setCurrentSocialMedia(currentMedia);
    setIsConnectDialogOpen(true);
  };
  const handleOnCloseConnectDialog = () => {
    setIsConnectDialogOpen(false);
    setCurrentSocialMediaUrl("");
    setCurrentSocialMedia(null);
  };
  const handleOnSaveUpdateSocialMediaUrl = () => {
    const data =
      currentSocialMedia?.title === INTEGRATION_TYPES.LINKEDIN
        ? { linkedin_url: currentSocialMediaUrl }
        : { xing_url: currentSocialMediaUrl };
    dispatch(
      updateCompanyData({
        type: "form",
        data,
        callback: () => {
          dispatch(fetchCompanyData());
          handleOnCloseConnectDialog();
        },
      })
    );
  };

  const handleOnChangeSocialMediaUrl = (val: string) => {
    setCurrentSocialMediaUrl(val);
  };

  const handleOnNavigateCompanySettings = () => {
    navigate("/settings/career");
  };

  const handleOnOpenJobEditor = () => {
    if (company?.jobs_creation_allowed) {
      dispatch(openJobEditor());
    } else {
      dispatch(setIsLimitDialogOpen(true));
    }
  };

  return (
    <>
      <FirstStepsBoxComponent
        t={t}
        onNavigateCompanySettings={handleOnNavigateCompanySettings}
        onOpenConnectDialog={handleOnOpenConnectDialog}
        onOpenJobEditor={handleOnOpenJobEditor}
        onOpenUploadLogoDialog={handleOnOpenUploadLogoDialog}
        isCompanyInfoFull={isCompanyInfoFull}
        isJobExist={+jobCount > 0}
        isLogoExist={isLogoExist}
        isLinkedinExist={isLinkedinExist}
        isXingExist={isXingExist}
      />
      <ConnectDialog
        t={t}
        companyProfileUrl={currentSocialMediaUrl}
        jobBoard={currentSocialMedia}
        isLoading={false}
        isOpen={isConnectDialogOpen}
        onClose={handleOnCloseConnectDialog}
        onSave={handleOnSaveUpdateSocialMediaUrl}
        onChangeUrl={handleOnChangeSocialMediaUrl}
      />
    </>
  );
};

export default FirstStepsBox;
