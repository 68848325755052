import React from "react";
import {
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { TFunction } from "i18next";
import { StyledAdditionalQuestionsFieldContainer } from "../styles";
import { IAdditionalQuestionSimpleSection } from "../../../models/JobPreview";
import {
  formatSalaryFieldValue,
  formatSalaryValue,
  removeNonNumeric,
} from "../../../utils";

const AdditionalQuestionsSalary = ({
  t,
  desktop,
  control,
}: IAdditionalQuestionSimpleSection & { readonly t: TFunction }) => (
  <StyledAdditionalQuestionsFieldContainer desktop={desktop}>
    <Stack className={"title"}>
      <Typography variant="subtitle2">
        {t("additional_questions.salary_expectation")}
      </Typography>
    </Stack>

    <Controller
      name="salary"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Box className="input-field">
          <TextField
            fullWidth
            placeholder="0.00"
            value={formatSalaryValue(value as unknown as number)}
            onChange={(e) =>
              onChange(formatSalaryFieldValue(removeNonNumeric(e.target.value)))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="subtitle2" color="textSecondary">
                    €
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    />
  </StyledAdditionalQuestionsFieldContainer>
);

export default AdditionalQuestionsSalary;
