import { TFunction } from "i18next";
import {
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";

export interface IIntegrationsComponent {
  readonly t: TFunction;
  readonly isConnectIntegrationDialogOpen: boolean;
  readonly isConnectExternalProfileDialogOpen: boolean;
  readonly integrations: IintegrationInfo[];
  readonly externalProfiles: IintegrationInfo[];
  readonly selectedIntegration: IintegrationInfo | null;
  readonly showPassword: boolean;
  readonly errors: FieldErrors<IConnectIntegrationDialogDefaultFormFields>;
  readonly companyProfileUrl: string;
  readonly quickApplyInterfaces: IintegrationInfo[];
  readonly register: UseFormRegister<IConnectIntegrationDialogDefaultFormFields>;
  readonly onChangeExternalProfileUrl: (value: string) => void;
  readonly onChangeShowPassword: () => void;
  readonly onClose: () => void;
  readonly onSaveExternalProfile: () => void;
  readonly onOpenConnectDialog: (integration: IintegrationInfo) => void;
  readonly onConnect: (
    data: IConnectIntegrationDialogDefaultFormFields
  ) => void;
  readonly handleSubmit: UseFormHandleSubmit<IConnectIntegrationDialogDefaultFormFields>;
}

export interface IIntegrationComponent {
  readonly t: TFunction;
  readonly integration: IintegrationInfo;
  readonly onOpenConnectDialog: (integration: IintegrationInfo) => void;
}

export interface IintegrationInfo {
  readonly group: INTEGRATION_GROUP;
  readonly title: INTEGRATION_TYPES;
  readonly description: string;
  readonly logo: string;
  readonly isConnected: boolean;
}

export interface IConnectIntegrationDialogDefaultFormFields {
  readonly url: string;
  readonly username: string;
  readonly password: string;
  readonly companyId: string;
  readonly apiKey: string;
}

export enum INTEGRATION_TYPES {
  HR4YOU = "HR4YOU",
  CONCLUDIS = "Concludis",
  ONLYFY = "Onlyfy",
  LINKEDIN = "LinkedIn",
  XING = "Xing",
  STEPSTONE = "Stepstone",
  INDEED = "Indeed",
}

export enum INTEGRATION_GROUP {
  INTEGRATION = "integration",
  EXTERNAL_PROFILES = "externalProfiles",
  QUICK_APPLY_INTERFACES = "quickApplyInterfaces",
}
