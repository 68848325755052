import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledIntegrationItem = styled(Stack)({
  padding: 16,
  height: 210,
  background: "#fff",
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  marginLeft: "0px !important",
  width: "32.5%",
  img: {
    width: "auto",
    height: 24,
    objectFit: "contain",
  },
  ".MuiChip-root": {
    height: 24,
  },
});
