import React from "react";
import { Stack, Typography, Tooltip } from "@mui/material";
import {
  BuildingOffice2Icon,
  GlobeAltIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import WebSiteItem from "./WebSiteItem";
import linkedinIcon from "../../assets/linkedinIcon.png";
import xingIcon from "../../assets/xingIcon.png";
import { TCompanyInfoHeader } from "../../models/CompanyInfo";
import { StyledCompanyHeaderContainer } from "./style";

const Header = ({ t, company, desktop, industry }: TCompanyInfoHeader) => (
  <Stack
    width={desktop ? "100%" : "85%"}
    margin="auto"
    alignItems="center"
    mt={desktop ? 8 : 4}
  >
    <StyledCompanyHeaderContainer
      companyColor={company.color}
      desktop={desktop}
      direction="row"
      width={desktop ? "100%" : "auto"}
      p="12px !important"
      height={desktop ? 88 : "auto"}
      justifyContent="space-between"
      alignItems="center"
      borderRadius={1.5}
      maxWidth="1108px !important"
      className="company-header"
    >
      <Stack
        direction={desktop ? "row" : "column"}
        width="100%"
        spacing={desktop ? 3 : 2}
        alignItems={desktop ? "center" : "flex-start"}
      >
        {company.logo && <img className="logo" src={company.logo} alt="logo" />}
        <Stack
          direction={desktop ? "row" : "column"}
          width="100%"
          justifyContent="space-between"
          spacing={desktop ? 0 : 2}
        >
          <Stack
            width="100%"
            justifyContent={desktop ? "space-between" : "flex-start"}
            spacing={desktop ? 2 : 0}
          >
            <Typography
              variant="h5"
              color="#131E30"
              overflow="hidden"
              textOverflow="ellipsis"
              sx={{
                display: "-webkit-box",
                "-webkit-line-clamp": "2",
                "-webkit-box-orient": "vertical",
              }}
            >
              {company.title}
            </Typography>
            <Stack
              mt={desktop ? 0 : 1.5}
              spacing={desktop ? 3.25 : 1}
              direction={desktop ? "row" : "column"}
            >
              <Stack direction="row" gap={1} alignItems="center">
                <UsersIcon className="icon" />
                <Typography
                  variant="subtitle2"
                  display="inline"
                  lineHeight="143%"
                >
                  {company?.employees} {t("company_page.employees")}
                </Typography>
              </Stack>
              {industry && (
                <Stack direction="row" gap={1} alignItems="center">
                  <BuildingOffice2Icon className="icon" />
                  <Tooltip title={industry}>
                    <Typography
                      variant="subtitle2"
                      display="inline"
                      lineHeight="143%"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {industry}
                    </Typography>
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            height={desktop ? 64 : "auto"}
            alignItems="center"
            spacing={1}
          >
            <WebSiteItem
              text={t("company_page.website")}
              icon={<GlobeAltIcon className="icon" />}
              url={company.website}
              companyColor={company?.color}
            />
            {company.linkedin_url && (
              <WebSiteItem
                text="Linkedin"
                icon={<img src={linkedinIcon} className="icon" />}
                url={company.linkedin_url}
                companyColor={company?.color}
              />
            )}
            {company.xing_url && (
              <WebSiteItem
                text="Xing"
                icon={<img src={xingIcon} className="icon" />}
                url={company.xing_url}
                companyColor={company?.color}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </StyledCompanyHeaderContainer>
  </Stack>
);

export default Header;
