import React, { useState } from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import AuthLayout from "../../layouts/Auth";
import { resendLink } from "../../store/reducers/Registration";

const AppliedResendEmail = ({ desktop }: { readonly desktop: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLinkDisabled, setIsLinkDisabled] = useState(false);
  const candidateUrlKey = sessionStorage.getItem("candidateUrlKey");
  const jobUrlKey = sessionStorage.getItem("jobUrlKey");

  const handleOnResend = () => {
    if (jobUrlKey && candidateUrlKey)
      dispatch(
        resendLink({
          candidateUrlKey,
          jobUrlKey,
          callback: onResendSuccess,
        })
      );
  };

  const onResendSuccess = () => {
    setIsLinkDisabled(true);
    setTimeout(() => {
      setIsLinkDisabled(false);
    }, 60000);
  };

  return (
    <AuthLayout desktop={desktop} isApplySuccessPage>
      <Stack>
        <Typography mt={1.5} variant="body1">
          {t("verification.title")}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" mt={1}>
          {t("verification.subtitle_one")}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {t("verification.subtitle_two")}
        </Typography>
        {candidateUrlKey && jobUrlKey && (
          <Box mt={3}>
            <Typography variant="subtitle2">
              <Link
                underline="none"
                display="inline"
                onClick={handleOnResend}
                className={isLinkDisabled ? "disabled-link" : ""}
              >
                {t("verification.resend")}
              </Link>
            </Typography>
          </Box>
        )}
      </Stack>
    </AuthLayout>
  );
};

export default AppliedResendEmail;
