import React from "react";
import outlook from "../../assets/outlook.png";
import gmail from "../../assets/gmail.svg";
import { Stack, Button, Divider, Typography } from "@mui/material";
import StatusCell from "../../components/DataGrid/cellRenderers/status";
import { StyledDataContainer } from "./style";
import { IConnectEmail } from "../../models/CurrentUser";
import { JOB_STATUSES } from "../../models/JobList";

const ConnectEmail = ({
  t,
  gmailEmail,
  outlookEmail,
  onAuthDisconnectGmail,
  onAuthDisconnectOutlook,
}: IConnectEmail) => (
  <StyledDataContainer>
    <Typography>{t("userSettings.connect_your_email")}</Typography>
    <Divider className="divider" />
    <StyledDataContainer
      justifyContent="space-between"
      flexDirection="row"
      mt="0px !important"
    >
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <img src={gmail} width={24} height={24} />
        <Typography variant="subtitle1">Gmail</Typography>
        {gmailEmail && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle2">{gmailEmail}</Typography>
            <StatusCell
              label={t("userSettings.connected")}
              type={JOB_STATUSES.ACTIVE}
            />
          </Stack>
        )}
      </Stack>
      <Button onClick={onAuthDisconnectGmail} variant="contained">
        {gmailEmail ? t("customers.disconnect") : t("userSettings.connect")}
      </Button>
    </StyledDataContainer>
    <StyledDataContainer justifyContent="space-between" flexDirection="row">
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <img src={outlook} width={24} height={24} />
        <Typography variant="subtitle1">Outlook</Typography>
        {outlookEmail && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle2">{outlookEmail}</Typography>
            <StatusCell
              label={t("userSettings.connected")}
              type={JOB_STATUSES.ACTIVE}
            />
          </Stack>
        )}
      </Stack>
      <Button onClick={onAuthDisconnectOutlook} variant="contained">
        {outlookEmail ? t("customers.disconnect") : t("userSettings.connect")}
      </Button>
    </StyledDataContainer>
  </StyledDataContainer>
);

export default ConnectEmail;
