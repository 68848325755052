import React from "react";
import { Stack, Button, Typography, Tooltip } from "@mui/material";
import StatusCell from "../../components/DataGrid/cellRenderers/status";
import { JOB_STATUSES } from "../../models/JobList";
import {
  IIntegrationComponent,
  INTEGRATION_GROUP,
} from "../../models/Integrations";
import { StyledIntegrationItem } from "./style";

const Integration = ({
  t,
  integration,
  onOpenConnectDialog,
}: IIntegrationComponent) => (
  <StyledIntegrationItem spacing={1.5}>
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={1.5} alignItems="center">
        <img src={integration.logo} alt="integration-logo" />
        <Typography variant="subtitle1" color="#000">
          {integration.title}
        </Typography>
      </Stack>
      {integration.isConnected && (
        <StatusCell
          label={t("userSettings.connected")}
          type={JOB_STATUSES.ACTIVE}
        />
      )}
    </Stack>
    <Typography variant="subtitle2" color="#000" height="100%">
      {integration.description}
    </Typography>
    {integration.group === INTEGRATION_GROUP.INTEGRATION ? (
      integration.isConnected ? (
        <Button variant="outlined" fullWidth onClick={() => {}}>
          {t("customers.disconnect")}
        </Button>
      ) : (
        <Button
          variant="contained"
          fullWidth
          onClick={() => onOpenConnectDialog(integration)}
        >
          {t("userSettings.connect")}
        </Button>
      )
    ) : integration.group === INTEGRATION_GROUP.EXTERNAL_PROFILES ? (
      <Button
        variant="contained"
        fullWidth
        onClick={() => onOpenConnectDialog(integration)}
      >
        {integration.isConnected
          ? t("single_job.edit")
          : t("userSettings.connect")}
      </Button>
    ) : (
      <Tooltip title={t("integrations.disconnectQuickApplyInterfacesTootlip")}>
        <Stack>
          <Button variant="outlined" disabled>
            {t("customers.disconnect")}
          </Button>
        </Stack>
      </Tooltip>
    )}
  </StyledIntegrationItem>
);

export default Integration;
