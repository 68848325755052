import React from "react";
import { Box, Checkbox, Stack } from "@mui/material";
import { Controller } from "react-hook-form";
import KOQuestionLocation from "./Location";
import KOQuestionsWorkingPermitEU from "./WorkingPermitEU";
import KoQuestionSalary from "./Salary";
import KOQuestionStartDate from "./StartDate";
import KoQuestionControlledTextField from "./ControlledTextfield";
import KoQuestionMultipleSelection from "./ControlledMultipleSelection";
import KOQuestionLanguages from "./Languages";
import KOQuestionPhone from "./Phone";
import KOQuestionDocuments from "./Documents";
import Loading from "../../../components/Loading";
import {
  StyledAgreementContainer,
  StyledJobPreviewTemplateSectionContent,
  StyledJobPreviewTemplateSectionTitle,
  StyledLoadingApplyButton,
} from "../styles";
import { IKOQuestionComponent } from "../../../models/JobPreview";
import { StyledKOQuestionsContainer } from "./styles";

const KOQuestionComponent = ({
  t,
  control,
  errors,
  jobData,
  containerStyle,
  isApplyInProgress,
  companyColor,
  driverLicenses,
  fields,
  skills,
  showCompanyAgreementText,
  languages,
  levels,
  jobTemplate,
  isVisible,
  filterOptions,
  locale,
  onAddLanguage,
  onRemoveLanguage,
  handleSubmit,
  onSubmit,
}: IKOQuestionComponent) =>
  isVisible ? (
    <StyledKOQuestionsContainer
      companyColor={companyColor}
      style={containerStyle}
    >
      <StyledJobPreviewTemplateSectionTitle>
        {t("apply_page.apply_now")}
      </StyledJobPreviewTemplateSectionTitle>

      <StyledJobPreviewTemplateSectionContent pt={1} pb={3}>
        {t("apply_page.apply_text")}
      </StyledJobPreviewTemplateSectionContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box flex={1}>
          <Stack spacing={1.5} flex={1}>
            {!!jobData.job.phone_required && (
              <KOQuestionPhone
                t={t}
                control={control}
                errors={errors}
                companyColor={companyColor}
              />
            )}
            {!!jobData.job.location_required && (
              <KOQuestionLocation t={t} control={control} errors={errors} />
            )}
            {!!jobData.job.working_permit_eu_required && (
              <KOQuestionsWorkingPermitEU
                t={t}
                control={control}
                errors={errors}
              />
            )}
            {!!jobData.job.salary_expectation_required && (
              <Stack pt={errors.location ? 2 : 0}>
                <KoQuestionSalary t={t} control={control} errors={errors} />
              </Stack>
            )}
            {!!jobData.job.earliest_start_date_required && (
              <KOQuestionStartDate
                t={t}
                control={control}
                errors={errors}
                locale={locale}
              />
            )}
            {!!jobData.job.current_job_required && (
              <KoQuestionControlledTextField
                t={t}
                control={control}
                errors={errors}
                value="currentJob"
                title={t("create_job_third_step.current_position")}
                placeholder={t(
                  "additional_questions.type_something_placeholder"
                )}
              />
            )}
            {!!jobData.job.highest_degree_required && (
              <KoQuestionControlledTextField
                t={t}
                control={control}
                errors={errors}
                value="highestDegree"
                title={t("create_job_third_step.highest_degree")}
                placeholder={t(
                  "additional_questions.type_something_placeholder"
                )}
              />
            )}
            {!!jobData.job.drivers_license_required && (
              <KoQuestionMultipleSelection
                t={t}
                companyColor={companyColor}
                control={control}
                errors={errors}
                error={errors.driverLicense}
                value="driverLicense"
                title={t("create_job_third_step.driving_licenses")}
                placeholder={t(
                  "additional_questions.driving_licenses_placeholder"
                )}
                options={driverLicenses}
                filterOptions={filterOptions}
              />
            )}
            {!!jobData.job.langs_required && (
              <KOQuestionLanguages
                t={t}
                fields={fields}
                control={control}
                languages={languages}
                levels={levels}
                errors={errors}
                companyColor={companyColor}
                filterOptions={filterOptions}
                onAdd={onAddLanguage}
                onRemove={onRemoveLanguage}
              />
            )}
            {!!jobData.job.skills_required && (
              <Box flex={1}>
                <KoQuestionMultipleSelection
                  t={t}
                  companyColor={companyColor}
                  control={control}
                  errors={errors}
                  error={errors.skills}
                  value="skills"
                  title={t("create_job_third_step.skills")}
                  placeholder={t("additional_questions.skills_placeholder")}
                  options={skills}
                  filterOptions={filterOptions}
                />
              </Box>
            )}

            {!!jobData.job.other_documents_required && (
              <KOQuestionDocuments
                t={t}
                control={control}
                errors={errors}
                companyColor={companyColor}
              />
            )}
          </Stack>
        </Box>

        <StyledAgreementContainer
          spacing={1}
          direction="row"
          py={2}
          companyColor={companyColor}
        >
          <Controller
            name="agreement"
            control={control}
            render={({ field: props }) => (
              <Checkbox
                {...props}
                className="checkbox"
                data-testid="apply-job-new-customer-agreement-checkbox"
              />
            )}
          />
          <Stack spacing={2} position="relative" top={10} pb={1.25}>
            <span className="agreement-text">
              {t("apply_page.terms_first_text")}{" "}
              <span
                className="link-text"
                onClick={() =>
                  window.open(
                    "https://www.karriera.de/agb-candidates",
                    "_blank"
                  )
                }
              >
                {t("apply_page.terms_second_text")}
              </span>{" "}
              {t("apply_page.terms_third_text")}{" "}
              <span
                className="link-text"
                onClick={() =>
                  window.open("https://www.karriera.de/datenschutz", "_blank")
                }
              >
                {t("apply_page.terms_forth_text")}
              </span>{" "}
              {t("apply_page.terms_fifth_text")}{" "}
            </span>

            {!!showCompanyAgreementText && (
              <span className="agreement-text">
                {t("apply_page.company_terms_first_text")}{" "}
                <span
                  className="link-text"
                  onClick={() => window.open(jobData?.tc_link, "_blank")}
                >
                  {t("apply_page.company_terms_second_text")}
                </span>{" "}
                {t("apply_page.company_terms_third_text")}{" "}
                <span
                  className="link-text"
                  onClick={() => window.open(jobData?.pp_link, "_blank")}
                >
                  {t("apply_page.company_terms_forth_text")}
                </span>{" "}
                {t("apply_page.company_terms_fifth_text")}{" "}
                {jobTemplate?.company}
                {t("apply_page.company_terms_sixth_text")}
                {errors.agreement && (
                  <span className="error">
                    <br />
                    {errors.agreement.message}
                  </span>
                )}
              </span>
            )}
          </Stack>
        </StyledAgreementContainer>

        <Stack>
          <StyledLoadingApplyButton
            data-testid="apply-job-new-customer-submit-button"
            type="submit"
            variant="contained"
            loading={isApplyInProgress}
            companyColor={companyColor}
            loadingPosition="end"
          >
            {t("apply_page.apply_now")}
          </StyledLoadingApplyButton>
        </Stack>
      </form>
    </StyledKOQuestionsContainer>
  ) : (
    <Loading />
  );

export default KOQuestionComponent;
