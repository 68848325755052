import * as yup from "yup";
import { TFunction } from "i18next";
import { TCareerPageFormDefaultValue } from "../../models/CompanySettings";

export const getFormSettings = (t: TFunction) => {
  return yup
    .object()
    .shape({
      website: yup.string().required(t("register.message") as string),
      employees: yup.string().required(t("register.message") as string),
      industry_id: yup.string().required(t("register.message") as string),
      is_page_active: yup.boolean(),
      about_us: yup.string(),
      company_color: yup.string(),
      picture_one: yup.mixed().nullable(),
      picture_two: yup.mixed().nullable(),
      picture_three: yup.mixed().nullable(),
      video_link: yup.string(),
    })
    .required();
};

export const formDefaultValues: TCareerPageFormDefaultValue = {
  website: "",
  employees: "",
  industry_id: "",
  is_page_active: 1,
  about_us: "",
  color: "#178CF2",
  company_color: "#178CF2",
  picture_one: undefined,
  picture_two: undefined,
  picture_three: undefined,
  reset_picture_one: false,
  reset_picture_two: false,
  reset_picture_three: false,
  benefits: [],
  video_link: "",
};
