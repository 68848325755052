import React from "react";
import { Stack, Typography, Tooltip } from "@mui/material";
import {
  BuildingOffice2Icon,
  GlobeAltIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import WebSiteItem from "../../CompanyInfo/WebSiteItem";
import linkedinIcon from "../../../assets/linkedinIcon.png";
import xingIcon from "../../../assets/xingIcon.png";
import {
  StyledAboutContainer,
  StyledCompanyHeaderContainer,
} from "../../CompanyInfo/style";
import { ICompanyHeader } from "../../../models/JobPreview";

const CompanyHeader = ({
  t,
  company,
  desktop,
  industry,
  isShowMore,
  isShowMoreExist,
  shortText,
  fullText,
  onShowMoreLess,
}: ICompanyHeader) => (
  <Stack
    alignItems="center"
    sx={{
      background: "#F9FAFB",
    }}
    px={3}
  >
    <StyledCompanyHeaderContainer
      companyColor={company.color}
      desktop={desktop}
      isJobPreviewPage
    >
      <Stack
        height={desktop ? 64 : "auto"}
        width="100%"
        direction={desktop ? "row" : "column"}
        spacing={3}
        flexWrap={
          company.title && !desktop && company?.title?.length > 24
            ? "wrap"
            : "nowrap"
        }
        alignItems={
          desktop ? (isShowMoreExist ? "center" : "center") : "normal"
        }
      >
        {company.logo && <img className="logo" src={company.logo} alt="logo" />}
        <Stack
          width="100%"
          justifyContent={desktop ? "space-between" : "flex-start"}
          spacing={desktop ? 2 : 0}
        >
          <Typography
            variant="h5"
            color="#131E30"
            overflow="hidden"
            textOverflow="ellipsis"
            width="90vw"
            sx={{
              display: "-webkit-box",
              "-webkit-line-clamp": "2",
              "-webkit-box-orient": "vertical",
            }}
          >
            {company.title}
          </Typography>

          <Stack
            mt={desktop ? 0 : 1.5}
            spacing={desktop ? 3.25 : 1}
            direction={desktop ? "row" : "column"}
          >
            <Stack direction="row" gap={1} alignItems="center">
              <UsersIcon className="icon" />
              <Typography
                variant="subtitle2"
                display="inline"
                lineHeight="143%"
              >
                {company?.employees} {t("company_page.employees")}
              </Typography>
            </Stack>
            {industry && (
              <Stack direction="row" gap={1} alignItems="center">
                <BuildingOffice2Icon className="icon" />
                <Tooltip title={industry}>
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    lineHeight="143%"
                    maxWidth={desktop ? 250 : "auto"}
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {industry}
                  </Typography>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      {company?.about_us &&
        company?.about_us.replace(/<p>\s*(<br>)?\s*<\/p>/g, "") !== "" && (
          <Stack spacing={0.5} width="100%">
            <StyledAboutContainer
              dangerouslySetInnerHTML={{
                __html: isShowMore ? fullText : shortText,
              }}
            />
            {isShowMoreExist && (
              <Typography
                display="inline"
                variant="subtitle2"
                color={company.color}
                whiteSpace="nowrap"
                sx={{ cursor: "pointer" }}
                onClick={onShowMoreLess}
              >
                {isShowMore
                  ? t("company_page.show_less")
                  : t("company_page.show_more")}
              </Typography>
            )}
          </Stack>
        )}

      <Stack
        mt={desktop ? 3 : 2}
        direction="row"
        spacing={1}
        position="relative"
        right={desktop ? 0 : 10}
      >
        <WebSiteItem
          text={t("company_page.website")}
          icon={<GlobeAltIcon className="icon" />}
          url={company.website}
          companyColor={company?.color}
        />
        {company.linkedin_url && (
          <WebSiteItem
            text="Linkedin"
            icon={<img src={linkedinIcon} className="icon" />}
            url={company.linkedin_url}
            companyColor={company?.color}
          />
        )}
        {company.xing_url && (
          <WebSiteItem
            text="Xing"
            icon={<img src={xingIcon} className="icon" />}
            url={company.xing_url}
            companyColor={company?.color}
          />
        )}
      </Stack>
    </StyledCompanyHeaderContainer>
  </Stack>
);

export default CompanyHeader;
