import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MenuItem, Tab, Tabs } from "@mui/material";
import { TabContext } from "@mui/lab";
import CompanySettings from "../CompanySettings";
import CareerPage from "../Career";
import JobWidget from "../JobWidgetPage";
import Workflow from "../Workflow";
import Automations from "../Automations";
import TeamSettings from "../TeamSettings";
import Integrations from "../Integrations";
import { ISettingsComponent } from "../../models/CurrentUser";
import {
  StyledPremiumJobBoardsTabMenu,
  StyledSingleJobContainer,
  StyledTabPanel,
} from "../SingleJob/styles";
import { StyledTeamsTabsContainer } from "../TeamSettings/styles";
import { SETTINGS_PAGE_TYPES } from "../../models/TopHeader";
import { TEAM_USER_STATUSES } from "../../models/Team";

const GeneralPageComponent = ({
  t,
  desktop,
  selectedSettingsTab,
  tabs,
  menuOpen,
  lang,
  anchorEl,
  onSelectMenuItem,
  onClickTeamTab,
  onChangeTab,
}: ISettingsComponent) => (
  <StyledSingleJobContainer mt={3}>
    <TabContext value={selectedSettingsTab}>
      <StyledTeamsTabsContainer>
        <Tabs value={selectedSettingsTab} onChange={onChangeTab}>
          {tabs.map((tab: SETTINGS_PAGE_TYPES, index: number) =>
            tab === SETTINGS_PAGE_TYPES.TEAM ? (
              <Tab
                key={tab + index}
                label={
                  <div
                    aria-controls={menuOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                    className="premium-job-boards-tab"
                    onClick={onClickTeamTab}
                  >
                    {t(`navigation.${tab}`)}
                    <ArrowDropDownIcon />
                  </div>
                }
                value={tab}
              />
            ) : (
              <Tab
                key={tab + index}
                label={t(`navigation.${tab}`)}
                value={tab}
              />
            )
          )}
        </Tabs>
      </StyledTeamsTabsContainer>
      <StyledTabPanel value={SETTINGS_PAGE_TYPES.GENERAL}>
        <CompanySettings />
      </StyledTabPanel>
      <StyledTabPanel value={SETTINGS_PAGE_TYPES.CAREER}>
        <CareerPage />
      </StyledTabPanel>
      <StyledTabPanel value={SETTINGS_PAGE_TYPES.JOB_WIDGET}>
        <JobWidget desktop={desktop} />
      </StyledTabPanel>
      <StyledTabPanel value={SETTINGS_PAGE_TYPES.WORKFLOWS}>
        <Workflow />
      </StyledTabPanel>
      <StyledTabPanel value={SETTINGS_PAGE_TYPES.AUTOMATION}>
        <Automations />
      </StyledTabPanel>
      <StyledTabPanel value={SETTINGS_PAGE_TYPES.TEAM}>
        <TeamSettings />
      </StyledTabPanel>
      <StyledTabPanel value={SETTINGS_PAGE_TYPES.INTEGRATIONS}>
        <Integrations />
      </StyledTabPanel>
    </TabContext>
    <StyledPremiumJobBoardsTabMenu
      lang={lang}
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={() => onSelectMenuItem()}
    >
      <MenuItem onClick={() => onSelectMenuItem(TEAM_USER_STATUSES.JOINED)}>
        {t("team_members.joined")}
      </MenuItem>
      <MenuItem onClick={() => onSelectMenuItem(TEAM_USER_STATUSES.PENDING)}>
        {t("team_members.pending")}
      </MenuItem>
    </StyledPremiumJobBoardsTabMenu>
  </StyledSingleJobContainer>
);

export default GeneralPageComponent;
