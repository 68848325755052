import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CVSection from "./CVSection";
import { StyledLoadingApplyButton, StyledUserDataContainer } from "../styles";
import { getJobPreviewState } from "../../../store/selectors/JobPreview";
import {
  applyToJob,
  setApplyData,
  setStep,
} from "../../../store/reducers/JobPreview";
import { getCurrentUserData } from "../../../store/selectors/CurrentUser";
import {
  jobActionLoggedCandidateFormDefaultValues,
  jobActionLoggedCandidateFormFields,
} from "./loggedCandidateConfig";
import { TJobActionLoggedCandidateFormFields } from "../../../models/JobPreview";

const LoggedCandidate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { key: jobUrlKey } = useParams();
  const [searchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source") || "";
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { email, username, firstName, lastName, profile_picture, cv } =
    useSelector(getCurrentUserData);
  const { isApplyInProgress, companyColor, jobData } =
    useSelector(getJobPreviewState);

  const [uploadedCV, setUploadedCV] = useState<FileList | null>(null);
  const [oldCVFileName, setOldCVFileName] = useState<string>("");

  const hasRequiredFieldSet = Object.keys(jobData.job)
    .filter(
      (key) =>
        key.includes("required") &&
        !(key === "cv_required" && jobData.job[key] === 1)
    )
    .some((key) => jobData.job[key] === 1);

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(
      jobActionLoggedCandidateFormFields(t, jobData.job.cv_required)
    ),
    defaultValues: jobActionLoggedCandidateFormDefaultValues,
  });

  const handleOnUploadCV = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) setUploadedCV(event.target.files);
  };

  const handleOnResetCV = (event: MouseEvent) => {
    setUploadedCV(null);
    setOldCVFileName("");
    setValue("cv", null);
    event.preventDefault();
  };

  const handleOnNavigateHome = () => {
    navigate("/");
  };

  const handleOnApply = (formFields: TJobActionLoggedCandidateFormFields) => {
    const body = {
      ...formFields,
      ...(utm_source && { utm_source }),
      files: uploadedCV,
    };
    if (hasRequiredFieldSet) {
      dispatch(
        setApplyData({
          ...body,
          email,
          firstname: firstName,
          lastname: lastName,
          ...(uploadedCV && { files: uploadedCV }),
          ...(!uploadedCV &&
            !jobData.job.cv_required && { use_latest_cv: true }),
          ...(utm_source && { utm_source }),
        })
      );
      dispatch(setStep(2));
    } else {
      if (email && firstName && lastName && jobUrlKey) {
        dispatch(
          applyToJob({
            jobUrlKey,
            body: {
              ...body,
              email,
              firstname: firstName,
              lastname: lastName,
              ...(uploadedCV && { files: uploadedCV }),
              ...(!uploadedCV &&
                !jobData.job.cv_required && { use_latest_cv: true }),
              ...(utm_source && { utm_source }),
            },
            callback: () => {
              navigate(`/candidate/login/additional-questions/${jobUrlKey}`);
            },
          })
        );
      }
    }
  };

  useEffect(() => {
    if (cv?.length) {
      setOldCVFileName(cv.substring(66));
    }
  }, [cv]);

  return (
    <Stack mt={3} spacing={3}>
      <form onSubmit={handleSubmit(handleOnApply)}>
        <StyledUserDataContainer
          companyColor={companyColor}
          direction={"row"}
          spacing={1.5}
          onClick={handleOnNavigateHome}
        >
          <Avatar
            src={profile_picture || ""}
            alt="profile-photo"
            className="login-avatar"
            data-testid={"apply-job-user-avatar"}
          />
          <Stack spacing={0.5} height="100%" justifyContent="center">
            <Typography
              variant="subtitle2"
              noWrap
              maxWidth={176}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {firstName} {lastName}
            </Typography>

            <Typography variant="subtitle2" color="textSecondary" fontSize={12}>
              {email || username}
            </Typography>
          </Stack>
          <ChevronRightIcon />
        </StyledUserDataContainer>
        <Stack mt={jobData.job.cv_required ? 3 : 1.5} mb={3}>
          <CVSection
            t={t}
            oldCVFileName={oldCVFileName}
            companyColor={companyColor}
            control={control as any}
            isRequired={jobData.job.cv_required}
            uploadedCV={uploadedCV?.[0]}
            onUploadCV={handleOnUploadCV}
            onResetCV={handleOnResetCV}
          />
          {errors.cv && (
            <Typography pl={0.75} pt={0.5} color="error" variant="subtitle2">
              {errors.cv.message}
            </Typography>
          )}
        </Stack>
        <StyledLoadingApplyButton
          fullWidth
          type="submit"
          data-testid="apply-job-logged-customer-submit-button"
          variant="contained"
          loadingPosition="end"
          loading={isApplyInProgress}
          companyColor={companyColor}
        >
          {hasRequiredFieldSet
            ? t("button.continue")
            : t("apply_page.apply_now")}
        </StyledLoadingApplyButton>
      </form>
    </Stack>
  );
};

export default LoggedCandidate;
