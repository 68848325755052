import React from "react";
import {
  Dialog,
  DialogActions,
  Button,
  Stack,
  Typography,
  TextField,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { IConnectIntegrationDialog } from "../../../models/dialogs";
import { INTEGRATION_TYPES } from "../../../models/Integrations";

const ConnectIntegrationDialog = ({
  t,
  isOpen,
  selectedIntegration,
  showPassword,
  errors,
  register,
  onChangeShowPassword,
  onClose,
  onSave,
  handleSubmit,
}: IConnectIntegrationDialog) => (
  <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={"sm"}>
    <Stack p={3} pb={2} direction="row" alignItems="center" spacing={1.5}>
      <img
        width="auto"
        height={24}
        src={selectedIntegration?.logo}
        alt="integration-logo"
        style={{
          objectFit: "contain",
        }}
      />
      <Typography variant="subtitle1">{selectedIntegration?.title}</Typography>
    </Stack>
    <form onSubmit={handleSubmit(onSave)}>
      <Stack px={3} spacing={1.5}>
        {selectedIntegration?.title === INTEGRATION_TYPES.CONCLUDIS && (
          <Stack>
            <InputLabel>{t("integrations.companyId")}</InputLabel>
            <TextField
              {...register("companyId")}
              error={Boolean(errors.companyId)}
              helperText={errors.companyId?.message}
              placeholder={t("integrations.companyId") as string}
              fullWidth
            />
          </Stack>
        )}
        {selectedIntegration?.title === INTEGRATION_TYPES.ONLYFY && (
          <Stack>
            <InputLabel>API Key</InputLabel>
            <TextField
              {...register("apiKey")}
              error={Boolean(errors.apiKey)}
              helperText={errors.apiKey?.message}
              placeholder="API Key"
              fullWidth
            />
          </Stack>
        )}
        {selectedIntegration?.title === INTEGRATION_TYPES.HR4YOU && (
          <Stack>
            <InputLabel>Url</InputLabel>
            <TextField
              {...register("url")}
              error={Boolean(errors.url)}
              helperText={errors.url?.message}
              placeholder="Url"
              fullWidth
            />
          </Stack>
        )}
        {selectedIntegration?.title !== INTEGRATION_TYPES.ONLYFY && (
          <Stack>
            <InputLabel>{t("integrations.username")}</InputLabel>
            <TextField
              {...register("username")}
              error={Boolean(errors.username)}
              helperText={errors.username?.message}
              placeholder={t("integrations.username") as string}
              fullWidth
            />
          </Stack>
        )}
        {selectedIntegration?.title !== INTEGRATION_TYPES.ONLYFY && (
          <Stack>
            <InputLabel>{t("apply_page.password")}</InputLabel>
            <TextField
              {...register("password")}
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              placeholder={t("apply_page.password") as string}
              fullWidth
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack width={19.75} height={19.75}>
                      {showPassword ? (
                        <EyeSlashIcon
                          onClick={onChangeShowPassword}
                          cursor={"pointer"}
                        />
                      ) : (
                        <EyeIcon
                          onClick={onChangeShowPassword}
                          cursor={"pointer"}
                        />
                      )}
                    </Stack>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        )}
      </Stack>
      <DialogActions sx={{ p: 3 }}>
        <Button variant="outlined" onClick={onClose}>
          {t("button.secondCancel")}
        </Button>
        <Button variant="contained" type="submit">
          {t("userSettings.connect")}
        </Button>
      </DialogActions>
    </form>
  </Dialog>
);

export default ConnectIntegrationDialog;
