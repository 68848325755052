import React from "react";
import {
  BriefcaseIcon,
  ChatBubbleOvalLeftIcon,
  CreditCardIcon,
  RectangleGroupIcon,
  UserIcon,
  UsersIcon,
  UserGroupIcon,
  ChartBarIcon,
  CogIcon,
} from "@heroicons/react/24/outline";
import { TFunction } from "i18next";
import { JOB_STATUSES } from "../../../models/JobList";
import { ISidebarMenuItem, SIDEBAR_MENU_ITEM_IDS } from "../../../models/Menu";

export const getMenuItems = ({
  t,
  isAgency,
  agencyMenuItems,
  isCandidate,
}: {
  t: TFunction;
  isAgency: boolean;
  agencyMenuItems: ISidebarMenuItem[];
  isCandidate: boolean;
}) =>
  isCandidate
    ? [
        {
          label: "Dashboard",
          icon: <RectangleGroupIcon />,
          nodeId: SIDEBAR_MENU_ITEM_IDS.DASHBOARD,
          route: `/`,
        },
        {
          label: t("navigation.messages"),
          icon: <ChatBubbleOvalLeftIcon />,
          nodeId: SIDEBAR_MENU_ITEM_IDS.MESSAGES,
          route: `/messages`,
        },
        {
          label: t("navigation.profile"),
          icon: <UserIcon />,
          nodeId: SIDEBAR_MENU_ITEM_IDS.USER_SETTINGS,
          route: `/user-settings`,
        },
      ]
    : isAgency
      ? agencyMenuItems
      : [
          {
            label: "Dashboard",
            icon: <RectangleGroupIcon />,
            nodeId: SIDEBAR_MENU_ITEM_IDS.DASHBOARD,
            route: `/dashboard`,
          },
          {
            label: "Jobs",
            icon: <BriefcaseIcon />,
            nodeId: SIDEBAR_MENU_ITEM_IDS.JOBS,
            route: `/jobs/${JOB_STATUSES.ACTIVE}`,
          },
          {
            label: t("navigation.applications"),
            icon: <UsersIcon />,
            nodeId: SIDEBAR_MENU_ITEM_IDS.APPLICATIONS,
            route: `/applications`,
          },
          {
            label: t("talentPools.talentPools"),
            icon: <UserGroupIcon />,
            nodeId: SIDEBAR_MENU_ITEM_IDS.TALENTPOOLS,
            route: `/talentpools`,
          },
          {
            label: t("navigation.messages"),
            icon: <ChatBubbleOvalLeftIcon />,
            nodeId: SIDEBAR_MENU_ITEM_IDS.MESSAGES,
            route: `/messages`,
          },
          {
            label: t("navigation.analytics"),
            icon: <ChartBarIcon />,
            nodeId: SIDEBAR_MENU_ITEM_IDS.ANALYTICS,
            route: `/analytics`,
          },
          {
            label: "Shop",
            icon: <CreditCardIcon />,
            route: `/discover`,
            nodeId: SIDEBAR_MENU_ITEM_IDS.DISCOVER,
          },
          {
            label: t("navigation.settings"),
            icon: <CogIcon />,
            route: `/settings/general`,
            nodeId: SIDEBAR_MENU_ITEM_IDS.SETTINGS,
          },
          /* AFTER FINISH inventory        {
          label: "Shop",
          icon: <CreditCardIcon />,
          nodeId: "shop",
          children: [
            {
              label: t("navigation.discover"),
              icon: (
                <StyledSubItemIconContainer>
                  <CircleIcon className={"circle-icon"} />
                </StyledSubItemIconContainer>
              ),
              nodeId: SIDEBAR_MENU_ITEM_IDS.DISCOVER,
              route: `/discover`,
            },
            {
              label: t("navigation.inventory"),
              icon: (
                <StyledSubItemIconContainer>
                  <CircleIcon className={"circle-icon"} />
                </StyledSubItemIconContainer>
              ),
              route: `/inventory`,
            },
          ],
        }, */
        ];
