import React from "react";
import { Box, Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import {
  CheckCircleIcon,
  ClockIcon,
  PlusCircleIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import ProductsEmptyState from "./ProductsEmptyState";
import {
  IPremiumPackagesComponent,
  TSalesProduct,
} from "../../../models/SingleJob";
import {
  StyledDaysBlock,
  StyledDiscountLabel,
  StyledPackageBodyItem,
  StyledPackageContainer,
  StyledPackageContainerHeader,
  StyledPackagePriceBlock,
  StyledPremiumPackagesProductsContainer,
  StyledShoppingCartButtonContainer,
  StyledSuccessButton,
} from "../styles";
import { StyledPreviewButton } from "../../../components/JobDialogs/styles";
import { formatSalaryValue } from "../../../utils";

const PremiumPackagesComponent = ({
  t,
  packages,
  isJobEditorPage,
  selectedProducts,
  isPremiumJobBoardsTab,
  onOpenProduct,
  isSelected,
  onAddPackage,
  onRemoveFromCart,
  onResetFilters,
  onHoverLeaveSelectPackageButton,
  onOpenCart,
}: IPremiumPackagesComponent) => (
  <StyledPremiumPackagesProductsContainer>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="body1" color={"#131E30"} fontWeight={500}>
        {t("create_job_fourth_step.packages")}
      </Typography>
      {isPremiumJobBoardsTab && (
        <Stack width="100%" alignItems="flex-end" position="relative" top={0}>
          <StyledShoppingCartButtonContainer
            isPremiumJobBoardsTab
            onClick={onOpenCart}
          >
            <StyledPreviewButton>
              <ShoppingBagIcon width={24} height={24} />
            </StyledPreviewButton>
            <Stack className={"count-label"}>{selectedProducts.length}</Stack>
          </StyledShoppingCartButtonContainer>
        </Stack>
      )}
    </Stack>
    {packages?.length ? (
      <Grid container spacing={2} mt={2}>
        {packages.map((jobPackage) => (
          <Grid
            item
            xl={isJobEditorPage ? 6 : 4}
            sm={6}
            key={jobPackage.id}
            onClick={() => onOpenProduct(jobPackage)}
            sx={{ cursor: "pointer" }}
          >
            <StyledPackageContainer>
              <StyledPackageContainerHeader className={"header"}>
                <Typography variant="body1">
                  {jobPackage.package_name}
                </Typography>
                {jobPackage?.discount && jobPackage?.discount > 0 && (
                  <StyledDiscountLabel>
                    {jobPackage.discount} %
                  </StyledDiscountLabel>
                )}
              </StyledPackageContainerHeader>
              <Stack spacing={1} mt={2} p={2}>
                {jobPackage.sales_products?.map(
                  (product: TSalesProduct, productIndex: number) => (
                    <StyledPackageBodyItem spacing={1} key={productIndex}>
                      <img src={product.image_url} alt={product.supplier} />
                      <Stack direction="row" justifyContent="space-between">
                        <Stack>
                          <Tooltip title={product.name}>
                            <Typography
                              variant="subtitle2"
                              noWrap
                              maxWidth={210}
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {product.name}
                            </Typography>
                          </Tooltip>
                        </Stack>
                        <StyledDaysBlock alignSelf="flex-end">
                          <ClockIcon />
                          <span>
                            {product.runtime_in_days}{" "}
                            {t("create_job_fourth_step.days")}
                          </span>
                        </StyledDaysBlock>
                      </Stack>
                    </StyledPackageBodyItem>
                  )
                )}
              </Stack>
              <Stack mt={"auto"} p={2} pt={0}>
                <StyledPackagePriceBlock className={"price-bar"}>
                  <Typography variant="body2">
                    {t("create_job_fourth_step.price")}:
                  </Typography>
                  <Stack spacing={1} direction={"row"}>
                    <Box className={"old-price"}>
                      {formatSalaryValue(jobPackage?.oldPrice as number)} €
                    </Box>
                    <Box className={"new-price"}>
                      {formatSalaryValue(jobPackage.package_price)} €
                    </Box>
                  </Stack>
                </StyledPackagePriceBlock>

                {isSelected(jobPackage) ? (
                  <StyledSuccessButton
                    variant={"contained"}
                    onClick={() => onRemoveFromCart(jobPackage)}
                  >
                    <Stack spacing={1.25} direction={"row"}>
                      <CheckCircleIcon width={24} height={24} />
                      <Box>{t("create_job_fourth_step.package_added")}</Box>
                    </Stack>
                  </StyledSuccessButton>
                ) : (
                  <Button
                    variant={"contained"}
                    onClick={() => onAddPackage(jobPackage)}
                    onMouseOver={() => onHoverLeaveSelectPackageButton(true)}
                    onMouseLeave={() => onHoverLeaveSelectPackageButton(false)}
                  >
                    <Stack spacing={1.25} direction={"row"}>
                      <PlusCircleIcon width={24} height={24} />
                      <Box>{t("create_job_fourth_step.add_package")}</Box>
                    </Stack>
                  </Button>
                )}
              </Stack>
            </StyledPackageContainer>
          </Grid>
        ))}
      </Grid>
    ) : (
      <ProductsEmptyState t={t} onResetFilters={onResetFilters} />
    )}
  </StyledPremiumPackagesProductsContainer>
);

export default PremiumPackagesComponent;
