import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { PayloadActionWithCallback, TListOption } from "../../models/common";
import {
  ICompanySettingsState,
  TCareerPageFormDefaultValue,
  TCompanyAddressUpdatePayload,
  TCompanySettingsCompany,
  TCompanySettingsFormDefaultValue,
  TCompanyUpdatePayload,
} from "../../models/CompanySettings";

const initialState: ICompanySettingsState = {
  countries: [],
  employees: [],
  industries: [],
  company: null,
  isImageLoading: false,
  isCompanyDataLoading: false,
  isEmployeesLoading: false,
  isIndustriesLoading: false,
  isCountriesLoading: false,
  isDisconnectLoading: false,
  isAddressUpdateLoading: false,
  error: null,
  companyLogoBlob: null,
  companyLogo: null,
  isCompanyLogoLoading: false,
};

const companyDataReducers = {
  fetchCompanyData: (state: Draft<ICompanySettingsState>) => {
    state.isCompanyDataLoading = true;
  },
  fetchCompanyDataSuccess: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<{ company: TCompanySettingsCompany }>
  ) => {
    const { company } = action.payload;
    state.company = company;
    state.isCompanyDataLoading = false;
  },
  fetchCompanyDataFailed: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isCompanyDataLoading = false;
  },
  updateCompanyData: (
    state: Draft<ICompanySettingsState>,
    action: PayloadActionWithCallback<{
      readonly type: "logo" | "form";
      readonly data:
        | TCompanyUpdatePayload
        | TCompanySettingsFormDefaultValue
        | TCareerPageFormDefaultValue;
    }>
  ) => {
    const { type } = action.payload;
    if (type === "logo") state.isImageLoading = true;
    else state.isCompanyDataLoading = true;
  },
  updateCompanyDataSuccess: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<{
      readonly type: "logo" | "form";
    }>
  ) => {
    const { type } = action.payload;
    if (type === "logo") state.isImageLoading = false;
    else state.isCompanyDataLoading = false;
  },
  updateCompanyDataFailed: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<{
      readonly type: "logo" | "form";
      readonly error: unknown;
    }>
  ) => {
    const { error, type } = action.payload;
    state.error = error;
    if (type === "logo") state.isImageLoading = false;
    else state.isCompanyDataLoading = false;
  },
  disconnectAgency: (
    state: Draft<ICompanySettingsState>,
    _: PayloadActionWithCallback<{
      id: number;
    }>
  ) => {
    state.isDisconnectLoading = true;
  },
  disconnectAgencySuccess: (state: Draft<ICompanySettingsState>) => {
    state.isDisconnectLoading = false;
  },
  disconnectAgencyFailed: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isDisconnectLoading = false;
  },
  downloadCompanyLogo: (state: Draft<ICompanySettingsState>) => {
    // TODO define type of error and handle error
    state.isCompanyDataLoading = true;
  },
  downloadCompanyLogoFailed: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.isCompanyDataLoading = false;
    state.error = action.payload;
  },
  downloadCompanyLogoSuccess: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<any>
  ) => {
    // TODO define type of error and handle error
    state.isCompanyDataLoading = false;
    state.companyLogo = action.payload;
  },
  fetchLogoBlob: (
    state: Draft<ICompanySettingsState>,
    _: PayloadAction<string>
  ) => {
    state.isCompanyDataLoading = true;
  },
  fetchLogoBlobFailed: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<unknown>
  ) => {
    state.isCompanyDataLoading = false;
    state.error = action.payload;
  },
  fetchLogoBlobSuccess: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<unknown>
  ) => {
    state.isCompanyDataLoading = false;
    state.companyLogoBlob = action.payload;
  },
};

const registrationDataReducers = {
  fetchRegistrationData: (state: Draft<ICompanySettingsState>) => {
    state.isEmployeesLoading = true;
    state.isIndustriesLoading = true;
  },
  fetchRegistrationDataSuccess: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<{
      readonly employees: TListOption[];
      readonly industries: TListOption[];
    }>
  ) => {
    const { employees, industries } = action.payload;
    state.employees = employees;
    state.industries = industries;
    state.isEmployeesLoading = false;
    state.isIndustriesLoading = false;
  },
  fetchRegistrationDataFailed: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isEmployeesLoading = false;
    state.isIndustriesLoading = false;
  },
  updateCompanyAddress: (
    state: Draft<ICompanySettingsState>,
    _: PayloadActionWithCallback<{ data: TCompanyAddressUpdatePayload }>
  ) => {
    state.isAddressUpdateLoading = true;
  },
  updateCompanyAddressFailed: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<unknown>
  ) => {
    state.isAddressUpdateLoading = false;
    state.error = action.payload;
  },
  updateCompanyAddressSuccess: (state: Draft<ICompanySettingsState>) => {
    state.isAddressUpdateLoading = false;
  },
};

const countriesReducers = {
  fetchCountries: (state: Draft<ICompanySettingsState>) => {
    state.isCountriesLoading = true;
  },
  fetchCountriesSuccess: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<{ countries: TListOption[] }>
  ) => {
    const { countries } = action.payload;
    state.countries = countries;
    state.isCountriesLoading = false;
  },
  fetchCountriesFailed: (
    state: Draft<ICompanySettingsState>,
    action: PayloadAction<unknown>
  ) => {
    state.error = action.payload;
    state.isCountriesLoading = false;
  },
};

export const CompanySettingsSlice = createSlice({
  name: "COMPANY_SETTINGS",
  initialState,
  reducers: {
    ...companyDataReducers,
    ...countriesReducers,
    ...registrationDataReducers,
  },
});

export const {
  fetchCompanyData,
  fetchCompanyDataSuccess,
  fetchCompanyDataFailed,
  updateCompanyData,
  updateCompanyDataSuccess,
  updateCompanyDataFailed,
  fetchRegistrationData,
  fetchRegistrationDataSuccess,
  fetchRegistrationDataFailed,
  fetchCountries,
  fetchCountriesSuccess,
  fetchCountriesFailed,
  disconnectAgency,
  disconnectAgencyFailed,
  disconnectAgencySuccess,
  updateCompanyAddress,
  updateCompanyAddressFailed,
  updateCompanyAddressSuccess,
  downloadCompanyLogo,
  downloadCompanyLogoFailed,
  downloadCompanyLogoSuccess,
  fetchLogoBlob,
  fetchLogoBlobFailed,
  fetchLogoBlobSuccess,
} = CompanySettingsSlice.actions;

export default CompanySettingsSlice.reducer;
