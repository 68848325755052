import { TFunction } from "i18next";
import React from "react";
import { DIALOG_TYPE } from "./common";
import { TJobOwner } from "./SingleJob";
import { TJob } from "./CompanyInfo";

// api

export type TApiGetJobsPayload = {
  readonly title?: TJobListFilters["active" | "archived"]["quickSearch"];
  readonly seniority_id?: TJobListFilters[
    | "active"
    | "archived"]["seniority"]["value"];
  readonly job_type_id?: TJobListFilters[
    | "active"
    | "archived"]["jobType"]["value"];
  readonly location?: TJobListFilters["active" | "archived"]["locations"];
  readonly pagenum?: TJobListPagination["active" | "archived"]["pageNum"];
  readonly pagesize: TJobListPagination["active" | "archived"]["pageSize"];
};

export interface IJobListNoResultComponent {
  readonly actionText: string;
  readonly bodyText: string;
  readonly titleText: string;
  readonly hasFilters: boolean;
  readonly imgSrc: string;
  readonly showButton: boolean;
  readonly onResetFilters: () => void;
  readonly onToggleDialog: () => void;
}

export interface IJobListComponent {
  readonly t: TFunction;
  readonly onOpenCustomerDialog: () => void;
  readonly onCloseCustomerDialog: () => void;
  readonly isAgency?: boolean;
  readonly isApplyJobDialogOpen: boolean;
  readonly onOpenApplyJobDialog: () => void;
  readonly onCloseApplyJobDialog: () => void;
  readonly onConfirmDeleteJob: () => void;
  readonly actions: {
    readonly onSetWorkflow?: (data: TJobListItem) => void;
    readonly onArchiveRestore: (urlKey: TJobListItem) => void;
    readonly onClick: (payload: {
      readonly id: TJobListItem["id"];
      readonly title: TJobListItem["title"];
      readonly created_at: TJobListItem["created_at"];
      readonly workflow_id: TJobListItem["workflow_id"];
    }) => void;
    readonly onClickApplicationsNumber: (payload: {
      readonly id: TJobListItem["id"];
      readonly title: TJobListItem["title"];
      readonly created_at: TJobListItem["created_at"];
      readonly workflow_id: TJobListItem["workflow_id"];
    }) => void;
    readonly onEdit: (payload: { readonly id: TJobListItem["id"] }) => void;
    readonly onDelete?: (job: TJobListItem) => void;
    readonly onDuplicate: (job: TJobListItem) => void;
    readonly onExport: (job: TJobListItem | TJobListItem[]) => void;
    readonly onPreview: (urlKey: TJobListItem["url_key"]) => void;
    readonly onContinueEditing: (job: TJob) => void;
    readonly onInvite: (data: TJobListItem) => void;
    readonly onPromote: (payload: { readonly id: TJobListItem["id"] }) => void;
  };
  readonly openedDialogType: DIALOG_TYPE | null;
  readonly selectedJobStatus: JOB_STATUSES;
  readonly jobStatuses: JOB_STATUSES[];
  readonly jobList: TJobListItem[];
  readonly isLoading: boolean;
  readonly isListLoading: boolean;
  readonly pageNum: number;
  readonly pageSize: number;
  readonly jobCount: string;
  readonly onPaginate: (pageNum: number) => void;
  readonly onCloseDialog: () => void;
  readonly onSelect?: (job: TJobListItem) => void;
  readonly onTabChange: (
    event: React.SyntheticEvent,
    value: JOB_STATUSES
  ) => void;
}

export interface IJobListFilterComponent {
  readonly jobFilter: IJobListState["filters"][
    | "active"
    | "archived"]["jobType"];
  readonly seniorityFilter: IJobListState["filters"][
    | "active"
    | "archived"]["seniority"];
  readonly t: TFunction;
  readonly isAgency?: boolean;
  readonly locations: IJobListState["filters"][
    | "active"
    | "archived"]["locations"];
  readonly quickSearch: IJobListState["filters"][
    | "active"
    | "archived"]["locations"];
  readonly companyName: IJobListState["filters"][
    | "active"
    | "archived"]["locations"];
  readonly onChange: (payload: {
    readonly value: IJobListState["filters"]["active" | "archived"][
      | "seniority"
      | "jobType"]["value"];
    readonly filterType: keyof TJobListFilterWithOptionsTypes;
  }) => void;
  readonly onChangeSimpleFilter: (payload: {
    readonly filter: keyof TJobListSimpleFilterTypes;
    readonly value: IJobListState["filters"]["active" | "archived"][
      | "locations"
      | "quickSearch"];
  }) => void;
}

export interface IJobListState {
  readonly filters: TJobListFilters;
  readonly navigation: TJobListNavigation;
  readonly pagination: TJobListPagination;
  readonly jobs: TJobListJobs;
  readonly allActiveJobs?: TJobListItem[];
  readonly selectedJobs: TJobListItem[];
  readonly error: unknown;
  readonly isLoading: boolean;
  readonly isListLoading: boolean;
  readonly isAllJobsLoading: boolean;
  readonly isDraftImagesLoading: boolean;
}

export type TJobListFilters = {
  readonly active: TJobListFilterTypes;
  readonly archived: TJobListFilterTypes;
  readonly draft: TJobListFilterTypes;
};

export type TJobListFilterTypes = {
  readonly locations: string;
  readonly quickSearch: string;
  readonly companyName?: string;
  readonly jobType: {
    readonly value: string;
    readonly options: { readonly label: string; readonly value: string }[];
  };
  readonly seniority: {
    readonly value: string;
    readonly options: { readonly label: string; readonly value: string }[];
  };
};

export type TJobListSimpleFilterTypes = Pick<
  TJobListFilterTypes,
  "quickSearch" | "locations" | "companyName"
>;

export type TJobListFilterWithOptionsTypes = Pick<
  TJobListFilterTypes,
  "jobType" | "seniority"
>;

export type TJobListNavigation = {
  readonly jobsStatuses: JOB_STATUSES[];
  readonly selectedJobStatus: JOB_STATUSES;
};

export type TJobListPagination = {
  readonly active: {
    readonly pageNum: number;
    readonly pageSize: number;
  };
  readonly archived: {
    readonly pageNum: number;
    readonly pageSize: number;
  };
  readonly draft: {
    readonly pageNum: number;
    readonly pageSize: number;
  };
};

export type TJobListJobs = {
  readonly list: TJobListItem[];
  readonly totalCount: string; // TODO should be number
};

export interface IJobListToolbar {
  readonly t: TFunction;
  readonly onArchive: (url_key: TJobListItem) => void;
  readonly onExport: (jobs: TJobListItem[]) => void;
  readonly onPreview: (url_key: TJobListItem["url_key"]) => void;
  readonly onAddApplication: (url_key: TJobListItem["url_key"]) => void;
}

export interface IJobListArchiveRestoreModal {
  readonly t: TFunction;
  readonly isLoading: boolean;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onConfirmArchive: () => void;
  readonly type: JOB_STATUSES;
}

export interface IJobListDeleteJobModal {
  readonly t: TFunction;
  readonly isLoading: boolean;
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onConfirmDeleteJob: () => void;
}

export type TJobListItem = {
  readonly id: string;
  readonly company_id: string;
  readonly title: string;
  readonly url_key: string;
  readonly publish_date: string | null; // TODO should be Date
  readonly created_at: string; // TODO should be Date
  readonly contract_type_id: string; // TODO should be number
  readonly contract_type: string;
  readonly seniority_id: string; // TODO should be number
  readonly seniority_level: string;
  readonly work_field_id: string; // TODO should be number
  readonly field_of_work: string;
  readonly position_type_id: string; // TODO should be number
  readonly industry_id: string; // TODO should be number
  readonly industry_title: string;
  readonly position_type: string;
  readonly company_title: string;
  readonly company_jass_id: string; // TODO should be number
  readonly applicationsCount: string; // TODO should be number
  readonly publicationsCount: string;
  readonly qualification_id: string;
  readonly qualification_title: string;
  readonly workflow_id: number | null;
  readonly owners: TJobOwner[];
  readonly locations: TJobListLocation[];
  readonly cv_required?: string;
  readonly phone_required?: string;
  readonly location_required?: string;
  readonly working_permit_eu_required?: string;
  readonly salary_expectation_required?: string;
  readonly earliest_start_date_required?: string;
  readonly current_job_required?: string;
  readonly highest_degree_required?: string;
  readonly drivers_license_required?: string;
  readonly langs_required?: string;
  readonly skills_required?: string;
  readonly other_documents_required?: string;
};

export type TJobListLocation = {
  readonly id: string;
  readonly country: string;
  readonly city: string;
  readonly zip: string;
  readonly job_id: string; // TODO should be number
};

export enum JOB_STATUSES {
  ACTIVE = "active",
  INACTIVE = "Inactive",
  ARCHIVED = "archived",
  DRAFT = "draft",
}

export enum JOB_ACTIONS {
  ARCHIVE = "archive",
  RESTORE = "restore",
  UNPUBLISH = "unpublish",
}
