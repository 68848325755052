import React from "react";
import {
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { IKOQuestionField } from "../../../models/JobPreview";
import { StyledKoQuestionContainer } from "./styles";
import {
  formatSalaryFieldValue,
  formatSalaryValue,
  removeNonNumeric,
} from "../../../utils";

const KoQuestionSalary = ({ t, control, errors }: IKOQuestionField) => (
  <StyledKoQuestionContainer>
    <InputLabel className="required-label">
      {t("create_job_second_step.salary")}
    </InputLabel>
    <Controller
      name="salaryExpectation"
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          error={Boolean(errors.salaryExpectation)}
          helperText={errors.salaryExpectation?.message}
          placeholder="0.00"
          fullWidth
          value={formatSalaryValue(value as unknown as number)}
          onChange={(e) =>
            onChange(formatSalaryFieldValue(removeNonNumeric(e.target.value)))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="subtitle2" color="textSecondary">
                  €
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  </StyledKoQuestionContainer>
);

export default KoQuestionSalary;
