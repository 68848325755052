import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "debounce";
import AutomationsComponent from "./component";
import {
  activateDeactivateAutomation,
  deleteAutomation,
  fetchActions,
  fetchAutomations,
  fetchTriggers,
  setAutomationsFilter,
  setCurrentAutomation,
} from "../../store/reducers/Automation";
import { getAutomationState } from "../../store/selectors/Automation";
import { IAutomationAction, ICurrentAutomation } from "../../models/Automation";
import { getCurrentUserData } from "../../store/selectors/CurrentUser";
import { addMessage } from "../../store/reducers/Snackbar";

const Automations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [deleteAutomationId, setDeleteAutomationId] = useState<number | null>(
    null
  );
  const { firstName } = useSelector(getCurrentUserData);
  const {
    automations,
    isListLoading,
    filter,
    triggers,
    isTriggersLoading,
    isActionsLoading,
    actions,
  } = useSelector(getAutomationState);

  const handleCreateAutomation = () => {
    navigate("/automation");
  };

  const isAutomationsLoading =
    isListLoading || isTriggersLoading || isActionsLoading || !firstName;
  const handleOpenAutomation = (data: any) => {
    const findTrigger = triggers.find(
      (trigger) => trigger.const === data.event_const
    );
    const formattedTrigger = {
      name: findTrigger?.name || "",
      const: findTrigger?.const || "",
      params: findTrigger?.params || "",
      type: findTrigger?.type || "",
    };

    const formattedActions = data.action_const.map(
      (actionConst: string, index: number) => {
        const findAction = actions.find(
          (action: IAutomationAction) => action.const === actionConst
        );
        const formattedAction = {
          ...findAction,
          actionCompleted: true,
          id: uuidv4(),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          params: data.params[index],
        };
        return formattedAction;
      }
    );

    const formattedAutomation = {
      id: data.id,
      enabled: data.enabled,
      name: data.name,
      trigger: formattedTrigger,
      actions: formattedActions,
      conditions: data.conditions,
      conditions_operators: data.conditions_operators,
    };

    dispatch(setCurrentAutomation(formattedAutomation as ICurrentAutomation));
    navigate("/automation");
  };

  const handleOpenCloseDeleteDialog = (data: any) => {
    if (data) {
      setIsDeleteDialogOpen(true);
      setDeleteAutomationId(data.id);
    } else {
      setIsDeleteDialogOpen(false);
      setDeleteAutomationId(null);
    }
  };

  const onSuccessDeleteAutomation = () => {
    setIsDeleteDialogOpen(false);
    dispatch(
      addMessage({
        title: t("automation.deleteAutomationSuccess"),
        type: "success",
      })
    );
    dispatch(fetchAutomations());
  };

  const handleDeleteAutomation = () => {
    dispatch(
      deleteAutomation({
        id: deleteAutomationId as number,
        callback: onSuccessDeleteAutomation,
      })
    );
  };

  const handleChangeAutomationsFilter = (value: string) => {
    dispatch(setAutomationsFilter(value));
  };

  const onSuccessChangeAutomationStatus = () => {
    dispatch(
      addMessage({
        title: t("automation.changeStatusSuccess"),
        type: "success",
      })
    );
    dispatch(fetchAutomations({ withoutLoader: true }));
  };

  const handleEnabledDisabledAutomation = (data: ICurrentAutomation) => {
    dispatch(
      activateDeactivateAutomation({
        data,
        withoutLoader: true,
        callback: onSuccessChangeAutomationStatus,
      })
    );
  };

  const debouncedChangeAutomationsFilter = debounce(
    handleChangeAutomationsFilter,
    300
  );

  useEffect(() => {
    dispatch(fetchAutomations());
  }, [filter]);

  useEffect(() => {
    if (!triggers.length && firstName && !isTriggersLoading) {
      setTimeout(() => {
        dispatch(fetchTriggers());
      }, 300);
    }
    if (!actions.length && firstName && !isActionsLoading) {
      setTimeout(() => {
        dispatch(fetchActions());
      }, 300);
    }
  }, []);

  return (
    <AutomationsComponent
      t={t}
      automations={automations}
      isListLoading={isAutomationsLoading}
      isDeleteDialogOpen={isDeleteDialogOpen}
      onChangeAutomationsFilter={debouncedChangeAutomationsFilter}
      onEnabledDisabledAutomation={handleEnabledDisabledAutomation}
      onDeleteAutomation={handleDeleteAutomation}
      onOpenCloseDeleteDialog={handleOpenCloseDeleteDialog}
      onOpenAutomation={handleOpenAutomation}
      onCreateAutomation={handleCreateAutomation}
    />
  );
};

export default Automations;
