import { styled } from "@mui/system";
import { Stack } from "@mui/material";

export const StyledUploadButtonContainer = styled(Stack)(
  ({ companyColor }: { companyColor?: string }) => ({
    label: {
      border: companyColor
        ? `1px solid ${companyColor} !important`
        : "1px solid #E5E7EB !important",
      borderRadius: 6,
      color: companyColor ?? "#6B7280",
      justifyContent: "space-between",
      fontWeight: 400,
      svg: {
        width: 20,
        height: 20,
      },
      "&:hover": { background: "transparent" },
    },
  })
);

export const StyledFileContainer = styled(Stack)({
  height: 40,
  alignItems: "center",
  fontSize: 14,
  padding: "0px 16px",
  border: "1px solid #E5E7EB !important",
  borderRadius: 6,
  color: "#6B7280",
  fontWeight: 400,
  "&:hover": { background: "transparent" },
  span: {
    maxWidth: "calc(100% - 56px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  svg: {
    width: 20,
    height: 20,
    "&.success-icon": { stroke: "#22C55E" },
    "&.remove-icon": {
      stroke: "#EF4444",
      marginLeft: "auto",
      cursor: "pointer",
    },
  },
});
