import React from "react";
import { EyeIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import ColorPicker from "../../components/ColorPicker";
import AutocompleteFilter from "../../components/filters/AutocompleteFilter";
import ProfilePicture from "../../components/ProfilePicture";
import Loading from "../../components/Loading";
import ChipsManager from "../../components/ChipsManager";
import CompanyImagesBox from "./CompanyImages";
import TextEditor from "../../components/TextEditor";
import { TListOption } from "../../models/common";
import { ICompanySettingsCareerPage } from "../../models/CompanySettings";
import { getEmployeeId } from "../../utils/companySettings";
import {
  StyledCompanySettingsContainer,
  StyledSelect,
  StyledSwitchContainer,
} from "../CompanySettings/styles";

const colorPickerStyle = {
  marginLeft: -240,
};

const CareerPageComponent = ({
  t,
  control,
  employees,
  errors,
  industries,
  logo,
  isFormDataLoading,
  isFormChanged,
  benefits,
  register,
  onChangeBenefits,
  onPreview,
  onDeleteLogo,
  onOpenUploadDialog,
  onSubmit,
}: ICompanySettingsCareerPage) =>
  isFormDataLoading ? (
    <Loading />
  ) : (
    <StyledCompanySettingsContainer spacing={2}>
      <Stack height={40}>
        <Controller
          name="is_page_active"
          control={control}
          render={({ field: { onChange, value } }) => (
            <StyledSwitchContainer direction={"row"}>
              <p>
                {value
                  ? t("company_settings.switch_text_on")
                  : t("company_settings.switch_text_off")}
              </p>
              <Box p={1.25}>
                <Switch
                  checked={Boolean(value)}
                  color="secondary"
                  onChange={(event, checked) => {
                    onChange(checked ? 1 : 0);
                  }}
                />
              </Box>
            </StyledSwitchContainer>
          )}
        />
      </Stack>
      <Stack direction="row" spacing={3}>
        <Stack spacing={2.55}>
          <InputLabel>{t("company_settings.company_logo")}</InputLabel>
          <ProfilePicture
            type="square"
            imgSrc={logo}
            onDelete={onDeleteLogo}
            onOpenUploadDialog={onOpenUploadDialog}
          />
        </Stack>
        <Stack width="100%" spacing={1.5}>
          <Stack direction="row" spacing={3}>
            <Stack flex={1}>
              <InputLabel>
                {t("company_settings.number_of_employees") as string}
              </InputLabel>
              <Controller
                name={"employees"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <StyledSelect
                    disabled={false}
                    fullWidth
                    value={getEmployeeId({ employees, value })}
                    displayEmpty
                    variant="outlined"
                    onChange={onChange}
                  >
                    {employees.map((employee: TListOption) => (
                      <MenuItem key={employee.value} value={employee.value}>
                        {employee.label}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
              />
            </Stack>
            <Stack flex={1}>
              <InputLabel>{t("company_settings.color")}</InputLabel>
              <Controller
                name={"company_color"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ColorPicker
                    title={t("company_settings.color")}
                    color={value}
                    onChange={onChange}
                    customStyle={colorPickerStyle}
                  />
                )}
              />
            </Stack>
          </Stack>

          <Stack direction="row" spacing={3}>
            <Stack flex={1} pb={errors.industry_id ? 1.75 : 0}>
              <InputLabel>{t("company_settings.industry")}</InputLabel>
              <Controller
                name={"industry_id"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutocompleteFilter
                    onChange={onChange}
                    value={value}
                    options={industries}
                    placeholder={t("company_settings.industry")}
                    errors={errors.industry_id}
                  />
                )}
              />
            </Stack>
            <Stack flex={1}>
              <InputLabel>{t("company_settings.link_to_website")}</InputLabel>
              <TextField
                fullWidth
                {...register("website")}
                error={Boolean(errors.website)}
                helperText={errors.website?.message}
                placeholder="company.com"
                InputProps={{
                  startAdornment: (
                    <Typography variant="subtitle2">https://</Typography>
                  ),
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack flex={1}>
        <InputLabel>{t("company_settings.about_us")}</InputLabel>
        <Controller
          name={"about_us"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextEditor value={value as string} onChange={onChange} />
          )}
        />
      </Stack>
      <Stack flex={1}>
        <InputLabel>Benefits</InputLabel>
        <ChipsManager
          listHeight={120}
          showListAlways
          placeholder="Benefit"
          chipList={benefits}
          onChange={onChangeBenefits}
        />
      </Stack>
      <CompanyImagesBox t={t} control={control} />
      <TextField
        placeholder={t("create_job_first_step.video_placeholder") as string}
        fullWidth
        {...register("video_link")}
      />
      <Stack pt={1} direction="row" alignSelf="flex-end" spacing={3}>
        <Button
          variant={"outlined"}
          startIcon={<EyeIcon width={24} height={24} />}
          onClick={onPreview}
        >
          {t("create_job_first_step.preview")}
        </Button>
        <Button
          className={"submit-btn"}
          size="small"
          disableElevation
          variant="contained"
          onClick={onSubmit}
          disabled={!isFormChanged || Boolean(Object.keys(errors)?.length)}
        >
          {t("button.save")}
        </Button>
      </Stack>
    </StyledCompanySettingsContainer>
  );

export default CareerPageComponent;
