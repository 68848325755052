import React, { useEffect } from "react";
import DashboardComponent from "./component";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchJobs } from "../../store/reducers/JobList";
import { JOB_STATUSES } from "../../models/JobList";
import { fetchApplications } from "../../store/reducers/Applications";
import { getCurrentUserData } from "../../store/selectors/CurrentUser";
import { getWorkflowState } from "../../store/selectors/Workflow";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isAgency } = useSelector(getCurrentUserData);
  const { isWorkflowsListLoaded } = useSelector(getWorkflowState);

  useEffect(() => {
    dispatch(fetchJobs(JOB_STATUSES.ACTIVE));
    if (isWorkflowsListLoaded) {
      dispatch(fetchApplications());
    }
  }, [isWorkflowsListLoaded]);

  return <DashboardComponent t={t} isAgency={Boolean(isAgency)} />;
};

export default Dashboard;
