import React from "react";
import {
  Divider,
  Tooltip,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  IJobBasicQuestionsSection,
  TJobBasicQuestionsFormFields,
} from "../../../models/JobEditor";
import { StyledBasicQuestionsContainer } from "../styles";

const JobBasicQuestionsSection = ({
  t,
  control,
  basicQuestionsTitles,
}: IJobBasicQuestionsSection) => (
  <>
    <Typography variant="body2">
      {t("create_job_third_step.basic_questions")}
    </Typography>
    <StyledBasicQuestionsContainer spacing={1}>
      {Object.keys(basicQuestionsTitles).map(
        (questionTitle: string, index: number) => (
          <Stack key={questionTitle} spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle2" color="textSecondary">
                {basicQuestionsTitles[questionTitle]}
              </Typography>
              <Controller
                name={questionTitle as keyof TJobBasicQuestionsFormFields}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    row
                    value={value}
                    onChange={(_, newValue) => onChange(newValue)}
                  >
                    <Tooltip title={t("create_job_third_step.ko_question")}>
                      <Radio
                        value="0"
                        color="secondary"
                        sx={{
                          "&:hover": {
                            backgroundColor: "#9f9d9d29 !important",
                          },
                        }}
                      />
                    </Tooltip>
                    <Tooltip title={t("status.active")}>
                      <Radio
                        value="1"
                        sx={{
                          color: "#22C55E !important",
                          "&.Mui-checked": { color: "#22C55E !important" },
                          "&:hover": {
                            backgroundColor: "#9f9d9d29 !important",
                          },
                        }}
                      />
                    </Tooltip>
                    <Tooltip title={t("status.inactive")}>
                      <Radio
                        value="2"
                        sx={{
                          color: "#EF4444 !important",
                          "&.Mui-checked": { color: "#EF4444 !important" },
                          "&:hover": {
                            backgroundColor: "#9f9d9d29 !important",
                          },
                        }}
                      />
                    </Tooltip>
                  </RadioGroup>
                )}
              />
            </Stack>
            {index !== Object.keys(basicQuestionsTitles).length - 1 && (
              <Divider />
            )}
          </Stack>
        )
      )}
    </StyledBasicQuestionsContainer>
  </>
);

export default JobBasicQuestionsSection;
