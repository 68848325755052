import { LoadingButton } from "@mui/lab";
import { darken, lighten } from "polished";
import { Box, Button, Chip, IconButton, MenuItem, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { APPLICATION_STATUSES } from "../../models/Applications";
import { IDataGridStatusCell } from "../../models/DataGrid";
import { JOB_STATUSES } from "../../models/JobList";
import { TEAM_USER_ROLES, TEAM_USER_STATUSES } from "../../models/Team";
import { CUSTOMER_STATUSES } from "../../models/Agency";
import { TStatusListOption } from "../../models/common";
import { ICellRendererParams } from "ag-grid-community";
import { TCandidateApplication } from "../../models/ApplicationPage";

export const StyledStatusChip = styled(Chip)(({
  uniqueStatuses,
  type,
  params,
  currentApplication,
}: {
  uniqueStatuses?: TStatusListOption[];
  type: IDataGridStatusCell["type"] | string;
  params?: ICellRendererParams;
  currentApplication?: TCandidateApplication;
}) => {
  const baseStyle = {
    cursor: "pointer",
    width: "min-content",
    border: "none !important",
    span: { padding: "0 8px" },
    height: 20,
    "&.status-selector": { svg: { width: 14, height: 14 } },
  };

  const dynamicCases = uniqueStatuses?.reduce(
    (acc, item) => {
      const lighterBackgroundColor =
        item.value === "new"
          ? lighten(0.2, item.color)
          : item.value === "rejected"
            ? lighten(0.3, item.color)
            : lighten(0.4, item.color);
      acc[item.value] = {
        ...baseStyle,
        textTransform: "none",
        backgroundColor: `${lighterBackgroundColor} !important`,
        color: `${item.color} !important`,
        "&:hover": {
          backgroundColor:
            params || currentApplication
              ? item.value === "new"
                ? `${darken(0.05, lighterBackgroundColor)} !important`
                : item.value === "rejected"
                  ? `${darken(0.1, lighterBackgroundColor)} !important`
                  : `${darken(0.1, lighterBackgroundColor)} !important`
              : lighterBackgroundColor,
        },
        "&.status-selector": {
          svg: {
            ...baseStyle["&.status-selector"].svg,
            color: `${item.color} !important`,
          },
        },
      };

      return acc;
    },
    {} as { [key: string]: any }
  );

  // Prvo provjeravamo postoji li odgovarajući dynamicCase
  if (dynamicCases && dynamicCases[type]) {
    return dynamicCases[type];
  }

  switch (type) {
    case TEAM_USER_STATUSES.JOINED:
    case JOB_STATUSES.ACTIVE:
    case CUSTOMER_STATUSES.ONLINE:
    case "aktiv":
      return {
        ...baseStyle,
        background: "#DCFCE7!important",
        color: "#22C55E!important",
        textTransform: "capitalize",
      };
    case TEAM_USER_STATUSES.PENDING:
      return {
        ...baseStyle,
        background: "#FFFBEB!important",
        color: "#F59E0B !important",
        textTransform: "capitalize",
      };
    case TEAM_USER_ROLES.ADMIN:
      return {
        ...baseStyle,
        backgroundColor: "#EEF2FF!important",
        color: "#818CF8!important",
      };
    case TEAM_USER_ROLES.RECRUITER:
      return {
        ...baseStyle,
        backgroundColor: "#F3F4F6!important",
        color: "#6B7280!important",
      };
    case JOB_STATUSES.ARCHIVED:
    case CUSTOMER_STATUSES.OFFLINE:
      return {
        ...baseStyle,
        backgroundColor: "#FEF2F2!important",
        color: "#EF4444!important",
        textTransform: "capitalize",
      };
    case APPLICATION_STATUSES.NEW:
    case "K.O. question":
    case "K.O.-Frage":
      return {
        ...baseStyle,
        backgroundColor: "#EEF2FF!important",
        color: "#818CF8 !important",
        "&:hover": {
          backgroundColor: `${darken(0.1, "#EEF2FF")} !important`,
        },
        "&.status-selector": {
          svg: {
            ...baseStyle["&.status-selector"].svg,
            color: "#818CF8!important",
            "&:hover": { color: "#818CF8!important" },
          },
        },
      };
    case APPLICATION_STATUSES.REVIEW:
      return {
        ...baseStyle,
        backgroundColor: "#FFFBEB!important",
        color: "#F59E0B!important",
        "&.status-selector": {
          svg: {
            ...baseStyle["&.status-selector"].svg,
            color: "#F59E0B!important",
            "&:hover": { color: "#F59E0B!important" },
          },
        },
      };
    case APPLICATION_STATUSES.REJECTED:
    case JOB_STATUSES.INACTIVE:
    case "Inaktiv":
      return {
        ...baseStyle,
        backgroundColor: "#FEF2F2!important",
        color: "#EF4444!important",
        "&.status-selector": {
          svg: {
            ...baseStyle["&.status-selector"].svg,
            color: "#EF4444!important",
            "&:hover": { color: "#EF4444!important" },
          },
        },
      };
    case APPLICATION_STATUSES.HIRED:
      return {
        ...baseStyle,
        backgroundColor: "#F0FDF4!important",
        color: "#22C55E!important",
        "&.status-selector": {
          svg: {
            ...baseStyle["&.status-selector"].svg,
            color: "#22C55E!important",
            "&:hover": { color: "#22C55E!important" },
          },
        },
      };

    default:
      // Ako ne postoji, vraćamo defaultni stil
      return {
        ...baseStyle,
      };
  }
});

export const StyledActionButton = styled(Button)({
  backgroundColor: "#EDF7FF",
  color: "#178CF2",
  boxShadow: "none",
  padding: "10px",
  height: 32,
  "&:disabled": {
    color: "#C6E5FF",
  },
  "&:hover": {
    backgroundColor: "#C6E5FF",
  },
});

export const StyledActionLoadingButton = styled(LoadingButton)(
  ({ continueEditingButtonWidth }: { continueEditingButtonWidth: number }) => ({
    backgroundColor: "#EDF7FF",
    minWidth: continueEditingButtonWidth,
    maxWidth: continueEditingButtonWidth,
    color: "#178CF2",
    boxShadow: "none",
    padding: "6px 10px",
    height: 32,
    "&:hover": {
      backgroundColor: "#C6E5FF",
    },
  })
);

export const StyledIconButton = styled(IconButton)({
  width: 36,
  height: 36,
  padding: 6,
});

export const StyledCVContainer = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  ".cv-icon": {
    padding: 4,
    marginRight: 2,
    width: 32,
    height: 32,
    stroke: "#178CF2",
    cursor: "pointer",
    "&:hover": {
      background: "#9f9d9d29",
      borderRadius: "50%",
    },
  },
  ".cv-file-name": {
    cursor: "pointer",
    color: "rgb(107, 114, 128)",
    width: "calc(100% - 37px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

export const StyledLogoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const StyledApplicationsNumber = styled(Stack)({
  cursor: "pointer",
  flexDirection: "row",
  padding: 4,
  height: 28,
  width: 50,
  gap: 4,
  borderRadius: 100,
  alignItems: "center",
  background: "#F3F4F6",
  "&:hover": {
    background: "#C6E5FF",
  },
  ".icon-container": {
    padding: 3,
    width: 20,
    height: 20,
    background: "#fff",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    svg: {
      stroke: "#6B7280",
    },
  },
});

export const StyledDeleteMenuItem = styled(MenuItem)({
  color: "#EF4444 !important",
  ":hover": {
    background: "rgba(239, 68, 68, 0.04)",
  },
});
