import React from "react";
import { Stack, InputLabel } from "@mui/material";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { Controller } from "react-hook-form";
import AutocompleteFilter from "../../../components/filters/AutocompleteFilter";
import SelectorFilter from "../../../components/filters/SelectorFilter";
import {
  StyledAddLanguageButton,
  StyledRemoveLanguageButtonContainer,
} from "../../AdditionalQuestions/styles";
import { StyledLanguagesContainer } from "./styles";
import { IKOQuestionLanguages } from "../../../models/JobPreview";

const KOQuestionLanguages = ({
  t,
  control,
  errors,
  fields,
  languages,
  levels,
  companyColor,
  filterOptions,
  onRemove,
  onAdd,
}: IKOQuestionLanguages) => (
  <StyledLanguagesContainer spacing={1.5}>
    <InputLabel className="required-label">
      {t("create_job_third_step.language_skills")}
    </InputLabel>
    {fields.map((item: any, index: number) => (
      <Stack
        mt={index == 0 ? "4px !important" : "12px !important"}
        key={index}
        direction="row"
        width="100%"
        spacing={1}
      >
        <Stack width="100%">
          <Controller
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            name={`languages[${index}].language`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutocompleteFilter
                filterOptions={filterOptions}
                onChange={onChange}
                value={value as string}
                options={languages}
                placeholder={t("additional_questions.language_placeholder")}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                errors={
                  errors.languages
                    ? errors.languages[index]?.language
                    : undefined
                }
              />
            )}
          />
        </Stack>
        <Stack width="100%">
          <Controller
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            name={`languages[${index}].level`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectorFilter
                isDisabled={false}
                value={value as string}
                onChange={onChange}
                label=""
                placeholder="Level"
                options={levels}
                className="input-selector-filter"
                paperClassname="select-dropdown-one-line"
                error={
                  errors.languages
                    ? errors.languages[index]?.level
                      ? errors.languages[index]?.level?.message
                      : ""
                    : ""
                }
              />
            )}
          />
        </Stack>
        <Stack minWidth="3%">
          {index > 0 && (
            <StyledRemoveLanguageButtonContainer
              height="100%"
              justifyContent="center"
            >
              <XCircleIcon onClick={() => onRemove(index)} />
            </StyledRemoveLanguageButtonContainer>
          )}
        </Stack>
      </Stack>
    ))}
    <StyledAddLanguageButton
      companyColor={companyColor}
      variant="outlined"
      onClick={onAdd}
    >
      {t("additional_questions.add_language")}
    </StyledAddLanguageButton>
  </StyledLanguagesContainer>
);

export default KOQuestionLanguages;
