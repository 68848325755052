import React from "react";
import PhoneInput from "react-phone-input-2";
import { Box, InputLabel, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { IKOQuestionField } from "../../../models/JobPreview";

const KOQuestionPhone = ({
  t,
  errors,
  control,
  companyColor,
}: IKOQuestionField) => (
  <Stack>
    <InputLabel className="required-label">
      {t("create_job_third_step.phone_number")}
    </InputLabel>
    <Controller
      name="phone"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Box
          className="input-field"
          sx={{
            "& .react-tel-input .form-control": {
              "&:focus": {
                boxShadow: errors.phone
                  ? "0 0 5px 2px #FEE2E2 !important"
                  : companyColor,
              },
            },
          }}
        >
          <PhoneInput
            containerStyle={{
              width: "100%",
            }}
            inputStyle={{
              width: "100%",
              marginTop: errors.phone ? 4 : 0,
              padding: "10px 16px 10px 60px",
              borderWidth: 1.5,
              borderColor: errors.phone
                ? "#FCA5A5"
                : companyColor
                  ? companyColor
                  : "#E5E7EB",
            }}
            country="de"
            value={value}
            onChange={onChange}
            preferredCountries={["de", "at", "ch"]}
          />
        </Box>
      )}
    />
    {errors.phone && (
      <Typography mt="4px !important" color="error" variant="subtitle2">
        {errors.phone.message}
      </Typography>
    )}
  </Stack>
);

export default KOQuestionPhone;
