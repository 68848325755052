import { apiRequest } from "./config";

export const apiFetchCandidateApplications = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiUpdateCandidateApplication = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: { phone: string; location: string; country_iso: string };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiUploadCandidateDocument = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: { additional_documents: [File] };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiDeleteCandidateApplication = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: { documentId: number };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiUnlcokCandidateApplication = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });
