import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import TeamsSettingsComponent from "./component";
import { DIALOG_TYPE } from "../../models/common";
import {
  TEAM_USER_STATUSES,
  TTeamsInvitation,
  TTeamsMember,
} from "../../models/Team";
import {
  fetchUsers,
  inviteUsers,
  removeUser,
  setPageNum,
  setQuickSearch,
  setSelectedUserStatus,
} from "../../store/reducers/Team";
import {
  getTeamsFilteredPaginationState,
  getTeamsFiltersState,
  getTeamsIsListLoading,
  getTeamsIsLoading,
  getTeamsNavigationState,
  getTeamsUsersState,
} from "../../store/selectors/Team";
import { getModifiedUserList } from "../../utils/users";
import { getCurrentUserData } from "../../store/selectors/CurrentUser";
import { addMessage } from "../../store/reducers/Snackbar";
import { SETTINGS_PAGE_TYPES } from "../../models/TopHeader";

const TeamSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tab } = useParams();
  const { t } = useTranslation();

  const { username, isAdmin } = useSelector(getCurrentUserData);
  const { selectedUserStatus } = useSelector(getTeamsNavigationState);

  const { list: userList, totalCount: userCount } =
    useSelector(getTeamsUsersState);
  const { quickSearch } = useSelector(getTeamsFiltersState);
  const isLoading = useSelector(getTeamsIsLoading);
  const isListLoading = useSelector(getTeamsIsListLoading);
  const { pageSize, pageNum } = useSelector(getTeamsFilteredPaginationState);
  const [targetUser, setTargetUser] = useState<
    TTeamsMember | TTeamsInvitation | null
  >(null);
  const [emailsToInvite, setEmailsToInvite] = useState<string[]>([]);
  const [openedDialogType, setOpenedDialogType] = useState<DIALOG_TYPE | null>(
    null
  );

  const modifiedUsers = useMemo(
    () =>
      getModifiedUserList({
        activeUser: username,
        userList,
        selectedUserStatus,
      }),
    [userList]
  );

  const pendingActions = {
    onDelete: (user: TTeamsMember | TTeamsInvitation) =>
      handleOnOpenDialog({
        user,
        type: DIALOG_TYPE.DELETE,
      }),
    onReInviteTeamMember: (email: string) => handleOnReInviteTeamMember(email),
  };

  const joinedActions = {
    onDelete: (user: TTeamsMember | TTeamsInvitation) =>
      handleOnOpenDialog({
        user,
        type: DIALOG_TYPE.DELETE,
      }),
  };

  const actions =
    selectedUserStatus == TEAM_USER_STATUSES.PENDING
      ? pendingActions
      : joinedActions;

  const handleOnChangeEmails = (emails: string[]) => {
    setEmailsToInvite(emails);
  };

  const handleOnChangeFilters = (value: string) => {
    dispatch(setQuickSearch(value));
  };

  const handleOnOpenDialog = ({
    user,
    type,
  }: {
    user?: TTeamsMember | TTeamsInvitation;
    type: DIALOG_TYPE;
  }) => {
    if (DIALOG_TYPE.DELETE && user) setTargetUser(user);
    if (DIALOG_TYPE.INVITE) setEmailsToInvite([]);
    setOpenedDialogType(type);
  };

  const handleOnConfirmInvite = () => {
    dispatch(
      inviteUsers({
        emails: emailsToInvite,
        callback: () => {
          dispatch(
            addMessage({
              type: "success",
              title: t("team_members.invite_snackbar_title"),
            })
          );
        },
      })
    );
  };

  const handleOnReInviteTeamMember = (email: string) => {
    dispatch(
      inviteUsers({
        emails: [email],
        callback: () => {
          dispatch(
            addMessage({
              type: "success",
              title: t("team_members.invite_snackbar_title"),
            })
          );
        },
      })
    );
  };

  const handleOnConfirmDelete = () => {
    dispatch(removeUser(targetUser as TTeamsMember & TTeamsInvitation));
  };

  const handleOnCloseDialog = () => {
    setOpenedDialogType(null);
  };

  const handleOnPaginate = (pageNum: number) => {
    dispatch(setPageNum(pageNum));
  };

  useEffect(() => {
    dispatch(fetchUsers(selectedUserStatus));
  }, [selectedUserStatus, quickSearch, pageNum]);

  useEffect(() => {
    if (!isLoading) {
      handleOnCloseDialog();
    }
  }, [isLoading]);

  useEffect(() => {
    if (selectedUserStatus) {
      dispatch(setSelectedUserStatus(selectedUserStatus));
    } else {
      dispatch(setSelectedUserStatus(TEAM_USER_STATUSES.JOINED));
    }
  }, [tab]);

  useEffect(() => {
    if (pageNum > 1 && !modifiedUsers.length) {
      dispatch(setPageNum(pageNum - 1));
    }
  }, [userList]);

  useEffect(() => {
    if (username && !isAdmin) {
      navigate(`/settings/${SETTINGS_PAGE_TYPES.GENERAL}`);
    }
  }, [username]);

  return (
    <TeamsSettingsComponent
      isLoading={isLoading}
      openedDialogType={openedDialogType}
      t={t}
      pageNum={pageNum}
      pageSize={pageSize}
      userCount={userCount}
      userList={modifiedUsers}
      quickSearch={quickSearch}
      isListLoading={isListLoading}
      emailsToInvite={emailsToInvite}
      actions={actions}
      onChangeFilters={handleOnChangeFilters}
      onCloseDialog={handleOnCloseDialog}
      onDelete={handleOnConfirmDelete}
      onEmailsChange={handleOnChangeEmails}
      onInvite={handleOnConfirmInvite}
      onOpenDialog={handleOnOpenDialog}
      onPaginate={handleOnPaginate}
    />
  );
};

export default TeamSettings;
