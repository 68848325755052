import primary from "./colors/primary";
import secondary from "./colors/secondary";
import divider from "./colors/divider";
import text from "./colors/text";
import additional1 from "./colors/additional1";
import additional2 from "./colors/additional2";

const typographyMediun = {
  fontWeight: 500,
};

const typographySemiBold = {
  fontWeight: 600,
};

export const Dark = {
  palette: {
    primary: {
      main: primary[800],
      new: "#818CF8",
      ...primary,
    },
    secondary: {
      main: secondary[500],
      dark: secondary[800],
      light: secondary[400],
      ...secondary,
    },
    background: {
      default: "#ECECF1",
      secondary: "#F9F9FF",
    },
    text: {
      primary: text.primary,
      secondary: text.secondary,
      onPrimary: text.onPrimary,
      headlines: text.headlines,
      secondaryVariant2: text.secondaryVariant2,
      secondaryVariant3: text.secondaryVariant3,
      white: text.white,
      selected: text.selected,
    },
    divider: "#E5E7EB",
    dividerAndOutline: {
      ...divider,
    },
    states: {
      main: "#D1DBFF",
    },
    info: {
      main: "#EEF2FF",
    },
    success: {
      main: "#00DA8B",
      700: "#0DB277",
    },
    error: {
      main: "#EF4444",
    },
    warning: {
      main: "#FFB03A",
      700: "#ED8E20",
    },
    alert: {
      main: "#F64949",
    },
    vip: `#linear-gradient(270.7deg, 
            #DBA223 -1.25%, #F0C954 25.49%, #FEE085 48.55%, #F0C954 73.19%, #DBA223 96.78%)`,
    other: {
      star: "#FFC700",
    },
    additional1,
    additional2,
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 500,
    fontWeightRegular: 600,
    fontWeightMedium: 700,
    fontWeightBold: 800,
    h1: {
      fontSize: "40px",
      lineHeight: "102.4%",
    },
    h2: {
      fontSize: "36px",
      lineHeight: "104.3%",
    },
    h2Bold: {
      fontSize: "60px",
      lineHeight: "120%",
    },
    h3: {
      fontSize: "32px",
      lineHeight: "106.67%",
    },
    h4: {
      fontSize: "28px",
      lineHeight: "109.71%",
    },
    h5: {
      fontSize: "24px",
      lineHeight: "133%",
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
      color: "#131E30",
      fontSize: "20px",
      lineHeight: "119%",
    },
    h6mobile: {
      fontWeight: 500,
      color: "#131E30",
      lineHeight: "114%",
      fontSize: "18px",
    },
    subtitle1: {
      fontSize: "16px",
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: 400,
    },
    title: {
      color: "#0F141B",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "132.74%",
    },
    mobileTitle: {
      ...typographyMediun,
      fontSize: "16px",
      lineHeight: "128%",
    },
    fieldText: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "122%",
      color: "#131E30",
    },
    body1: {
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "132.74%",
    },
    body1underline: {
      fontSize: "18px",
      lineHeight: "132.74%",
      textDecoration: "underline",
    },
    body1medium: {
      ...typographyMediun,
      fontSize: "18px",
      lineHeight: "132.74%",
    },
    body1lineThrough: {
      fontSize: "18px",
      lineHeight: "132.74%",
      textDecoration: "line-through",
    },
    body1bold: {
      ...typographySemiBold,
      fontSize: "18px",
      lineHeight: "132.74%",
    },
    body1italic: {
      fontSize: "18px",
      fontStyle: "italic",
      lineHeight: "132.74%",
    },
    body2: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "128%",
    },
    body2underline: {
      textDecoration: "underline",
      fontSize: "16px",
      lineHeight: "128%",
    },
    body2medium: {
      ...typographyMediun,
      fontSize: "16px",
      lineHeight: "128%",
    },
    body2lineThrough: {
      fontSize: "16px",
      lineHeight: "128%",
      textDecoration: "line-through",
    },
    body2bold: {
      ...typographySemiBold,
      fontSize: "16px",
      lineHeight: "128%",
    },
    body2italic: {
      fontStyle: "italic",
      fontSize: "16px",
      lineHeight: "128%",
    },
    body3: {
      display: "block",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "121.9%",
    },
    body3underline: {
      textDecoration: "underline",
      fontSize: "14px",
      lineHeight: "121.9%",
    },
    body3medium: {
      ...typographyMediun,
      fontSize: "14px",
      lineHeight: "121.9%",
    },
    body3lineThrough: {
      fontSize: "14px",
      lineHeight: "121.9%",
      textDecoration: "line-through",
    },
    body3bold: {
      ...typographySemiBold,
      fontSize: "14px",
      lineHeight: "121.9%",
    },
    body3italic: {
      fontStyle: "italic",
      fontSize: "14px",
      lineHeight: "121.9%",
    },
    body4: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "121.9%",
    },
    body4underline: {
      textDecoration: "underline",
      fontSize: "12px",
      lineHeight: "142.22%",
    },
    body4medium: {
      ...typographyMediun,
      fontSize: "12px",
      lineHeight: "142.22%",
    },
    body4lineThrough: {
      fontSize: "12px",
      lineHeight: "142.22%",
      textDecoration: "line-through",
    },
    body4bold: {
      ...typographySemiBold,
      fontSize: "12px",
      lineHeight: "142.22%",
    },
    body4italic: {
      fontStyle: "italic",
      fontSize: "12px",
      lineHeight: "142.22%",
    },
    label: {
      fontSize: "12px",
      lineHeight: "128%",
    },
    button: {
      fontSize: "16px",
      lineHeight: "107%",
    },
    caption: {
      ...typographySemiBold,
      fontSize: "14px",
      lineHeight: "121.9%",
      textTransform: "uppercase",
    },
    caption2: {
      ...typographySemiBold,
      fontSize: "12px",
      lineHeight: "142.2%",
      textTransform: "uppercase",
    },
    caption3: {
      fontSize: "11px",
      lineHeight: "166%",
    },
    caption4: {
      fontSize: "12px",
      lineHeight: "166%",
    },
    overline: {
      fontSize: "12px",
      lineHeight: "266%",
      textTransform: "uppercase",
    },
    tooltipTitle: {
      fontWeight: 500,
      fontSize: "9px",
      lineHeight: "11px",
      letterSpacing: "0.05em",
    },
    tooltipBody: {
      fontWeight: 400,
      fontSize: "10.5px",
      lineHeight: "13px",
      letterSpacing: "0.02em",
    },
    statsTitle: {
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "14.3px",
      letterSpacing: "0.03em",
    },
    insightsTitle: {
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: "17.58px",
      letterSpacing: "0.16px",
    },
    subscript: {
      fontWeight: 300,
      fontSize: "16px",
      lineHeight: "133%",
      letterSpacing: 0,
    },
    errorText: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#EF4444",
      paddingTop: 4,
    },
  },
  shadows: [
    "none",
    "-3.75px -3.75px 7.5px rgba(255, 255, 255, 0.3), 3.75px 3.75px 11.25px rgba(178, 182, 196, 0.3)",
    "0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
    "0px 3px 3px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
    "0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)",
    "0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)",
    "0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)",
    "0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)",
    "0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)",
  ],

  components: {
    MuiAppBar: {
      root: {
        backgroundColor: primary[800],
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          ".MuiAutocomplete-listbox": {
            "& li": {
              minHeight: 40,
              fontSize: 14,
              color: "#6B7280",
              "&:hover": {
                background: "#F9FAFB",
              },
            },
          },
          ".MuiAutocomplete-noOptions": {
            fontSize: "12px !important",
          },
        },
        secondary: {
          backgroundColor: "#F9F9FF",
        },
        sidebar: {
          backgroundColor: primary[700],
          borderRadius: 0,
        },
        header: {
          backgroundColor: primary[700],
          borderRadius: 0,
        },
        autocompleteDropdown: {
          backgroundColor: primary[600],
          color: "#fff",
          "& li:hover": {
            backgroundColor: "#D1DBFF !important",
            color: primary[800],
          },
          "& .Mui-focused": {
            backgroundColor: "#D1DBFF !important",
            color: primary[800],
            "& .MuiSvgIcon-root": {
              color: text.primary,
            },
          },
          scrollbarWidth: "thin",
          scrollbarColor: `${primary[400]} ${primary[100]}`,
          "& ::-webkit-scrollbar": {
            width: 9,
          },
          "& ::-webkit-scrollbar-thumb": {
            background: primary[400],
          },
          "& ::-webkit-scrollbar-track": {
            background: primary[100],
          },
          "& .MuiSvgIcon-root": {
            color: primary[100],
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          color: text.secondary,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&[disabled]": {
            color: "grey !important",
            pointerEvents: "none",
          },
          color: "#178CF2 !important",
          textDecorationColor: "#178CF2 !important",
        },
      },
      variants: [
        {
          props: { variant: "pointer" },
          style: {
            cursor: "pointer",
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "40px !important",
          borderRadius: "6px !important",
          color: "#131ERO",
          background: "#fff !important",
          fontWeight: 400,
          "& fieldset": {
            border: "1px solid #9A9FAF",
          },
          "&:hover fieldset": {
            borderColor: "#E5E7EB !important",
          },
          "& input": {
            "&::placeholder": {
              color: "#9CA3AF !important",
              fontWeight: 400,
            },
          },
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            border: "1px solid #E5E7EB",
          },
          "&.Mui-focused fieldset": {
            border: "1px solid #5FB2FA !important",
            boxShadow: "0 0 5px 2px #C6E5FF",
          },
          "& .Mui-disabled": {
            WebkitTextFillColor: `${primary[200]} !important`,
          },
          "&.Mui-error fieldset": {
            border: "1.5px solid #FCA5A5 !important",
            color: "#FCA5A5 !important",
          },
          "&.Mui-focused, &.Mui-error fieldset": {
            border: "1.5px solid #FCA5A5 !important",
            boxShadow: "0 0 5px 2px #FEE2E2 !important",
          },
          "&.Mui-error ": {
            color: "#FCA5A5 !important",
          },
        },
        colorSecondary: {
          color: "#D3D6E0 !important",
          "& fieldset": {
            border: "1px solid #D3D6E0 !important",
          },
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            border: "1px solid #D3D6E0",
          },
          "&.Mui-focused fieldset": {
            border: "1.5px solid #D3D6E0 !important",
          },
        },
        colorDisabledPhoneNumber: {
          "& .Mui-disabled": {
            WebkitTextFillColor: `#6B7280 !important`,
          },
        },
        input: {
          height: "40px !important",
          fontSize: "14px",
          "&::placeholder": {
            color: "#6B7280",
          },
          "&:-webkit-autofill": {
            WebkitBackgroundClip: "text",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          // When you change this fontSize, then you also have to change legend's fontSize
          overflow: "visible !important",
          fontSize: 14,
          paddingLeft: 3,
          color: "#0F141B",
          "&.Mui-focused": {
            color: "#9A9FAF !important",
          },
          fontWeight: "500",
        },
        colorSecondary: {
          color: "#9A9FAF",
          "&.Mui-focused": {
            color: "#D3D6E0 !important",
          },
        },
        asterisk: {
          color: "#d32f2f",
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined", disabled: true },
          style: {
            "& fieldset.MuiOutlinedInput-notchedOutline": {
              border: "1px solid #CCCCCC !important",
            },
            "& .MuiFormLabel-root.Mui-disabled": {
              color: "#CCCCCC",
            },
          },
        },
        {
          props: { variant: "outlined", disabled: true, color: "secondary" },
          style: {
            "& fieldset.MuiOutlinedInput-notchedOutline": {
              border: "1px solid #7F7F7F !important",
            },
            "& .MuiFormLabel-root.Mui-disabled": {
              color: "#7F7F7F",
            },
          },
        },
        {
          props: { variant: "outlined", specialvariant: "basicInput" },
          style: {
            display: "flex",
            "& .MuiInputBase-input": {
              flex: 1,
              fontWeight: 600,
              fontSize: 16,
              padding: "6.5px 10px",
              textAlign: "center",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          "& legend": {
            fontSize: 10.5,
          },
          "& .MuiSvgIcon-root": {
            color: `${divider[700]} !important`,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: 14,
          textTransform: "none",
          marginTop: 0,
          lineHeight: 1.57,
          marginRight: 0,
          marginLeft: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            color: `${divider[700]} !important`,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px !important",
          color: "#6B7280 !important",
          "&:hover": {
            background: "#F9FAFB",
          },
          minHeight: 40,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: primary[400],
          "&:hover": {
            background: "#9f9d9d29",
          },
          "&:disabled": {
            color: "#4C4C4C",
          },
        },
      },
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { variant: "test" },
          style: {
            color: "red !important",
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          color: "#E5E7EB",
          "&.Mui-checked": {
            color: "#818CF8 !important",
          },
        },
        label: {
          fontSize: "0.8rem",
        },
      },
    },
    MuiAutocomplete: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            root: {
              fontSize: "0.7rem",
              ul: {
                backgroundColor: "red !important",
              },
              "&:li": {
                backgroundColor: "red !important",
              },
            },
            input: {
              color: text.onPrimary,
            },
          },
        },
        {
          props: { color: "secondary" },
          style: {
            input: {
              color: text.primary,
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
          textTransform: "none",
        },
        sizeLarge: {
          fontWeight: 400,
          fontSize: "15px",
          lineHeight: "173%",
          padding: "8px 22px",
        },
        sizeMedium: {
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "171%",
          padding: "6px 16px",
        },
        sizeSmall: {
          fontWeight: 400,
          fontSize: "13px",
          lineHeight: "169%",
          padding: "4px 10px",
        },
        autocompleteButton: {
          color: "#5073E8",
          "&:hover": {
            background: "transparent",
          },
          "&:disabled": {
            color: "#D32F2F",
            "-webkit-text-fill-color": "inherit !important",
          },
        },
        containedPrimary: {
          background: "#178CF2",
          height: "40px !important",
          color: "#FFFFFF",
          boxShadow: "none",
          "&:hover": {
            background: "#304BA8",
            boxShadow: "none",
          },
          "&:disabled": {
            background: "#D3D6E0",
            color: "#9A9FAF",
          },
        },
        containedSecondary: {
          background: "#7645C3",
          height: "40px !important",
          color: "#FFFFFF",
          "&:hover": {
            background: "#441090",
          },
          "&:disabled": {
            background: "#DDD1EF",
            color: "#BBA3E1",
          },
        },
        containedWarning: {
          background: "#FEF2F2",
          height: "40px !important",
          color: "#EF4444",
          boxShadow: "none",
          "&:hover": {
            background: "#FEE2E2",
            boxShadow: "none",
          },
          "&:disabled": {
            background: "#D3D6E0",
            color: "#9A9FAF",
          },
        },
        containedError: {
          background: "#EF4444",
          height: "40px !important",
          color: "#fff",
          boxShadow: "none",
          "&:hover": {
            background: "#DC2626",
            boxShadow: "none",
          },
          "&:disabled": {
            background: "#D3D6E0",
            color: "#9A9FAF",
          },
        },
        outlinedPrimary: {
          border: "1px solid #178CF2 !important",
          height: "40px !important",
          color: "#178CF2",
          "&:hover": {
            background: "#EEF1FD",
          },
          "&:disabled": {
            border: "1px solid #C6E5FF !important",
            color: "#C6E5FF",
          },
        },
        outlinedSecondary: {
          border: "1px solid #7645C3 !important",
          height: "40px !important",
          color: "#7645C3",
          "&:hover": {
            background: "#EEE8F8",
          },
          "&:disabled": {
            border: "1px solid #DDD1EF !important",
            color: "#BBA3E1",
          },
        },
        outlinedError: {
          border: "1px solid #EF4444 !important",
          height: "40px !important",
          color: "#EF4444",
          "&:hover": {
            background: "#EEE8F8",
          },
        },
        textPrimary: {
          color: "#178CF2",
          "&:hover": {
            color: "#178CF2",
            background: "transparent",
          },
          "&:disabled": {
            color: "#9A9FAF",
          },
        },
        textSecondary: {
          color: "#7645C3",
          "&:hover": {
            color: "#441090",
            background: "transparent",
          },
          "&:disabled": {
            color: "#BBA3E1",
          },
        },
        vip: {
          color: "#615124",
          background:
            "linear-gradient(270.7deg, #DBA223 -1.25%, #F0C954 40.54%, #F0C954 64.13%, #EFD37E 97.59%)",
          "&:hover": {
            background:
              "linear-gradient(267.03deg, #5F5132 -73.99%, #A78436 10.88%, #D6B247 80.8%)",
            backgroundBlendMode: "overlay, normal",
          },
          "&:disabled": {
            color: "#625225",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(270.7deg, #DBA223 -1.25%, #F0C954 25.49%, #FEE085 48.55%, #F0C954 73.19%, #DBA223 96.78%)",
            backgroundBlendMode: "darken, normal",
          },
        },
        subscribe: {
          color: "#FFFFFF",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(266.99deg, #161616 -6.18%, #535353 97.97%)padding-box, linear-gradient(270.7deg, #DBA223 -1.25%, #F0C954 40.54%, #F0C954 64.13%, #EFD37E 97.59%)border-box",
          border: "1.9px solid transparent",
          borderRadius: "10px",
          "&:hover": {
            color: "#000000",
            background:
              "linear-gradient(267.03deg, #5F5132 -73.99%, #A78436 10.88%, #D6B247 80.8%)",
            backgroundBlendMode: "overlay, normal",
          },
          "&:disabled": {
            color: "#858585",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(266.99deg, #161616 -6.18%, #535353 97.97%)padding-box, linear-gradient(267.03deg, #5F5132 -73.99%, #A78436 10.88%, #D6B247 80.8%)border-box",
            backgroundBlendMode: "darken, normal",
          },
        },
        menuItem: {
          color: "black",
          textTransform: "capitalize",
          "&:hover": {
            color: "#5073E8",
          },
          fontSize: "15px !important",
          "&.Mui-disabled": {
            // Enables tooltip for disabled buttons
            pointerEvents: "auto",
            cursor: "pointer",
            color: "#4C4C4C",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&:not(.chips-manager, .status-selector)": {
            borderRadius: "16px",
            border: "1px solid #D3D6E0",
            background: "#F5F5F5",
            color: "#7F7F7F",
            fontSize: "12px",
            "&:hover": {
              background: "#D1DBFF",
              color: "#7F7F7F",
              border: "1px solid #7F7F7F",
              "& .MuiChip-deleteIcon": {
                color: "#7F7F7F !important",
              },
            },
          },
        },
        deleteIcon: {
          "&:not(.chips-manager, .status-selector)": {
            color: "#D3D6E0 !important",
          },
        },
        stack1: {
          background: additional1.main,
          border: `1.5px solid ${additional1[900]}`,
          borderRadius: "8px",
          color: "#fff",
          fontSize: "16px",
          fontWeight: 500,
          "&:hover": {
            background: additional1.main,
            border: `1.5px solid ${additional1[900]}`,
            borderRadius: "8px",
            color: "#fff",
          },
        },
        stack2: {
          background: additional2.main,
          border: `1.5px solid ${additional2[900]}`,
          borderRadius: "8px",
          color: "#fff",
          fontSize: "16px",
          fontWeight: 500,
          "&:hover": {
            background: additional2.main,
            border: `1.5px solid ${additional2[900]}`,
            borderRadius: "8px",
            color: "#fff",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          filter:
            "drop-shadow(-3.75px -3.75px 7.5px rgba(255, 255, 255, 0.3)) drop-shadow(3.75px 3.75px 11.25px rgba(178, 182, 196, 0.3))",
          borderRadius: "4px",
          background: "#FFFFFF",
          "&:hover": {
            background: "#D1DBFF",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "#9A9FAF",
          borderRadius: "4px",
          fontSize: "10px",
          fontWeight: 500,
          lineHeight: "140%",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          maxWidth: 1128,
          wordBreak: "break-word",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          background: "#F5F5F5 !important",
          color: "#7F7F7F",
          filter: "drop-shadow(0px 1.5px 1.5px rgba(0, 0, 0, 0.3))",
          "&:hover": {
            background: "#7F7F7F !important",
            color: "#FFFFFF",
          },
          "&:active": {
            background: "#000000 !important",
            color: "#FFFFFF",
          },
          "&:disabled": {
            background: "#D3D6E0 !important",
            color: "#9A9FAF",
          },
        },
        secondary: {
          background: "#5073E8 !important",
          color: "#FFFFFF !important",
          filter: "drop-shadow(0px 1.5px 1.5px rgba(0, 0, 0, 0.3))",
          "&:hover": {
            background: "#304BA8 !important",
            color: "#FFFFFF",
          },
          "&:active": {
            background: "#6B8AFF !important",
            color: "#FFFFFF",
          },
          "&:disabled": {
            background: "#D3D6E0 !important",
            color: "#9A9FAF",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${divider[500]}`,
        },
      },
    },
    MuiSwitch: {
      variants: [
        {
          // Normal switch
          props: { variant: undefined },
          style: {
            "& .MuiSwitch-switchBase": {
              color: primary[50],
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: divider[300],
              },
              "& .MuiSwitch-thumb": {
                boxShadow:
                  "0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
              },
              "&.Mui-checked": {
                color: "#fff",
                "& + .MuiSwitch-track": {
                  opacity: 1,
                  backgroundColor: "#818CF8",
                },
              },
              "&.Mui-disabled": {
                color: divider[300],
                "& + .MuiSwitch-track": {
                  opacity: 1,
                  backgroundColor: divider[700],
                },
              },
            },
          },
        },
        {
          props: { variant: "alwaysOn" },
          style: {
            "& .MuiSwitch-switchBase": {
              color: secondary[500],
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: secondary[200],
              },
              "& .MuiSwitch-thumb": {
                boxShadow:
                  "0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
              },
              "&.Mui-checked": {
                color: secondary[500],
                "& + .MuiSwitch-track": {
                  opacity: 1,
                  backgroundColor: secondary[200],
                },
              },
              "&.Mui-disabled": {
                color: divider[300],
                "& + .MuiSwitch-track": {
                  opacity: 1,
                  backgroundColor: divider[700],
                },
              },
            },
          },
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        action: {
          alignItems: "center",
          padding: "0 0 0 16px",
          "& .MuiSvgIcon-root": {
            color: text.white,
          },
        },
        filledPrimary: {
          backgroundColor: "#131E30",
          color: "#fff",
          ".MuiAlertTitle-root": {
            fontSize: "14px !important",
          },
          "& .MuiSvgIcon-root": {
            color: "#fff !important",
          },
        },
        filledSecondary: {
          backgroundColor: "#EEF2FF",
          color: "#818CF8",
          ".MuiAlertTitle-root": {
            fontSize: "14px !important",
          },
          "& .MuiSvgIcon-root": {
            color: "#818CF8 !important",
          },
        },
        standardInfo: {
          backgroundColor: "#EEF2FF",
          color: "#818CF8",
          ".MuiAlertTitle-root": {
            fontSize: "14px !important",
          },
          "& .MuiSvgIcon-root": {
            color: "#818CF8 !important",
          },
        },
        filledSuccess: {
          backgroundColor: "#DCFCE7",
          color: "#22C55E",
          ".MuiAlertTitle-root": {
            fontSize: "14px !important",
          },
          "& .MuiSvgIcon-root": {
            color: "#22C55E !important",
          },
        },
        filledError: {
          backgroundColor: "#FEF2F2",
          color: "#EF4444",
          ".MuiAlertTitle-root": {
            fontSize: "14px !important",
          },
          "& .MuiSvgIcon-root": {
            color: "#EF4444 !important",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow:
            "0px 3px 3px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12) !important",
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          "& .MuiPagination-ul": {
            li: {
              "& .Mui-selected": {
                background: "#EDF7FF !important",
                color: "#178CF2 !important",
                borderRadius: 0,
                "&:hover": {
                  backgroundColor: "#EDF7FF !important",
                  color: `#178CF2 !important`,
                },
              },
              width: 36,
              height: 36,
              "& button": {
                "&:hover": {
                  backgroundColor: "transparent !important",
                  color: `#178CF2 !important`,
                },
                ".MuiSvgIcon-root": {
                  "&:hover": {
                    fill: "#178CF2",
                  },
                },
              },
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        textSecondary: {
          color: "#374151 !important",
        },
        icon: {
          color: primary[600],
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "#F9F9FF",
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: "#FFC700",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#818CF8",
          "&.Mui-checked": {
            color: "#818CF8 !important",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          gap: 20,
        },
        indicator: {
          bottom: 7,
          height: 1,
          backgroundColor: "#818CF8",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 40,
          fontWeight: 400,
          fontSize: 14,
          padding: 8,
          borderBottom: "2px solid #e0e0e0",
          color: "#374151",
          "&.Mui-selected": {
            color: "#818CF8",
            fontWeight: 500,
          },
        },
      },
    },
    MuiFormControl: {
      variants: [
        {
          props: { variant: "outlined", chartVariant: true },
          style: {
            "& .MuiInputBase-root": {
              borderRadius: 1.5,
              "& .MuiOutlinedInput-notchedOutline": {
                border: `0.6px solid ${divider[300]} !important`,
              },
            },
            "& .MuiSelect-select": {
              fontSize: 9,
              padding: "3.5px 21px 2.5px 6.3px",
              borderRadius: 1.5,
            },
            "& .MuiSvgIcon-root": {
              color: `${primary[400]} !important`,
              width: 20,
            },
          },
          // Menu styles for the special select component inside charts.
          // Can be accessed through theme.components.MuiFormControl.variants[0].MenuStyle
          MenuStyle: {
            maxHeight: 120,
            width: 205,
            color: text.secondary,
            "& .MuiMenuItem-root": {
              minHeight: "20px !important",
              fontSize: 9,
              "&:hover": {
                background: "none",
                fontWeight: 500,
                color: text.secondaryVariant2,
              },
              "&.Mui-selected": {
                background: "none",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent",
                color: text.secondaryVariant2,
              },
            },
            scrollbarWidth: "thin",
            scrollbarColor: `${primary[400]} ${primary[100]}`,
            "&::-webkit-scrollbar": {
              width: 9,
            },
            "&::-webkit-scrollbar-thumb": {
              background: primary[400],
            },
            "&::-webkit-scrollbar-track": {
              background: primary[100],
            },
          },
          // Menu styles for the filter select component.
          // Can be accessed through theme.components.MuiFormControl.variants[0].FilterMenuStyle
          FilterMenuStyle: {
            backgroundColor: primary[600],
            color: "#fff",
            "& .MuiMenuItem-root": {
              "&:hover": {
                backgroundColor: "#D1DBFF !important",
                color: primary[800],
                "& .MuiSvgIcon-root": {
                  color: text.primary,
                },
              },
              "&.Mui-selected": {
                backgroundColor: "#D1DBFF !important",
                color: primary[800],
                "& .MuiSvgIcon-root": {
                  color: `${secondary[500]} !important`,
                },
              },
              "&.Mui-focusVisible": {
                backgroundColor: "#D1DBFF",
                color: primary[800],
                "& .MuiSvgIcon-root": {
                  color: text.primary,
                },
              },
              scrollbarWidth: "thin",
              scrollbarColor: `${primary[400]} ${primary[100]}`,
              "& ::-webkit-scrollbar": {
                width: 9,
              },
              "& ::-webkit-scrollbar-thumb": {
                background: primary[400],
              },
              "& ::-webkit-scrollbar-track": {
                background: primary[100],
              },
              "& .MuiSvgIcon-root": {
                color: primary[100],
              },
            },
          },
        },
      ],
    },
  },
};
