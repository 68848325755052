import {
  TCareerPageFormDefaultValue,
  TCompanyAddressUpdatePayload,
  TCompanySettingsFormDefaultValue,
  TCompanyUpdatePayload,
} from "../models/CompanySettings";
import { apiRequest } from "./config";

export const apiGetCompanyData = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiUpdateCompanyData = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload:
    | TCompanyUpdatePayload
    | TCompanySettingsFormDefaultValue
    | TCareerPageFormDefaultValue;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetRegistrationData = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiGetCountries = () =>
  apiRequest({ type: "post", url: "/countries" });

export const apiDisconnectCustomer = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });

export const apiUpdateCompanyAddress = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TCompanyAddressUpdatePayload;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetCompanyInfo = ({ url }: { url: string }) =>
  apiRequest({ type: "get", url });
