import React, { useEffect, useMemo, useState } from "react";
import { TFunction } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  IApplicationsState,
  TApplicationsFilters,
} from "../../../models/Applications";
import {
  fetchAllApplications,
  resetApplicationsFilters,
  setApplicationFilters,
  setView,
} from "../../../store/reducers/Applications";
import {
  getAllApplications,
  getApplicationListState,
  getApplicationsFilters,
} from "../../../store/selectors/Applications";
import ApplicationsFiltersComponent from "./component";
import {
  getCurrentUserData,
  getStatuses,
} from "../../../store/selectors/CurrentUser";
import { getAllActiveJobs } from "../../../store/selectors/JobList";
import { TListOption } from "../../../models/common";
import { getCustomersListState } from "../../../store/selectors/Customers";
import { fetchCustomers } from "../../../store/reducers/Customers";
import { setIsSelectWorkflowDialogOpen } from "../../../store/reducers/dialogs";
import { getSingleJobState } from "../../../store/selectors/SingleJob";
import { getWorkflowState } from "../../../store/selectors/Workflow";
import {
  setCurrentWorkflowStatuses,
  setSelectedJob,
  setSelectedWorkflow,
} from "../../../store/reducers/Workflow";
import { getNavigationState } from "../../../store/selectors/Navigation";

const ApplicationsFilters = ({
  t,
  view,
  jobId,
  onOpenApplyJobDialog,
}: {
  readonly t: TFunction;
  readonly view: IApplicationsState["view"];
  readonly jobId: string | null;
  readonly onOpenApplyJobDialog: () => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allApplications = useSelector(getAllApplications);
  const statuses = useSelector(getStatuses);
  const allJobs = useSelector(getAllActiveJobs);

  const { appliedTo, status, quickSearch, location, company } = useSelector(
    getApplicationsFilters
  );
  const { jobDetails } = useSelector(getSingleJobState);
  const { workflows } = useSelector(getWorkflowState);
  const { isAgency } = useSelector(getCurrentUserData);
  const { list } = useSelector(getCustomersListState);
  const { cardsApplications, list: applicationsList } = useSelector(
    getApplicationListState
  );

  const { open } = useSelector(getNavigationState);

  const [columnWidth, setColumnWidth] = useState<number>(290);

  const columnSpacing = 16;
  const spacingNumber = 3;
  const margin = window.innerWidth > 1536 ? 90 : 0;
  const differenceBeetwenOpenAndClosedSidebar = 150;
  const openSidebarDndWidth =
    columnWidth * 4 + columnSpacing * spacingNumber + margin;
  const closedSidebarDndWidth =
    openSidebarDndWidth + differenceBeetwenOpenAndClosedSidebar;
  const dndContainerWidth = open ? openSidebarDndWidth : closedSidebarDndWidth;

  const formattedAllActiveJobs = allJobs?.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const singleJobLocations = allApplications?.filter(
    (item) => item.job_id == jobId
  );

  const formattedSingleJobLocations = singleJobLocations?.map((item) => {
    if (item.location) {
      return {
        label: item.location,
        value: item.location,
      };
    }
  });

  const formattedAllLocations = allApplications?.map((item) => {
    if (item.location) {
      return {
        label: item.location,
        value: item.location,
      };
    }
  });

  const formattedLocations = jobId
    ? formattedSingleJobLocations
    : formattedAllLocations;

  const formattedAllLocationsWithoutDuplicate = formattedLocations?.filter(
    (v, i, a) =>
      v !== undefined &&
      a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i
  );

  const uniqueStatuses = useMemo(() => {
    if (workflows && workflows.length) {
      if (jobDetails) {
        const findWorkflow = workflows.find(
          (workflow) => workflow.id == jobDetails.workflow_id
        );
        if (findWorkflow) {
          const singleJobWorkflowStatuses = findWorkflow.statuses.map(
            (status) => ({
              label: status.label,
              value: status.value,
            })
          );
          return singleJobWorkflowStatuses;
        }
        const formattedStatuses = statuses.map((status) => ({
          label: status.label,
          value: status.value,
        }));
        return formattedStatuses;
      }
    }

    const allStatuses = workflows.flatMap((workflow) =>
      workflow.statuses.map((status) => ({
        label: status.label,
        value: status.value,
      }))
    );

    const allUniqueStatuses = allStatuses.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );
    return allUniqueStatuses || statuses || [];
  }, [workflows, jobDetails, statuses]);

  const formattedStatus = {
    value: status.value,
    options: uniqueStatuses,
  };

  const formattedCustomers = list?.map((item) => ({
    label: item.companyName,
    value: item.id,
  }));

  const handleOnChangeFilter = ({
    value,
    filterType,
  }: {
    readonly value: string;
    readonly filterType: keyof TApplicationsFilters;
  }) => {
    dispatch(setApplicationFilters({ filterType, value }));
  };

  const handleOnChangeView = (view: IApplicationsState["view"]) => {
    dispatch(setView(view));
    localStorage.setItem("view", view);
  };

  const handleOpenSetWorkflowDialog = () => {
    if (workflows.length > 0) {
      if (jobDetails) {
        const findWorkflow = workflows.find(
          (workflow) => workflow.id === jobDetails.workflow_id
        );

        const id = findWorkflow ? findWorkflow?.id : workflows[0]?.id;

        if (findWorkflow) {
          dispatch(setCurrentWorkflowStatuses(findWorkflow.statuses));
        }
        dispatch(
          setSelectedJob({
            id: jobId as string,
            title: jobDetails.title,
            urlKey: jobDetails.url_key,
            workflowId: jobDetails.workflow_id,
          })
        );
        dispatch(
          setSelectedWorkflow({
            label: findWorkflow?.title || workflows[0].title,
            value: id as unknown as string,
            jobId: jobDetails.id,
          })
        );
      }
      dispatch(setIsSelectWorkflowDialogOpen(true));
    } else {
      navigate("/settings/workflows");
    }
  };

  useEffect(() => {
    if (!allApplications) {
      dispatch(fetchAllApplications());
    }
    if (isAgency) {
      dispatch(fetchCustomers());
    }
    return () => {
      dispatch(resetApplicationsFilters());
    };
  }, []);

  useEffect(() => {
    const column = document.querySelector(".dnd-column");
    if (column?.clientWidth) {
      setColumnWidth(column.clientWidth);
    }
  }, [view, cardsApplications]);

  return (
    <ApplicationsFiltersComponent
      jobTypeFilter={appliedTo}
      stateFilter={formattedStatus}
      t={t}
      jobId={jobId}
      quickSearch={quickSearch}
      location={location}
      allJobs={formattedAllActiveJobs}
      isAgency={isAgency}
      companyFilter={company}
      filtersWidth={dndContainerWidth}
      allLocations={formattedAllLocationsWithoutDuplicate as TListOption[]}
      customers={formattedCustomers as TListOption[]}
      view={view}
      cardsApplications={cardsApplications}
      list={applicationsList}
      onOpenSetWorkflowDialog={handleOpenSetWorkflowDialog}
      onChangeFilters={handleOnChangeFilter}
      onOpenApplyJobDialog={onOpenApplyJobDialog}
      onChangeView={handleOnChangeView}
    />
  );
};

export default ApplicationsFilters;
