import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { IConnectDialogComponent } from "../../../models/SingleJob";
import { INTEGRATION_TYPES } from "../../../models/Integrations";

const ConnectDialog = ({
  t,
  companyProfileUrl,
  jobBoard,
  isLoading,
  isOpen,
  onClose,
  onSave,
  onChangeUrl,
}: IConnectDialogComponent) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    maxWidth={"xs"}
    PaperProps={{ sx: { background: "white" } }}
    data-testid={`connect-dialog-${jobBoard?.title}`}
  >
    <Stack p={2} pb={1} direction="row" spacing={1.5} alignItems="center">
      <img src={jobBoard?.logo} alt="logo" width={24} height={24} />
      <Typography variant="subtitle1" color="#000">
        {t("addCompanyProfileDialog.title", { title: jobBoard?.title })}
      </Typography>
    </Stack>
    <DialogContent sx={{ 2: 3, py: 0, overflow: "hidden" }}>
      <Box>
        <Typography variant="subtitle2" color="textSecondary">
          {t("addCompanyProfileDialog.description", {
            title: jobBoard?.title,
          })}
        </Typography>
      </Box>
      <Box mt={3}>
        <TextField
          data-testid="connect-url-field"
          value={companyProfileUrl}
          onChange={(e) => onChangeUrl(e.target.value)}
          placeholder={
            jobBoard?.title.toLowerCase() ===
            INTEGRATION_TYPES.LINKEDIN.toLowerCase()
              ? "https://linkedin.com/company/mycompany/"
              : "https://xing.de/company/mycompany/"
          }
          fullWidth
        />
      </Box>
    </DialogContent>
    <DialogActions sx={{ p: 2 }}>
      <Button
        data-testid="connect-url-close-button"
        variant={"outlined"}
        onClick={onClose}
        disabled={isLoading}
      >
        {t("button.secondCancel")}
      </Button>
      <Button
        data-testid="connect-url-save-button"
        endIcon={
          isLoading ? (
            <CircularProgress
              size={16}
              style={{
                color: "#9A9FAF",
              }}
            />
          ) : null
        }
        variant={"contained"}
        onClick={onSave}
        disabled={isLoading || !companyProfileUrl}
      >
        {t("button.save")}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConnectDialog;
