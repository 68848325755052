import React from "react";
import { Button, Divider, Stack } from "@mui/material";
import { TFunction } from "i18next";
import supportImg from "../../../assets/support.png";
import {
  StyledDashboardItemContainer,
  StyledDashboardItemTitle,
  StyledSupportWrapper,
} from "../styles";

const SupportBox = ({ t }: { readonly t: TFunction }) => (
  <StyledDashboardItemContainer>
    <Stack spacing={3} className={"full-height"}>
      <StyledDashboardItemTitle>
        {t("dashboard.support.title")}
      </StyledDashboardItemTitle>
      <Divider />
      <StyledSupportWrapper>
        <img alt={"support"} src={supportImg} />
        <Button
          variant={"contained"}
          href="https://karriera.atlassian.net/servicedesk/customer/portal/1"
          target="_blank"
        >
          {t("dashboard.support.ctaBtn")}
        </Button>
      </StyledSupportWrapper>
    </Stack>
  </StyledDashboardItemContainer>
);

export default SupportBox;
