import { TFunction } from "i18next";
import { TJobOwner } from "./SingleJob";
import { MouseEvent } from "react";
import { APPLICATION_STATUSES } from "./Applications";
import { TMessagesInfo } from "./Messages";
import { TSelectedJobCandidateApplication } from "./ApplicationPage";
import { TListOption } from "./common";

export interface ITopHeader {
  readonly pageType: PAGE_TYPES;
  readonly routeTitle: string;
  readonly desktop: boolean;
}

export interface ISingleJobHeaderComponent {
  readonly t: TFunction;
  readonly title: string;
  readonly subtitle: string;
  readonly anchorEl: null | HTMLElement;
  readonly jobOwners: TJobOwner[] | null;
  readonly isArchivedJob: boolean;
  readonly isLoading: boolean;
  readonly isJobOwner: boolean;
  readonly urlKey?: string;
  readonly desktop: boolean;
  readonly isAgency: number;
  readonly onOpenSetWorkflowDialog: () => void;
  readonly onOpenMenu: (event: MouseEvent<HTMLElement>) => void;
  readonly onCloseMenu: () => void;
  readonly onEdit: () => void;
  readonly onArchive: () => void;
  readonly onPreview: () => void;
  readonly onInvite: () => void;
  readonly onExport: () => void;
  readonly onDuplicate: () => void;
  readonly onNavigateBack: () => void;
}

export interface ISingleApplicationHeaderComponent {
  readonly t: TFunction;
  readonly title: string;
  readonly subtitle: string;
  readonly status?: TListOption;
  readonly targetJob: { title: string; id: string };
  readonly isLoading: boolean;
  readonly currentApplication?: TSelectedJobCandidateApplication;
  readonly desktop: boolean;
  readonly onUpdateStatus: (newStatus: APPLICATION_STATUSES) => void;
  readonly onNavigateBack: () => void;
  readonly onOpenAddCandidateToJobDialog: () => void;
}

export interface ISingleApplicationCandidateHeaderComponent {
  readonly t: TFunction;
  readonly title: string;
  readonly subtitle: string;
  readonly status?: string;
  readonly targetJob: string;
  readonly isLoading: boolean;
  readonly isOpen: boolean;
  readonly desktop: boolean;
  readonly company: string;
  readonly allStatuses: string[];
  readonly onOpenCloseDialog: (boolean: boolean) => void;
  readonly onDeleteApplication: () => void;
  readonly onNavigateBack: () => void;
}

export interface IMobileChatTopHeaderComponent {
  readonly t: TFunction;
  readonly messagesInfo?: TMessagesInfo | null;
  readonly status?: APPLICATION_STATUSES;
  readonly isLoading: boolean;
  readonly isCandidate: boolean;
  readonly desktop: boolean;
  readonly handleOpenCandidate: () => void;
  readonly onNavigateBack: () => void;
}

export interface ITopHeaderUserMenu {
  readonly t: TFunction;
  readonly anchorEl: null | HTMLElement;
  readonly firstName: string;
  readonly lastName: string;
  readonly avatarSrc: string;
  readonly isCandidate?: boolean;
  readonly isAgencyToken?: boolean;
  readonly desktop: boolean;
  readonly onOpenProfile: () => void;
  readonly onOpen: (event: MouseEvent<HTMLElement>) => void;
  readonly onClose: () => void;
  readonly onLogout: () => void;
}

export enum PAGE_TYPES {
  SINGLE_JOB = "singleJob",
  SINGLE_APPLICATION = "singleApplication",
  SINGLE_APPLICATION_CANDIDATE = "singleApplicationCandidate",
  MESSAGES_MOBILE = "messagesMobile",
  CUSTOMER_DETAILS = "customerDetails",
  TALENT_POOL = "talentPool",
  PRODUCT = "product",
  PACKAGE = "package",
  ANALYTICS = "analytics",
  AUTOMATION = "createAutomation",
  SETTINGS = "settings",
}

export enum SETTINGS_PAGE_TYPES {
  GENERAL = "general",
  CAREER = "career",
  JOB_WIDGET = "jobWidget",
  WORKFLOWS = "workflows",
  AUTOMATION = "automation",
  TEAM = "team",
  INTEGRATIONS = "integrations",
}
