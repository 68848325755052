import React, { useState } from "react";
import ChipsManagerComponent from "./component";
import { IChipsManager } from "../../models/common";

const ChipsManager = ({
  chipList,
  placeholder,
  validation,
  showListAlways = false,
  listHeight,
  onChange,
}: IChipsManager) => {
  const [currentChip, setCurrentChip] = useState<string>("");
  const [currentChipList, setCurrentChipList] = useState<string[]>(
    chipList || []
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleOnValidateChip = () => {
    if (validation && validation.validationFunc) {
      if (validation.validationFunc(currentChip)) handleOnAdd();
      else setErrorMessage(validation.errorMessage);
    } else handleOnAdd();
  };

  const handleOnAdd = () => {
    const updatedList = [...currentChipList, currentChip];
    setCurrentChipList(updatedList);
    setCurrentChip("");
    onChange(updatedList);
  };

  const handleOnChange = (value: string) => {
    setCurrentChip(value);
    setErrorMessage("");
  };
  const handleOnDelete = (chipIndex: number) => {
    const filteredList = currentChipList.filter(
      (chip: string, index: number) => index !== chipIndex
    );
    setCurrentChipList(filteredList);
    onChange(filteredList);
  };

  return (
    <ChipsManagerComponent
      chip={currentChip}
      chipList={currentChipList}
      errorMessage={errorMessage}
      placeholder={placeholder}
      showListAlways={showListAlways}
      listHeight={listHeight}
      onAdd={handleOnValidateChip}
      onChange={handleOnChange}
      onDelete={handleOnDelete}
    />
  );
};

export default ChipsManager;
