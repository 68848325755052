import React, { useEffect, useMemo, useState } from "react";
import DOMPurify from "dompurify";
import he from "he";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CompanyInfoComponent from "./component";
import {
  fetchCompanyInfo,
  setCompanyFilter,
  setPageNum,
} from "../../store/reducers/CompanyInfo";
import {
  getCompanyInfoPagination,
  getCompanyInfoState,
  getCompanySettingsCompanyState,
} from "../../store/selectors/Company";

const CompanyInfo = ({ desktop }: { readonly desktop: boolean }) => {
  const { t } = useTranslation();
  const { companyname } = useParams();
  const dispatch = useDispatch();

  const { isLoading, company, filterOptions, filterValue, error } =
    useSelector(getCompanyInfoState);

  const { company: companySettings } = useSelector(
    getCompanySettingsCompanyState
  );
  const industry = useMemo(
    () => companySettings?.industry || "",
    [companySettings]
  );
  const { pageNum, pageSize } = useSelector(getCompanyInfoPagination);

  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const fullText = DOMPurify.sanitize(he.decode(company?.about_us || ""));
  const hasCompanyMedia =
    company.video_link ||
    company.picture_one ||
    company.picture_two ||
    company.picture_three;
  const maxDescriptionLength = desktop ? (hasCompanyMedia ? 460 : 560) : 430;
  const isShowMoreExist = fullText.length > maxDescriptionLength;
  const shortText = isShowMoreExist
    ? fullText.substring(0, maxDescriptionLength) + "..."
    : fullText;

  const onFilterChange = ({
    key,
    value,
  }: {
    key: "location" | "workField";
    value: string;
  }) => {
    dispatch(setCompanyFilter({ key, value }));
  };

  const handlePaginate = (number: number) => {
    dispatch(setPageNum(number));
  };

  const handleOnShowMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  useEffect(() => {
    dispatch(
      fetchCompanyInfo({
        companyKey: companyname as string,
        locationId: filterValue.location,
        workFieldId: filterValue.workField,
        pageSize,
        pageNum,
      })
    );
  }, [filterValue, pageNum]);

  return (
    <CompanyInfoComponent
      t={t}
      desktop={desktop}
      isLoading={isLoading}
      company={company}
      filterOptions={filterOptions}
      filterValue={filterValue}
      page={pageNum}
      error={error}
      industry={industry}
      isShowMore={isShowMore}
      isShowMoreExist={isShowMoreExist}
      shortText={shortText}
      fullText={fullText}
      onShowMoreLess={handleOnShowMoreLess}
      paginate={handlePaginate}
      onFilterChange={onFilterChange}
    />
  );
};

export default CompanyInfo;
