import React from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Avatar, Divider } from "@mui/material";
import PhotoIcon from "@mui/icons-material/Photo";
import {
  StyledProfilePictureContainer,
  StyledProfilePictureOverlay,
} from "./styles";
import { TProfilePicture } from "../../models/common";

const ProfilePicture = ({
  imgSrc,
  type = "rounded",
  onDelete,
  onOpenUploadDialog,
}: TProfilePicture) => (
  <StyledProfilePictureContainer
    width={type !== "rounded" ? 180 : 120}
    height={type !== "rounded" ? 92 : 120}
  >
    <Avatar
      src={imgSrc}
      sx={{
        width: type !== "rounded" ? 180 : 120,
        height: type !== "rounded" ? 92 : 120,
        borderRadius: type !== "rounded" ? "6px" : "50%",
      }}
    >
      {imgSrc && type === "rounded" ? undefined : (
        <PhotoIcon
          sx={{
            width: 64,
            height: 64,
          }}
        />
      )}
    </Avatar>
    <StyledProfilePictureOverlay
      width={type !== "rounded" ? 180 : 120}
      height={type !== "rounded" ? 92 : 120}
      borderRadius={type !== "rounded" ? "6px" : "50%"}
    >
      <TrashIcon
        data-testid="delete-picture-icon"
        className={"overlay-icon"}
        onClick={onDelete}
      />
      <Divider orientation="vertical" className={"overlay-divider"} />
      <PencilSquareIcon
        data-testid="update-picture-icon"
        className={"overlay-icon"}
        onClick={onOpenUploadDialog}
      />
    </StyledProfilePictureOverlay>
  </StyledProfilePictureContainer>
);

export default ProfilePicture;
