import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import IntegrationsComponent from "./component";
import hr4youLogo from "../../assets/HR4YOU.png";
import concludisLogo from "../../assets/concludis.jpg";
import onlyfyLogo from "../../assets/onlyfy.jpeg";
import linkedinLogo from "../../assets/linkedinIcon.png";
import xingLogo from "../../assets/xingIcon.png";
import stepstoneLogo from "../../assets/stepstoneIcon.jpg";
import indeedLogo from "../../assets/indeed.png";
import {
  IConnectIntegrationDialogDefaultFormFields,
  IintegrationInfo,
  INTEGRATION_GROUP,
  INTEGRATION_TYPES,
} from "../../models/Integrations";
import {
  connectIntegrationDialogDefaultValues,
  connectIntegrationDialogFormFields,
} from "./config";
import { getCompanySettingsCompanyState } from "../../store/selectors/Company";
import {
  fetchCompanyData,
  updateCompanyData,
} from "../../store/reducers/CompanySettings";

const IntegrationsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedIntegration, setSelectedIntegration] =
    useState<null | IintegrationInfo>(null);
  const [isConnectIntegrationDialogOpen, setIsConnectIntegrationDialogOpen] =
    useState<boolean>(false);
  const [
    isConnectExternalProfileDialogOpen,
    setIsConnectExternalProfileDialogOpen,
  ] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [companyProfileUrl, setCompanyProfileUrl] = useState<string>("");

  const { company } = useSelector(getCompanySettingsCompanyState);

  const {
    formState: { errors },
    register,
    reset,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(
      connectIntegrationDialogFormFields({
        t,
        urlRequired: selectedIntegration?.title === INTEGRATION_TYPES.HR4YOU,
        usernameRequired:
          selectedIntegration?.title !== INTEGRATION_TYPES.ONLYFY,
        passwordRequired:
          selectedIntegration?.title !== INTEGRATION_TYPES.ONLYFY,
        companyIdRequired:
          selectedIntegration?.title === INTEGRATION_TYPES.CONCLUDIS,
        apiKeyRequired: selectedIntegration?.title === INTEGRATION_TYPES.ONLYFY,
      })
    ),
    defaultValues: connectIntegrationDialogDefaultValues,
  });

  const integrations: IintegrationInfo[] = [
    {
      title: INTEGRATION_TYPES.HR4YOU,
      description: t("integrations.HR4YOUDescription"),
      logo: hr4youLogo,
      isConnected: false,
      group: INTEGRATION_GROUP.INTEGRATION,
    },
    {
      title: INTEGRATION_TYPES.CONCLUDIS,
      description: t("integrations.ConcludisDescription"),
      logo: concludisLogo,
      isConnected: false,
      group: INTEGRATION_GROUP.INTEGRATION,
    },
    {
      title: INTEGRATION_TYPES.ONLYFY,
      description: t("integrations.OnlyfyDescription"),
      logo: onlyfyLogo,
      isConnected: false,
      group: INTEGRATION_GROUP.INTEGRATION,
    },
  ];

  const externalProfiles: IintegrationInfo[] = [
    {
      title: INTEGRATION_TYPES.LINKEDIN,
      description: t("integrations.linkedinDescription"),
      logo: linkedinLogo,
      isConnected: Boolean(company?.linkedin?.length),
      group: INTEGRATION_GROUP.EXTERNAL_PROFILES,
    },
    {
      title: INTEGRATION_TYPES.XING,
      description: t("integrations.xingDescription"),
      logo: xingLogo,
      isConnected: Boolean(company?.xing?.length),
      group: INTEGRATION_GROUP.EXTERNAL_PROFILES,
    },
  ];

  const quickApplyInterfaces: IintegrationInfo[] = [
    {
      title: INTEGRATION_TYPES.STEPSTONE,
      description: t("integrations.stepstoneDescription"),
      logo: stepstoneLogo,
      isConnected: true,
      group: INTEGRATION_GROUP.QUICK_APPLY_INTERFACES,
    },
    {
      title: INTEGRATION_TYPES.INDEED,
      description: t("integrations.indeedDescription"),
      logo: indeedLogo,
      isConnected: true,
      group: INTEGRATION_GROUP.QUICK_APPLY_INTERFACES,
    },
  ];

  const handleOnOpenConnectDialog = (integration: IintegrationInfo) => {
    if (integration.group === INTEGRATION_GROUP.INTEGRATION) {
      setIsConnectIntegrationDialogOpen(true);
      setSelectedIntegration(integration);
    } else {
      setSelectedIntegration(integration);
      setIsConnectExternalProfileDialogOpen(true);
      if (
        integration.title.toLowerCase() ===
          INTEGRATION_TYPES.LINKEDIN.toLowerCase() &&
        company?.linkedin?.length
      ) {
        setCompanyProfileUrl(company.linkedin);
      }
      if (
        integration.title.toLowerCase() ===
          INTEGRATION_TYPES.XING.toLowerCase() &&
        company?.xing?.length
      ) {
        setCompanyProfileUrl(company.xing);
      }
    }
  };

  const handleCloseConnectDialog = () => {
    setIsConnectIntegrationDialogOpen(false);
    setIsConnectExternalProfileDialogOpen(false);
    setSelectedIntegration(null);
    setShowPassword(false);
    setCompanyProfileUrl("");
    reset();
  };

  const handleOnConnect = (
    data: IConnectIntegrationDialogDefaultFormFields
  ) => {
    console.log(data);
    handleCloseConnectDialog();
  };

  const handleChangeShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeExternalProfileUrl = (value: string) => {
    setCompanyProfileUrl(value);
  };

  const handleSaveExternalProfile = () => {
    const data =
      selectedIntegration?.title === INTEGRATION_TYPES.LINKEDIN
        ? { linkedin_url: companyProfileUrl }
        : { xing_url: companyProfileUrl };
    dispatch(
      updateCompanyData({
        type: "form",
        data,
        callback: () => {
          dispatch(fetchCompanyData());
          handleCloseConnectDialog();
        },
      })
    );
  };

  return (
    <IntegrationsComponent
      t={t}
      isConnectIntegrationDialogOpen={isConnectIntegrationDialogOpen}
      isConnectExternalProfileDialogOpen={isConnectExternalProfileDialogOpen}
      selectedIntegration={selectedIntegration}
      integrations={integrations}
      externalProfiles={externalProfiles}
      quickApplyInterfaces={quickApplyInterfaces}
      showPassword={showPassword}
      companyProfileUrl={companyProfileUrl}
      errors={errors}
      register={register}
      onChangeExternalProfileUrl={handleChangeExternalProfileUrl}
      onChangeShowPassword={handleChangeShowPassword}
      onClose={handleCloseConnectDialog}
      onOpenConnectDialog={handleOnOpenConnectDialog}
      onConnect={handleOnConnect}
      handleSubmit={handleSubmit}
      onSaveExternalProfile={handleSaveExternalProfile}
    />
  );
};

export default IntegrationsPage;
