import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  activateDeactivateAutomation,
  activateDeactivateAutomationFailed,
  activateDeactivateAutomationSuccess,
  addUpdateAutomation,
  addUpdateAutomationFailed,
  addUpdateAutomationSuccess,
  deleteAutomation,
  deleteAutomationFailed,
  deleteAutomationSuccess,
  fetchActions,
  fetchActionsFailed,
  fetchActionsSuccess,
  fetchAutomations,
  fetchAutomationsFailed,
  fetchAutomationsSuccess,
  fetchSelectedActionData,
  fetchSelectedActionDataFailed,
  fetchSelectedActionDataSuccess,
  fetchTriggers,
  fetchTriggersFailed,
  fetchTriggersSuccess,
} from "../reducers/Automation";
import {
  apiAddUpdateAutomation,
  apiAddUpdateAutomationStatus,
  apiDeleteAutomation,
  apiFetchActions,
  apiFetchAutomations,
  apiFetchEntity,
  apiFetchTriggers,
} from "../../api/Autonation";
import { PayloadAction } from "@reduxjs/toolkit";
import { getAutomationState } from "../selectors/Automation";
import { IAutomationAction, ICurrentAutomation } from "../../models/Automation";
import { PayloadActionWithCallback } from "../../models/common";

const baseUrl = "/company";
const baseEventManagerUrl = "/company/event-manager";

function* handleOnFetchAutomations() {
  const { filter } = yield select(getAutomationState);
  const apiPayload = {
    name: filter,
    pagesize: -1,
  };
  try {
    const { data } = yield call(apiFetchAutomations, {
      url: baseEventManagerUrl,
      apiPayload,
    });
    yield put(fetchAutomationsSuccess(data.eventManager));
  } catch (e) {
    yield put(fetchAutomationsFailed(e)); // TODO handle error
  }
}

function* handleOnAddUpdateAutomation({
  payload,
}: PayloadActionWithCallback<unknown>) {
  const { callback } = payload;
  const { currentAutomation } = yield select(getAutomationState);
  const actions = currentAutomation.actions.map(
    (action: IAutomationAction) => action.const
  );
  const automationParams = currentAutomation.actions.map(
    (action: IAutomationAction) => action.params
  );
  const url = currentAutomation.id
    ? `${baseEventManagerUrl}/update/${currentAutomation.id}`
    : `${baseEventManagerUrl}/add`;

  const apiPayload = {
    name: currentAutomation.name,
    event_const: currentAutomation.trigger.const,
    action_const: actions,
    params: automationParams,
    enabled: currentAutomation.enabled ? 1 : 0,
    conditions: currentAutomation.conditions,
    conditions_operators: currentAutomation.conditions_operators
      ? JSON.stringify(currentAutomation.conditions_operators)
      : JSON.stringify([[]]),
    group_conditions_operators:
      currentAutomation.group_conditions_operators ?? ["AND"],
  };

  try {
    yield call(apiAddUpdateAutomation, {
      url,
      apiPayload,
    });
    yield put(addUpdateAutomationSuccess());
    yield call(callback);
  } catch (e) {
    yield put(addUpdateAutomationFailed(e)); // TODO handle error
  }
}

function* handleOnFetchTriggers() {
  const url = `${baseUrl}/events`;

  try {
    const { data } = yield call(apiFetchTriggers, { url });
    yield put(fetchTriggersSuccess(data.events));
  } catch (e) {
    yield put(fetchTriggersFailed(e)); // TODO handle error
  }
}

function* handleOnActivateDeactivateAutomation({
  payload,
}: PayloadActionWithCallback<{ data: ICurrentAutomation }>) {
  const { data, callback } = payload;

  const url = data.enabled
    ? `${baseEventManagerUrl}/deactivate/${data.id}`
    : `${baseEventManagerUrl}/activate/${data.id}`;

  try {
    yield call(apiAddUpdateAutomationStatus, {
      url,
    });
    yield put(activateDeactivateAutomationSuccess);
    yield call(callback);
  } catch (e) {
    yield put(activateDeactivateAutomationFailed(e)); // TODO handle error
  }
}

function* handleOnDeleteAutomation({
  payload,
}: PayloadActionWithCallback<{ id: number }>) {
  const { id, callback } = payload;
  const url = `${baseEventManagerUrl}/delete/${id}`;

  try {
    yield call(apiDeleteAutomation, {
      url,
    });
    yield put(deleteAutomationSuccess);
    yield call(callback);
  } catch (e) {
    yield put(deleteAutomationFailed(e)); // TODO handle error
  }
}

function* handleOnFetchActions() {
  const url = `${baseUrl}/actions`;
  try {
    const { data } = yield call(apiFetchActions, { url });
    yield put(fetchActionsSuccess(data.actions));
  } catch (e) {
    yield put(fetchActionsFailed(e)); // TODO handle error
  }
}

function* handleOnFetchEntity({ payload }: PayloadAction<string>) {
  const url = `${baseUrl}/event-entities/list`;
  const { selectedActionData } = yield select(getAutomationState);
  try {
    const apiPayload = {
      entity: payload,
    };
    const { data } = yield call(apiFetchEntity, { url, apiPayload });
    const formattedData =
      payload === "jobs"
        ? {
            ...selectedActionData,
            jobs: data.collection,
          }
        : {
            ...selectedActionData,
            members: data.collection,
          };

    yield put(fetchSelectedActionDataSuccess(formattedData));
  } catch (e) {
    yield put(fetchSelectedActionDataFailed(e)); // TODO handle error
  }
}

function* AutomationSaga() {
  yield takeLatest(fetchAutomations, handleOnFetchAutomations);
  yield takeLatest(fetchTriggers, handleOnFetchTriggers);
  yield takeLatest(fetchActions, handleOnFetchActions);
  yield takeLatest(fetchSelectedActionData, handleOnFetchEntity);
  yield takeLatest(addUpdateAutomation, handleOnAddUpdateAutomation);
  yield takeLatest(
    activateDeactivateAutomation,
    handleOnActivateDeactivateAutomation
  );
  yield takeLatest(deleteAutomation, handleOnDeleteAutomation);
}

export default AutomationSaga;
