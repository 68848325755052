import React from "react";
import { Radio, RadioGroup, Stack, Tooltip, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { TFunction } from "i18next";
import { StyledAdditionalQuestionsFieldContainer } from "../styles";
import { IAdditionalQuestionSimpleSection } from "../../../models/JobPreview";

const WorkingPermitEU = ({
  t,
  control,
  desktop,
}: IAdditionalQuestionSimpleSection & { readonly t: TFunction }) => (
  <StyledAdditionalQuestionsFieldContainer desktop={desktop}>
    <Stack className={"title"}>
      <Typography variant="subtitle2">
        {t("additional_questions.eu_permit")}
      </Typography>
    </Stack>
    <Controller
      name="workingPermitEU"
      control={control}
      render={({ field: { onChange, value } }) => (
        <RadioGroup
          row
          value={value}
          onChange={(_, newValue) => onChange(newValue)}
        >
          <Tooltip title={t("yes")}>
            <Radio
              value="1"
              sx={{
                color: "#22C55E !important",
                "&.Mui-checked": { color: "#22C55E !important" },
                "&:hover": {
                  backgroundColor: "#9f9d9d29 !important",
                },
              }}
            />
          </Tooltip>
          <Tooltip title={t("no")}>
            <Radio
              value="2"
              sx={{
                color: "#EF4444 !important",
                "&.Mui-checked": { color: "#EF4444 !important" },
                "&:hover": {
                  backgroundColor: "#9f9d9d29 !important",
                },
              }}
            />
          </Tooltip>
        </RadioGroup>
      )}
    />
  </StyledAdditionalQuestionsFieldContainer>
);

export default WorkingPermitEU;
