import dayjs from "dayjs";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  applyToJob,
  applyToJobFailed,
  applyToJobSuccess,
  checkCandidateExist,
  checkCandidateExistFailed,
  checkCandidateExistSuccess,
  fetchCities,
  fetchCitiesFailed,
  fetchCitiesSuccess,
  fetchJob,
  fetchJobFailed,
  fetchJobSuccess,
  fetchPreviewTemplate,
  fetchPreviewTemplateFailed,
  fetchPreviewTemplateSuccess,
  sendMagicLink,
  sendMagicLinkSuccess,
  submitAdditionalQuestions,
  submitAdditionalQuestionsFailed,
  submitAdditionalQuestionsSuccess,
} from "../reducers/JobPreview";
import {
  apiApplyToJob,
  apiCheckCandidateExist,
  apiFetchJob,
  apiFetchTemplatePreview,
  apiGetCities,
  apiSendMagicLink,
  apiSubmitAdditionalQuestions,
  apiUpdateCandidateDocuments,
  apiUpdateCandidatePhoto,
} from "../../api/JobPreview";
import { PayloadAction } from "@reduxjs/toolkit";
import { PayloadActionWithCallback } from "../../models/common";
import {
  TAdditionalQuestionsFormFields,
  TJobActionRegisterFormFields,
} from "../../models/JobPreview";
import { getCompanySettingsCompanyState } from "../selectors/Company";

function* handleOnFetchJob({ payload }: PayloadAction<{ jobUrlKey: string }>) {
  const { jobUrlKey } = payload;
  try {
    const { data } = yield call(apiFetchJob, { jobUrlKey });
    const formattedData = {
      ...data,
      driversLicenses: data.drivers_licenses,
      languageLevels: data.language_levels,
    };
    const {
      job,
      job_locations: jobLocations,
      jobs,
      company_color,
      jobs_total,
    } = data;
    yield put(
      fetchJobSuccess({
        job,
        jobLocations,
        jobs,
        data: formattedData,
        companyColor: company_color || "#178CF2",
        totalJobs: jobs_total,
      })
    );
  } catch (e) {
    yield put(fetchJobFailed(e)); // TODO handle error
  }
}

function* handleOnApplyToJob({
  payload,
}: PayloadActionWithCallback<{
  jobUrlKey: string;
  body: TJobActionRegisterFormFields & { files?: FileList | null };
}>) {
  const { callback, jobUrlKey, body } = payload;

  const languages_and_levels = body.languages?.reduce(
    (result: any, item: any) => {
      result[item.language] = item.level;
      return result;
    },
    {}
  );

  const skills = body.skills?.reduce((result: any, item: any) => {
    result[item.value] = item.label;
    return result;
  }, {});

  const drivers_license = body.driverLicense?.reduce(
    (result: any, item: any) => {
      result[item.value] = item.label;
      return result;
    },
    {}
  );

  const apiPayload = {
    ...body,
    current_job: body.professionalStatus,
    highest_degree: body.education,
    langs: JSON.stringify(languages_and_levels),
    salary_expectation: body.salary ? parseInt(body.salary) : undefined,
    skills: JSON.stringify(skills),
    drivers_license: JSON.stringify(drivers_license),
    earliest_start_date:
      body.earliestStartDate &&
      dayjs(body.earliestStartDate).format("YYYY-MM-DD"),
    location: body.city,
    other_documents: body.otherDocuments,
    additional_documents: body.otherDocuments,
    files: body.files,
  };

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { data } = yield call(apiApplyToJob, { jobUrlKey, apiPayload });
    yield put(applyToJobSuccess());
    yield call(callback, { candidateUrlKey: data.candidate_urlkey });
  } catch (e) {
    yield put(applyToJobFailed(e)); // TODO handle error
  }
}

function* handleOnCheckCandidateExist({
  payload,
}: PayloadActionWithCallback<{
  email: string;
}>) {
  const { callback } = payload;
  const url = "/candidates/is-available";
  try {
    yield call(apiCheckCandidateExist, { url, apiPayload: payload });
    yield put(checkCandidateExistSuccess());
    yield call(callback);
  } catch (_error) {
    yield call(callback, "error");
    yield put(checkCandidateExistFailed()); // TODO handle error
  }
}

function* handleOnSendMagicLink({
  payload,
}: PayloadActionWithCallback<{
  email: string;
  urlKey: string;
}>) {
  const { urlKey, email, callback } = payload;
  const url = `/apply/magic/${urlKey}`;

  const apiPayload = {
    email,
  };

  try {
    yield call(apiSendMagicLink, { url, apiPayload });
    yield put(sendMagicLinkSuccess());
    yield call(callback);
  } catch (_error) {
    yield put(checkCandidateExistFailed()); // TODO handle error
  }
}

export type TCity = {
  country: string;
  state?: string;
  name: string;
  countrycode: string;
  type: string;
};

function* handleOnFetchCities({
  payload,
}: PayloadAction<{
  city: string;
}>) {
  const { city } = payload;
  try {
    const { features } = yield call(apiGetCities, { city });
    const modifiedCities = features.reduce(
      (
        acc: TCity[],
        item: {
          properties: TCity;
        }
      ) => (item.properties.type === "city" ? [...acc, item.properties] : acc),
      []
    );
    yield put(fetchCitiesSuccess(modifiedCities));
  } catch (e) {
    yield put(fetchCitiesFailed(e)); // TODO handle error
  }
}

function* handleOnSubmitAdditionalQuestions({
  payload,
}: PayloadAction<{
  data: TAdditionalQuestionsFormFields;
  jobUrlKey: string;
}>) {
  const { data, jobUrlKey } = payload;
  try {
    const languages_and_levels = data.languages?.reduce(
      (result: any, item: any) => {
        result[item.language] = item.level;
        return result;
      },
      {}
    );

    const skills = data.skills?.reduce((result: any, item: any) => {
      result[item.id] = item.title;
      return result;
    }, {});

    const drivers_license = data.driverLicense?.reduce(
      (result: any, item: any) => {
        result[item.value] = item.label;
        return result;
      },
      {}
    );

    const apiPayload = {
      current_job: data.professionalStatus,
      highest_degree: data.education,
      langs: JSON.stringify(languages_and_levels),
      salary_expectation: data.salary
        ? data.salary.replace(".", "")
        : undefined,
      skills: JSON.stringify(skills),
      drivers_license: JSON.stringify(drivers_license),
      answers: data.questions,
      earliest_start_date:
        data.earliestStartDate &&
        dayjs(data.earliestStartDate).format("YYYY-MM-DD"),
      phone: data.phone,
      country: data.country,
      location: data.city,
      working_permit_eu: data.workingPermitEU == "1" ? 1 : false,
    };

    const requests = [];
    if (data.documents.filter(({ file }) => file).length) {
      requests.push(
        call(apiUpdateCandidateDocuments, {
          files: data.documents.map(({ file }) => file),
          jobUrlKey,
        })
      );
    }

    if (data.photo.file) {
      requests.push(call(apiUpdateCandidatePhoto, { file: data.photo.file }));
    }

    requests.push(
      call(apiSubmitAdditionalQuestions, { apiPayload, jobUrlKey })
    );

    yield all(requests);
    yield put(submitAdditionalQuestionsSuccess());
  } catch (e) {
    yield put(submitAdditionalQuestionsFailed(e)); // TODO handle error
  }
}

function* handleOnFetchTemplatePreview({ payload }: PayloadAction<string>) {
  const url = `/company/templates/preview/${payload}`;
  const { company } = yield select(getCompanySettingsCompanyState);

  try {
    const { data } = yield call(apiFetchTemplatePreview, { url });

    const orginalTemplate = data.template_attributes;

    const formattedTemplate: any = {
      ...orginalTemplate,
      company: company.title,
      company_website: company.website,
      header_1: orginalTemplate.header_1_name,
      header_2: orginalTemplate.header_2_name,
      header_3: orginalTemplate.header_3_name,
      logo: orginalTemplate.logo_name,
    };

    for (const [key, value] of Object.entries(orginalTemplate)) {
      formattedTemplate[`template_${key}`] = value;
    }

    yield put(
      fetchPreviewTemplateSuccess({
        template: formattedTemplate,
      })
    );
  } catch (e) {
    yield put(fetchPreviewTemplateFailed(e)); // TODO handle error
  }
}

function* JobPreviewSaga() {
  yield takeLatest(fetchJob, handleOnFetchJob);
  yield takeLatest(applyToJob, handleOnApplyToJob);
  yield takeLatest(checkCandidateExist, handleOnCheckCandidateExist);
  yield takeLatest(sendMagicLink, handleOnSendMagicLink);
  yield takeLatest(fetchCities, handleOnFetchCities);
  yield takeLatest(
    submitAdditionalQuestions,
    handleOnSubmitAdditionalQuestions
  );
  yield takeLatest(fetchPreviewTemplate, handleOnFetchTemplatePreview);
}

export default JobPreviewSaga;
