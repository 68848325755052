import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../config";

export const getTeamsFiltersState = (state: RootState) => state.teams.filters;

export const getTeamsNavigationState = (state: RootState) =>
  state.teams.navigation;

export const getTeamsPaginationState = (state: RootState) =>
  state.teams.pagination;

export const getTeamsFilteredPaginationState = createSelector(
  [getTeamsNavigationState, getTeamsPaginationState],
  ({ selectedUserStatus }, pagination) => {
    const targetStatus = selectedUserStatus.toLowerCase() as
      | "joined"
      | "pending";
    return pagination[targetStatus];
  }
);

export const getTeamsUsersState = (state: RootState) => state.teams.users;

export const getTeamsIsLoading = (state: RootState) => state.teams.isLoading;

export const getTeamsIsListLoading = (state: RootState) =>
  state.teams.isListLoading;
