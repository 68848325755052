import * as yup from "yup";
import { TFunction } from "i18next";
import { IConnectIntegrationDialogDefaultFormFields } from "../../models/Integrations";

export const connectIntegrationDialogFormFields = ({
  t,
  urlRequired,
  usernameRequired,
  passwordRequired,
  companyIdRequired,
  apiKeyRequired,
}: {
  t: TFunction;
  urlRequired: boolean;
  usernameRequired: boolean;
  passwordRequired: boolean;
  companyIdRequired: boolean;
  apiKeyRequired: boolean;
}) => {
  return yup.object().shape({
    url: urlRequired
      ? yup.string().required(t("register.message") as string)
      : yup.string(),
    username: usernameRequired
      ? yup.string().required(t("register.message") as string)
      : yup.string(),
    password: passwordRequired
      ? yup.string().required(t("register.message") as string)
      : yup.string(),

    companyId: companyIdRequired
      ? yup.string().required(t("register.message") as string)
      : yup.string(),
    apiKey: apiKeyRequired
      ? yup.string().required(t("register.message") as string)
      : yup.string(),
  });
};

export const connectIntegrationDialogDefaultValues: IConnectIntegrationDialogDefaultFormFields =
  {
    url: "",
    username: "",
    password: "",
    companyId: "",
    apiKey: "",
  };
