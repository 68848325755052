import { styled } from "@mui/system";
import { Button, Chip, Stack } from "@mui/material";

export const StyledAdditionalQuestionsContainer = styled(Stack)(
  ({ desktop, companyColor }: { desktop: boolean; companyColor?: string }) => ({
    width: desktop ? 1128 : "85vw",
    minHeight: `calc(100vh - ${desktop ? 128 : 48}px)`,
    justifyContent: "flex-start",
    margin: desktop ? "64px auto" : 24,
    hr: {
      borderColor: "#E5E7EB",
    },
    ".input-selector-filter": {
      width: 125,
      "& .MuiInputBase-input": {
        lineHeight: "40px",
        height: "40px !important",
      },
    },
    ".react-tel-input": {
      input: {
        "&:focus": {
          borderColor: `${companyColor} !important`,
          boxShadow: `0 0 3px 1.25px ${companyColor}`,
        },
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: `${companyColor} !important`,
        boxShadow: `0 0 3px 1.25px ${companyColor}`,
      },
    },
    ".check-status-button": {
      background: companyColor,
      "&:hover": {
        background: companyColor,
        opacity: 0.8,
      },
    },
    ".submit-button": {
      background: companyColor,
      "&:hover": {
        background: companyColor,
        opacity: 0.8,
      },
    },
    ".skip-button": {
      color: companyColor,
      borderColor: `${companyColor} !important`,
    },
    ".Mui-checked": {
      color: `${companyColor} !important`,
    },
    ".company-logo": {
      objectFit: "contain",
    },
  })
);

export const SuccessScreenContainer = styled(Stack)(
  ({ desktop }: { desktop: boolean }) => ({
    marginTop: 40,
    padding: `${desktop ? 16 : 0}px 30px`,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "#F9FAFB",
    height: desktop ? 285 : 200,
    borderRadius: 6,
    svg: { width: 36, height: 36, strokeWidth: 1.5, stroke: "#22C55E" },
  })
);

export const StyledAdditionalQuestionsFieldContainer = styled(Stack)(
  ({ desktop, companyColor }: { desktop: boolean; companyColor?: string }) => ({
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    ".title": {
      width: desktop ? "60%" : "100%",
      height: 40,
      justifyContent: "center",
    },
    padding: "24px 0",
    borderBottom: "1px solid #E5E7EB",
    ".MuiChip-deleteIcon": {
      background: "transparent !important",
      fill: `#fff !important`,
      stroke: "#fff !important",
    },
    ".MuiAutocomplete-tag": {
      background: companyColor,
      color: companyColor ? "#fff !important " : "#7F7F7F !important",
      border: companyColor ? "#fff !important" : "#7F7F7F !important",
      "&:hover": {
        opacity: 0.8,
      },
    },
    ".input-field": {
      width: desktop ? "40%" : "100%",
      ".MuiAutocomplete-tag": {
        background: `${companyColor} !important`,
        color: companyColor ? "#fff !important " : "#7F7F7F !important",
        border: companyColor ? "#fff !important" : "#7F7F7F !important",
        "&:hover": {
          opacity: 0.8,
        },
      },
      ".skills-autocomplete": {
        ".MuiAutocomplete-input": {
          position: "relative",
          width: "100%",
        },
      },
      "& .Mui-checked": {
        color: `${companyColor} !important`,
      },
      "& .MuiRadio-root": {
        color: companyColor,
      },
    },
    ".input-selector-filter": {
      width: "100%",
      textDecoration: "none",
      textTransform: "none",
      "[data-testid=CloseIcon]": {
        cursor: "pointer",
        width: 18,
        height: 18,
        marginRight: 8,
      },
    },
  })
);

export const StyledAdditionalQuestionsSkillsContainer = styled(Stack)({
  flexWrap: "wrap",
  gap: 8,
  alignItems: "center",
  width: "100%",
  minHeight: 40,
  background: "transparent",
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  boxShadow: "none",
  color: "#6B7280",
  padding: "6px 16px",
});

export const StyledAdditionalQuestionsSkillChip = styled(Chip)(
  ({ companyColor }: { companyColor?: string }) => ({
    color: companyColor ? `#fff !important` : "#7F7F7F !important",
    background: companyColor
      ? `${companyColor} !important`
      : "#F5F5F5 !important",
    fontSize: "14px!important",
    border: companyColor ? "#fff !important" : "1px solid #D3D6E0 !important",
    "&:hover": {
      opacity: 0.8,
    },
    ".MuiChip-deleteIcon": {
      stroke: companyColor ? "#fff !important" : "#7F7F7F !important",
    },
  })
);

export const StyledAddLanguageButton = styled(Button)(
  ({ companyColor }: { companyColor?: string }) => ({
    width: "100%",
    color: companyColor,
    borderColor: `${companyColor} !important`,
  })
);

export const StyledRemoveLanguageButtonContainer = styled(Stack)({
  alignSelf: "center",
  svg: { width: 24, height: 24, cursor: "pointer", stroke: "#EF4444" },
});

export const StyledAdditionalQuestionsInfoBlock = styled(Stack)(
  ({ isSubmitted, desktop }: { isSubmitted: boolean; desktop: boolean }) => ({
    marginTop: isSubmitted ? "auto" : 24,
    justifyContent: "center",
    gap: 24,
    flexDirection: "row",
    padding: 24,
    position: "relative",
    left: desktop ? 30 : 0,
  })
);
