import React, {
  ChangeEvent,
  useState,
  useEffect,
  useMemo,
  MouseEvent,
} from "react";
import { createFilterOptions } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import ApplyJobDialogComponent from "./component";
import { IApplyJobDialog } from "../../../models/Team";
import {
  recruiterJobActionAuthFormFields,
  recruiterJobActionRegisterFormDefaultValues,
} from "./config";
import { getJobPreviewState } from "../../../store/selectors/JobPreview";
import { TRecruiterJobActionRegisterFormFields } from "../../../models/JobPreview";
import {
  applyToJob,
  checkCandidateExist,
  fetchJob,
  resetApplyError,
  sendMagicLink,
} from "../../../store/reducers/JobPreview";
import { getJobListSelectedJobs } from "../../../store/selectors/JobList";
import { getSingleJobState } from "../../../store/selectors/SingleJob";
import { fetchJobApplications } from "../../../store/reducers/SingleJob";
import { addMessage } from "../../../store/reducers/Snackbar";
import { TListOption } from "../../../models/common";

const ApplyJobDialog = ({ isOpen, onCancel }: IApplyJobDialog) => {
  const dispatch = useDispatch();
  const { key } = useParams();
  const [searchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source") || "";
  const { t } = useTranslation();

  const selectedJobs = useSelector(getJobListSelectedJobs);
  const { jobDetails, jobLocations } = useSelector(getSingleJobState);
  const {
    isApplyInProgress,
    jobData,
    isLoading: isJobDataLoading,
  } = useSelector(getJobPreviewState);

  const [locale, setLocale] = useState("de");
  const [uploadedCV, setUploadedCV] = useState<FileList | null>(null);

  const jobCity = jobLocations ? jobLocations[0].city : "";
  const jobUrlKey = key ? jobDetails?.url_key : selectedJobs[0]?.url_key;

  const isCvRequired = selectedJobs[0]
    ? Boolean(selectedJobs[0].cv_required)
    : Boolean(jobDetails?.cv_required);

  const isPhoneRequired = selectedJobs[0]
    ? Boolean(selectedJobs[0].phone_required)
    : Boolean(jobDetails?.phone_required);

  const isLocationRequired = selectedJobs[0]
    ? Boolean(selectedJobs[0].location_required)
    : Boolean(jobDetails?.location_required);

  const isWorkingPermitEuRequired = selectedJobs[0]
    ? Boolean(selectedJobs[0].working_permit_eu_required)
    : Boolean(jobDetails?.working_permit_eu_required);

  const isSalaryExpectationRequired = selectedJobs[0]
    ? Boolean(selectedJobs[0].salary_expectation_required)
    : Boolean(jobDetails?.salary_expectation_required);

  const isEarliestStartDateRequired = selectedJobs[0]
    ? Boolean(selectedJobs[0].earliest_start_date_required)
    : Boolean(jobDetails?.earliest_start_date_required);

  const isCurrentJobRequired = selectedJobs[0]
    ? Boolean(selectedJobs[0].current_job_required)
    : Boolean(jobDetails?.current_job_required);

  const isHighestDegreeRequired = selectedJobs[0]
    ? Boolean(selectedJobs[0].highest_degree_required)
    : Boolean(jobDetails?.highest_degree_required);

  const isDriversLicenseRequired = selectedJobs[0]
    ? Boolean(selectedJobs[0].drivers_license_required)
    : Boolean(jobDetails?.drivers_license_required);

  const isLangsRequired = selectedJobs[0]
    ? Boolean(selectedJobs[0].langs_required)
    : Boolean(jobDetails?.langs_required);

  const isSkillsRequired = selectedJobs[0]
    ? Boolean(selectedJobs[0].skills_required)
    : Boolean(jobDetails?.skills_required);

  const isOtherDocumentsRequired = selectedJobs[0]
    ? Boolean(selectedJobs[0].other_documents_required)
    : Boolean(jobDetails?.other_documents_required);

  const hasRequiredFields =
    Boolean(selectedJobs[0]?.drivers_license_required) ||
    Boolean(selectedJobs[0]?.langs_required) ||
    Boolean(selectedJobs[0]?.skills_required);

  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(
      recruiterJobActionAuthFormFields({
        t,
        cvRequired: isCvRequired,
        currentJobRequired: isCurrentJobRequired,
        driverLicenseRequired: isDriversLicenseRequired,
        earliestStartDateRequired: isEarliestStartDateRequired,
        highestDegreeRequired: isHighestDegreeRequired,
        langsRequired: isLangsRequired,
        locationRequired: isLocationRequired,
        otherDocumentsRequired: isOtherDocumentsRequired,
        phoneRequired: isPhoneRequired,
        salaryExpectationRequired: isSalaryExpectationRequired,
        skillsRequired: isSkillsRequired,
      })
    ),
    defaultValues: {
      ...recruiterJobActionRegisterFormDefaultValues,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "languages",
  });

  const modifiedDriverLicenses: TListOption[] = useMemo(() => {
    if (jobData.driversLicenses)
      return jobData.driversLicenses.map(
        (dl: { id: string; name: string }) => ({
          value: dl.id,
          label: dl.name,
        })
      );
    return [];
  }, [jobData]);

  const modifiedSkills: TListOption[] = useMemo(() => {
    if (jobData.skills)
      return jobData.skills.map((dl: { id: string; title: string }) => ({
        value: dl.id,
        label: dl.title,
      }));
    return [];
  }, [jobData]);

  const { modifiedLanguages, modifiedLanguageLevels } = useMemo(() => {
    let modifiedLanguages;
    let modifiedLanguageLevels;
    if (jobData.languages)
      modifiedLanguages = jobData.languages.map(
        (lang: { id: string; name: string }) => ({
          label: lang.name,
          value: lang.name,
        })
      );
    if (jobData.languageLevels)
      modifiedLanguageLevels = jobData.languageLevels.map(
        (lang: { id: string; name: string }) => ({
          label: lang.name,
          value: lang.name,
        })
      );

    return { modifiedLanguages, modifiedLanguageLevels };
  }, [jobData]);

  const noRequiredFieldsData =
    !modifiedLanguageLevels?.length ||
    !modifiedLanguages?.length ||
    !modifiedSkills?.length ||
    !modifiedDriverLicenses?.length;
  const isJobListLoading = hasRequiredFields && noRequiredFieldsData;
  const isDataLoading = isJobListLoading || isJobDataLoading;

  const isLoading = key ? !jobDetails || isDataLoading : isDataLoading;

  const filterOptions = createFilterOptions({
    stringify: (option: TListOption) => option.label,
    ignoreCase: true,
    matchFrom: "any",
    limit: 10,
  });

  const handleAddNewLanguage = () => {
    append({ language: "", level: "" });
  };

  const handleOnUploadCV = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) setUploadedCV(event.target.files);
  };

  const handleOnResetCV = (event: MouseEvent) => {
    setUploadedCV(null);
    event.preventDefault();
    setValue("cv", null);
  };

  const onClose = () => {
    if (key) {
      dispatch(
        fetchJobApplications({
          jobId: key,
          isApplyDialog: true,
          callback: () => "fetchJobApplications",
        })
      );
    }
    onCancel();
    resetApplyError();
    reset();
  };

  const onCandidateExist = () => {
    dispatch(
      sendMagicLink({
        urlKey: jobUrlKey as string,
        email: getValues().email,
        callback: () => onClose(),
      })
    );
  };

  const onSuccessApplyToJob = () => {
    onClose();
    dispatch(
      addMessage({
        type: "success",
        title: t("singleJob.addCandidateSuccess"),
      })
    );
  };

  const onCandidateNotExist = (body: any) => {
    dispatch(
      applyToJob({
        jobUrlKey: jobUrlKey as string,
        body: { ...body, ...(utm_source && { utm_source }) },
        callback: () => onSuccessApplyToJob(),
      })
    );
  };

  const onSubmit = (formFields: TRecruiterJobActionRegisterFormFields) => {
    const body = {
      ...formFields,
      country: formFields.location?.countrycode as string,
      city: formFields.location?.name as string,
      salary: formFields.salaryExpectation.replace(".", ""),
      professionalStatus: formFields.currentJob as string,
      education: formFields.highestDegree as string,
      photo: null as any,
      questions: [],
      working_permit_eu: isWorkingPermitEuRequired
        ? formFields.workingPermitEU == "1"
          ? 1
          : false
        : null,
      files: uploadedCV,
    };

    dispatch(
      checkCandidateExist({
        email: getValues().email,
        callback: (type) => {
          if (type === "error") {
            onCandidateExist();
          } else {
            onCandidateNotExist(body);
          }
        },
      })
    );
  };

  useEffect(() => {
    const language = localStorage.getItem("lang") || "de_DE";
    if (language === "en_US") {
      setLocale("en");
    }
  }, []);

  useEffect(() => {
    const jobUrlKey = selectedJobs.length
      ? selectedJobs[0].url_key
      : jobDetails
        ? jobDetails.url_key
        : undefined;
    const noData =
      !jobData.driversLicenses || !jobData.skills || !jobData.languages;
    if (noData && jobUrlKey) {
      dispatch(
        fetchJob({
          jobUrlKey: jobUrlKey as string,
        })
      );
    }
  }, [jobDetails, selectedJobs]);

  return (
    <ApplyJobDialogComponent
      t={t}
      key={key}
      isOpen={isOpen}
      isLoading={isLoading}
      isApplyInProgress={isApplyInProgress}
      isCvRequired={isCvRequired}
      isPhoneRequired={isPhoneRequired}
      isLocationRequired={isLocationRequired}
      isWorkingPermitEuRequired={isWorkingPermitEuRequired}
      isSalaryExpectationRequired={isSalaryExpectationRequired}
      isEarliestStartDateRequired={isEarliestStartDateRequired}
      isCurrentJobRequired={isCurrentJobRequired}
      isHighestDegreeRequired={isHighestDegreeRequired}
      isDriversLicenseRequired={isDriversLicenseRequired}
      isSkillsRequired={isSkillsRequired}
      isLangsRequired={isLangsRequired}
      isOtherDocumentsRequired={isOtherDocumentsRequired}
      languages={modifiedLanguages}
      levels={modifiedLanguageLevels}
      fields={fields}
      skills={modifiedSkills}
      driverLicenses={modifiedDriverLicenses}
      jobDetails={jobDetails}
      selectedJobs={selectedJobs}
      uploadedCV={uploadedCV}
      jobCity={jobCity}
      locale={locale}
      control={control}
      errors={errors}
      register={register}
      filterOptions={filterOptions}
      onClose={onClose}
      onSubmit={onSubmit}
      onUploadCV={handleOnUploadCV}
      onResetCV={handleOnResetCV}
      onAddNewLanguage={handleAddNewLanguage}
      onRemoveLanguage={remove}
      handleSubmit={handleSubmit}
    />
  );
};

export default ApplyJobDialog;
