import { call, put, select, takeLatest } from "redux-saga/effects";
import { apiGetUser } from "../../api/Users";
import {
  fetchUser,
  fetchUserFailed,
  fetchUserSuccess,
  updateCurrentUserPhoto,
  updateCurrentUserPhotoFailed,
  updateCurrentUserPhotoSuccess,
  updateCurrentUserData,
  updateCurrentUserDataFailed,
  updateCurrentUserDataSuccess,
  updateCurrentUserPasswordSuccess,
  updateCurrentUserPasswordFailed,
  updateCurrentUserPassword,
  fetchUserWithCallback,
  authGmailSuccess,
  authGmailFailed,
  authGmail,
  disconnectGmailSuccess,
  disconnectGmailFailed,
  disconnectGmail,
  confirmGmailSuccess,
  confirmGmailFailed,
  confirmGmail,
  authOutlookSuccess,
  authOutlookFailed,
  disconnectOutlookSuccess,
  disconnectOutlookFailed,
  confirmOutlookSuccess,
  confirmOutlookFailed,
  authOutlook,
  confirmOutlook,
  disconnectOutlook,
} from "../reducers/CurrentUser";
import { fetchCompanyData } from "../reducers/CompanySettings";
import { fetchCandidateApplications } from "../reducers/Candidate";
import { apiGetStatuses } from "../../api/common";
import {
  TApiUpdateCurrentUserData,
  TApiUpdateCurrentUserPassword,
  TApiUpdateCurrentUserPhoto,
} from "../../models/CurrentUser";
import { PayloadActionWithCallback } from "../../models/common";
import { getCurrentUserIsCandidate } from "../selectors/CurrentUser";
import {
  apiAuthGmail,
  apiAuthOutlook,
  apiConfirmGmail,
  apiConfirmOutlook,
  apiDisconnectGmail,
  apiDisconnectOutlook,
  apiUpdateCurrentUserData,
  apiUpdateCurrentUserPassword,
  apiUpdateCurrentUserPhoto,
} from "../../api/CurrentUser";

function* handleOnFetchUser() {
  try {
    const {
      data: { statuses },
    } = yield call(apiGetStatuses);
    const { data } = yield call(apiGetUser);

    const { customer, candidate } = data;
    const user = customer || candidate;
    const convertedResponse = {
      agencyId: user?.agency_id,
      companyId: user?.company_id,
      companyUrlKey: user?.company_url_key,
      firstName: user?.firstname,
      lastName: user?.lastname,
      email: user?.username || user?.email,
      username: user?.username || user.email,
      memberId: user?.member_id,
      phoneNumber: user?.phonenumber,
      isAgency: user?.is_agency,
      isAnalyticsAllowed: customer?.is_analytics_allowed,
      applicationsAllowed: user?.applications_allowed,
      isAdmin: user?.is_admin,
      isDisabled: user?.is_disabled,
      isActivated: user?.is_activated,
      lang: user?.lang,
      photo: user?.photo,
      linkedinUrl: user?.linkedin_url,
      xingUrl: user?.xing_url,
      noPassword: user?.noPassword,
      gmailEmail: user?.gmail_email,
      outlookEmail: user?.microsoft_email,
    };

    const allStatuses = statuses.map(
      (status: { title: string; const: string }) => ({
        label: status.title,
        value: status.const,
      })
    );

    const orderStatuses = ["new", "review", "hired", "rejected"];

    const sortedStatuses = allStatuses.sort(
      (a: { value: string }, b: { value: string }) => {
        const orderIndexA = orderStatuses.indexOf(a.value);
        const orderIndexB = orderStatuses.indexOf(b.value);

        if (orderIndexA === -1) {
          return 1;
        } else if (orderIndexB === -1) {
          return -1;
        }
        return orderIndexA - orderIndexB;
      }
    );

    yield put(
      fetchUserSuccess({
        userData: convertedResponse,
        isCandidate: Boolean(candidate),
        jassId: customer?.jass_id,
        statuses: sortedStatuses,
      })
    );
    if (customer) yield put(fetchCompanyData());
    else yield put(fetchCandidateApplications());
  } catch (e) {
    yield put(fetchUserFailed(e)); // TODO handle error
  }
}

function* handleOnFetchUserWithCallback({
  payload,
}: PayloadActionWithCallback<unknown>) {
  const { callback } = payload;
  try {
    const {
      data: { statuses },
    } = yield call(apiGetStatuses);
    const { data } = yield call(apiGetUser);

    const { customer, candidate } = data;
    const user = customer || candidate;
    const convertedResponse = {
      agencyId: user?.agency_id,
      companyId: user?.company_id,
      companyUrlKey: user?.company_url_key,
      firstName: user?.firstname,
      lastName: user?.lastname,
      email: user?.username || user?.email,
      username: user?.username || user.email,
      memberId: user?.member_id,
      phoneNumber: user?.phonenumber,
      isAgency: user?.is_agency,
      isAdmin: user?.is_admin,
      isDisabled: user?.is_disabled,
      isActivated: user?.is_activated,
      lang: user?.lang,
      photo: user?.photo,
      linkedinUrl: user?.linkedin_url,
      xingUrl: user?.xing_url,
      noPassword: user?.noPassword,
    };

    const allStatuses = statuses.map(
      (status: { title: string; const: string }) => ({
        label: status.title,
        value: status.const,
      })
    );

    const orderStatuses = ["new", "review", "hired", "rejected"];

    const sortedStatuses = allStatuses.sort(
      (a: { value: string }, b: { value: string }) => {
        const orderIndexA = orderStatuses.indexOf(a.value);
        const orderIndexB = orderStatuses.indexOf(b.value);

        if (orderIndexA === -1) {
          return 1;
        } else if (orderIndexB === -1) {
          return -1;
        }
        return orderIndexA - orderIndexB;
      }
    );

    yield put(
      fetchUserSuccess({
        userData: convertedResponse,
        isCandidate: Boolean(candidate),
        jassId: customer?.jass_id,
        statuses: sortedStatuses,
      })
    );
    if (customer) yield put(fetchCompanyData());
    else yield put(fetchCandidateApplications());
    yield call(callback);
  } catch (e) {
    yield put(fetchUserFailed(e)); // TODO handle error
  }
}

function* handleOnUpdateCurrentUserPhoto({
  payload,
}: PayloadActionWithCallback<TApiUpdateCurrentUserPhoto>) {
  const { photo, reset, callback } = payload;

  const apiPayload: TApiUpdateCurrentUserPhoto = {
    photo,
    reset,
  };

  const isCandidate: boolean = yield select(getCurrentUserIsCandidate);
  const url = isCandidate
    ? "/candidate/upload-photo"
    : "/company/profile/photo-update";

  try {
    yield call(apiUpdateCurrentUserPhoto, { url, apiPayload });
    yield put(updateCurrentUserPhotoSuccess());
    yield call(callback);
  } catch (e) {
    yield put(updateCurrentUserPhotoFailed(e)); // TODO handle error
  }
}

function* handleOnUpdateCurrentUserData({
  payload,
}: PayloadActionWithCallback<{ data: TApiUpdateCurrentUserData }>) {
  const { data, callback } = payload;

  const isCandidate: boolean = yield select(getCurrentUserIsCandidate);
  const url = isCandidate
    ? "/candidate/update-profile"
    : "/company/profile/member-update";

  try {
    yield call(apiUpdateCurrentUserData, { url, apiPayload: data });
    yield put(updateCurrentUserDataSuccess());
    yield call(callback);
  } catch (e) {
    yield put(updateCurrentUserDataFailed(e)); // TODO handle error
  }
}

function* handleOnUpdateCurrentUserPassword({
  payload,
}: PayloadActionWithCallback<{ data: TApiUpdateCurrentUserPassword }>) {
  const { data, callback } = payload;

  const isCandidate: boolean = yield select(getCurrentUserIsCandidate);
  const url = isCandidate
    ? "/candidate/password-update"
    : "/company/profile/password-update";

  try {
    yield call(apiUpdateCurrentUserPassword, { url, apiPayload: data });
    yield put(updateCurrentUserPasswordSuccess());
    yield call(callback);
  } catch (e) {
    yield put(updateCurrentUserPasswordFailed(e)); // TODO handle error
  }
}

function* handleOnAuthGmail() {
  try {
    const { data } = yield call(apiAuthGmail);
    yield put(authGmailSuccess());
    window.open(data.auth_url, "_blank");
  } catch (e) {
    yield put(authGmailFailed(e)); // TODO handle error
  }
}

function* handleOnConfirmGmail({
  payload,
}: PayloadActionWithCallback<{ code: string }>) {
  const { code, callback } = payload;
  const apiPayload = {
    code,
  };
  try {
    yield call(apiConfirmGmail, { apiPayload });
    yield put(confirmGmailSuccess());
    yield call(callback);
  } catch (e) {
    yield put(confirmGmailFailed(e)); // TODO handle error
  }
}

function* handleOnDisconnectGmail({
  payload,
}: PayloadActionWithCallback<unknown>) {
  const { callback } = payload;
  try {
    yield call(apiDisconnectGmail);
    yield put(disconnectGmailSuccess());
    yield call(callback);
  } catch (e) {
    yield put(disconnectGmailFailed(e)); // TODO handle error
  }
}

function* handleOnAuthOutlook() {
  try {
    const { data } = yield call(apiAuthOutlook);
    yield put(authOutlookSuccess());
    window.open(data.auth_url, "_blank");
  } catch (e) {
    yield put(authOutlookFailed(e)); // TODO handle error
  }
}

function* handleOnConfirmOutlook({
  payload,
}: PayloadActionWithCallback<{ code: string }>) {
  const { code, callback } = payload;
  const apiPayload = {
    code,
  };
  try {
    yield call(apiConfirmOutlook, { apiPayload });
    yield put(confirmOutlookSuccess());
    yield call(callback);
  } catch (e) {
    yield put(confirmOutlookFailed(e)); // TODO handle error
  }
}

function* handleOnDisconnectOutlook({
  payload,
}: PayloadActionWithCallback<unknown>) {
  const { callback } = payload;
  try {
    yield call(apiDisconnectOutlook);
    yield put(disconnectOutlookSuccess());
    yield call(callback);
  } catch (e) {
    yield put(disconnectOutlookFailed(e)); // TODO handle error
  }
}

function* UserSaga() {
  yield takeLatest(fetchUser, handleOnFetchUser);
  yield takeLatest(fetchUserWithCallback, handleOnFetchUserWithCallback);
  yield takeLatest(updateCurrentUserPhoto, handleOnUpdateCurrentUserPhoto);
  yield takeLatest(updateCurrentUserData, handleOnUpdateCurrentUserData);
  yield takeLatest(
    updateCurrentUserPassword,
    handleOnUpdateCurrentUserPassword
  );
  yield takeLatest(authGmail, handleOnAuthGmail);
  yield takeLatest(confirmGmail, handleOnConfirmGmail);
  yield takeLatest(disconnectGmail, handleOnDisconnectGmail);
  yield takeLatest(authOutlook, handleOnAuthOutlook);
  yield takeLatest(confirmOutlook, handleOnConfirmOutlook);
  yield takeLatest(disconnectOutlook, handleOnDisconnectOutlook);
}

export default UserSaga;
