import React from "react";
import {
  Dialog,
  Typography,
  Stack,
  Box,
  TextField,
  Button,
  Divider,
  InputLabel,
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Loading from "../../Loading";
import CVSection from "../../../pages/JobPreview/JobAction/CVSection";
import KOQuestionPhone from "../../../pages/JobPreview/KOQuestions/Phone";
import KOQuestionLocation from "../../../pages/JobPreview/KOQuestions/Location";
import KOQuestionsWorkingPermitEU from "../../../pages/JobPreview/KOQuestions/WorkingPermitEU";
import KoQuestionSalary from "../../../pages/JobPreview/KOQuestions/Salary";
import KOQuestionStartDate from "../../../pages/JobPreview/KOQuestions/StartDate";
import KoQuestionControlledTextField from "../../../pages/JobPreview/KOQuestions/ControlledTextfield";
import KoQuestionMultipleSelection from "../../../pages/JobPreview/KOQuestions/ControlledMultipleSelection";
import KOQuestionLanguages from "../../../pages/JobPreview/KOQuestions/Languages";
import KOQuestionDocuments from "../../../pages/JobPreview/KOQuestions/Documents";
import { StyledLoadingApplyButton } from "../../../pages/JobPreview/styles";
import { IApplyJobDialogComponent } from "../../../models/Team";

const ApplyJobDialogComponent = ({
  t,
  key,
  isOpen,
  isLoading,
  isCvRequired,
  isApplyInProgress,
  isPhoneRequired,
  isLocationRequired,
  isWorkingPermitEuRequired,
  isSalaryExpectationRequired,
  isEarliestStartDateRequired,
  isCurrentJobRequired,
  isHighestDegreeRequired,
  isDriversLicenseRequired,
  isSkillsRequired,
  isLangsRequired,
  isOtherDocumentsRequired,
  languages,
  levels,
  fields,
  skills,
  filterOptions,
  driverLicenses,
  jobDetails,
  selectedJobs,
  uploadedCV,
  jobCity,
  locale,
  control,
  errors,
  register,
  onClose,
  onSubmit,
  onUploadCV,
  onResetCV,
  onAddNewLanguage,
  onRemoveLanguage,
  handleSubmit,
}: IApplyJobDialogComponent) => (
  <Dialog open={isOpen} onClose={onClose} fullWidth>
    <Stack
      p={3}
      spacing={3}
      sx={{ backgroundColor: "#fff" }}
      data-testid={"jobs-list-apply-job-dialog"}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="body1" fontWeight={500}>
            {t("joblist.add")}
          </Typography>
        </Stack>
        <Stack>
          <XMarkIcon
            onClick={onClose}
            width={24}
            height={24}
            stroke="#131E30"
            style={{ cursor: "pointer" }}
          />
        </Stack>
      </Stack>
      <Divider
        sx={{
          width: "100%",
          borderColor: "#E5E7EB",
          my: 3,
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Stack spacing={1.5}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle2" color="#000000" fontWeight={500}>
                {t("create_job_first_step.job_title")}
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                maxWidth="75%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {key ? jobDetails?.title : selectedJobs[0]?.title}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle2" color="#000000" fontWeight={500}>
                {t("joblist.location_placeholder")}
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                maxWidth="75%"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {key ? jobCity : selectedJobs[0]?.locations[0]?.city}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle2" color="#000000" fontWeight={500}>
                {t("create_job_second_step.job_type")}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {key
                  ? jobDetails?.contract_type
                  : selectedJobs[0]?.contract_type}
              </Typography>
            </Stack>
          </Stack>

          <Box
            sx={{
              ".required-label": {
                textTransform: "capitalize",
                "&::after": {
                  content: '"*"',
                  position: "absolute",
                  marginLeft: 0.5,
                  color: "#EF4444",
                  fontSize: 20,
                },
              },
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <InputLabel className="required-label">{t("email")}</InputLabel>
                <TextField
                  data-testid="apply-job-dialog-email"
                  {...register("email")}
                  placeholder="name@example.de"
                  fullWidth
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                />
              </Box>
              <Box mt={1.5}>
                <InputLabel className="required-label">
                  {t("candidate.firstName")}
                </InputLabel>
                <TextField
                  data-testid="apply-job-dialog-firstname"
                  placeholder={t("userSettings.firstname") as string}
                  fullWidth
                  {...register("firstname")}
                  error={Boolean(errors["firstname"])}
                  helperText={errors["firstname"]?.message}
                />
              </Box>
              <Box mt={1.5}>
                <InputLabel className="required-label">
                  {t("candidate.lastName")}
                </InputLabel>
                <TextField
                  data-testid="apply-job-dialog-lastname"
                  placeholder={t("userSettings.lastname") as string}
                  fullWidth
                  {...register("lastname")}
                  error={Boolean(errors["lastname"])}
                  helperText={errors["lastname"]?.message}
                />
              </Box>

              <Stack mt={1.5}>
                <InputLabel className={isCvRequired ? "required-label" : ""}>
                  CV
                </InputLabel>
                <CVSection
                  t={t}
                  control={control as any}
                  isRequired={isCvRequired}
                  companyColor={errors.cv ? "#FCA5A5" : undefined}
                  uploadedCV={uploadedCV?.[0]}
                  cvHasLabel
                  onUploadCV={onUploadCV}
                  onResetCV={onResetCV}
                />
                {errors.cv && (
                  <Typography
                    pl={0.75}
                    pt={0.5}
                    color="error"
                    variant="subtitle2"
                  >
                    {errors.cv.message}
                  </Typography>
                )}
              </Stack>
              <Box mt={1.5} flex={1}>
                <Stack spacing={1.5} flex={1}>
                  {isPhoneRequired && (
                    <KOQuestionPhone
                      t={t}
                      control={control as any}
                      errors={errors}
                    />
                  )}
                  {isLocationRequired && (
                    <KOQuestionLocation
                      t={t}
                      control={control as any}
                      errors={errors}
                    />
                  )}

                  {isWorkingPermitEuRequired && (
                    <Stack pt={errors.location ? 2 : 0}>
                      <KOQuestionsWorkingPermitEU
                        t={t}
                        control={control as any}
                        errors={errors}
                      />
                    </Stack>
                  )}

                  {isSalaryExpectationRequired && (
                    <KoQuestionSalary
                      t={t}
                      control={control as any}
                      errors={errors}
                    />
                  )}

                  {isEarliestStartDateRequired && (
                    <KOQuestionStartDate
                      t={t}
                      control={control as any}
                      errors={errors}
                      locale={locale}
                    />
                  )}

                  {isCurrentJobRequired && (
                    <KoQuestionControlledTextField
                      t={t}
                      control={control as any}
                      errors={errors}
                      value="currentJob"
                      title={t("create_job_third_step.current_position")}
                      placeholder={t(
                        "additional_questions.type_something_placeholder"
                      )}
                    />
                  )}

                  {isHighestDegreeRequired && (
                    <KoQuestionControlledTextField
                      t={t}
                      control={control as any}
                      errors={errors}
                      value="highestDegree"
                      title={t("create_job_third_step.highest_degree")}
                      placeholder={t(
                        "additional_questions.type_something_placeholder"
                      )}
                    />
                  )}

                  {isDriversLicenseRequired && (
                    <KoQuestionMultipleSelection
                      t={t}
                      companyColor="#178CF2"
                      control={control as any}
                      errors={errors}
                      error={errors.driverLicense}
                      value="driverLicense"
                      title={t("create_job_third_step.driving_licenses")}
                      placeholder={t(
                        "additional_questions.driving_licenses_placeholder"
                      )}
                      options={driverLicenses}
                      filterOptions={filterOptions}
                    />
                  )}

                  {isLangsRequired && (
                    <KOQuestionLanguages
                      t={t}
                      fields={fields}
                      control={control as any}
                      languages={languages}
                      levels={levels}
                      errors={errors}
                      companyColor="#178CF2"
                      filterOptions={filterOptions}
                      onAdd={onAddNewLanguage}
                      onRemove={onRemoveLanguage}
                    />
                  )}

                  {isSkillsRequired && (
                    <Box flex={1}>
                      <KoQuestionMultipleSelection
                        t={t}
                        companyColor="#178CF2"
                        control={control as any}
                        errors={errors}
                        error={errors.skills}
                        value="skills"
                        title={t("create_job_third_step.skills")}
                        placeholder={t(
                          "additional_questions.skills_placeholder"
                        )}
                        options={skills}
                        filterOptions={filterOptions}
                      />
                    </Box>
                  )}

                  {isOtherDocumentsRequired && (
                    <KOQuestionDocuments
                      t={t}
                      control={control as any}
                      errors={errors}
                    />
                  )}
                </Stack>
              </Box>

              <Stack
                mt={1.5}
                direction="row"
                spacing={1.5}
                justifyContent="flex-end"
              >
                <Button
                  data-testid="apply-job-dialog-close-button"
                  variant="outlined"
                  onClick={onClose}
                >
                  {t("button.secondCancel")}
                </Button>
                <StyledLoadingApplyButton
                  data-testid="apply-job-dialog-save-button"
                  sx={{ minWidth: 134 }}
                  type={"submit"}
                  variant={"contained"}
                  loading={isApplyInProgress}
                  loadingPosition="end"
                >
                  {t("button.save")}
                </StyledLoadingApplyButton>
              </Stack>
            </form>
          </Box>
        </>
      )}
    </Stack>
  </Dialog>
);

export default ApplyJobDialogComponent;
