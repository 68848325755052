import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledChipsManagerContainer = styled(Stack)({
  ".Mui-error": {
    textTransform: "none",
  },
  "& button": {
    maxWidth: "40px",
    maxHeight: "40px",
    minWidth: "40px",
    minHeight: "40px",
    fontSize: 30,
  },
});

export const StyledChipsContainer = styled(Stack)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: 6,
  padding: 6,
  border: "1px solid rgb(229, 231, 235)",
  borderRadius: 6,
  maxHeight: 130,
  overflow: "hidden scroll",
  alignContent: "flex-start",

  ".MuiChip-filled": {
    backgroundColor: "#edf7ff",
    maxWidth: 150,
    color: " #178cf2",
    height: 28,
  },
  "&::-webkit-scrollbar ": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#E5E7EB",
    borderRadius: "6px",
  },
});
