import React, { useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import CareerPageComponent from "./component";
import { formDefaultValues, getFormSettings } from "./config";
import {
  getCompanySettingsCompanyState,
  getCompanySettingsIsFormDataLoading,
} from "../../store/selectors/Company";
import { setIsUploadPictureDialogOpen } from "../../store/reducers/dialogs";
import {
  fetchCompanyData,
  fetchRegistrationData,
  updateCompanyData,
} from "../../store/reducers/CompanySettings";
import { getCurrentUserData } from "../../store/selectors/CurrentUser";
import { isEqualObjects } from "../../utils/common";
import { TCareerPageFormDefaultValue } from "../../models/CompanySettings";
import { addMessage } from "../../store/reducers/Snackbar";
import { isArraysEqual } from "../../utils";

const CareerPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [isBenefitsChanged, setIsBenefitsChanged] = useState<boolean>(false);
  const [benefits, setBenefits] = useState<string[]>([]);

  const isFormDataLoading = useSelector(getCompanySettingsIsFormDataLoading);
  const { company, employees, industries } = useSelector(
    getCompanySettingsCompanyState
  );
  const { companyUrlKey } = useSelector(getCurrentUserData);

  const isDataChaged = isFormChanged || isBenefitsChanged;

  const {
    control,
    register,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(getFormSettings(t)),
    defaultValues: formDefaultValues,
  });

  const careerData = useMemo(() => {
    const employeesDefaultValue = employees.find(
      (item) => item.label == company?.employees
    )?.value;
    const industryDefaultValue = industries.find(
      (item) => item.value == company?.industry_id
    )?.value;
    const currentCareerData = {
      about_us: company?.about_us,
      company_color: company?.company_color,
      employees: employeesDefaultValue,
      industry_id: industryDefaultValue,
      is_page_active: company?.is_page_active,
      website: company?.website,
    };
    return currentCareerData;
  }, [company, employees, industries]);

  const handleOnOpenUploadDialog = () => {
    dispatch(setIsUploadPictureDialogOpen(true));
  };

  const handleOnUpdateLogo = (imageFile?: File) => {
    dispatch(
      updateCompanyData({
        type: "logo",
        data: imageFile ? { logo: imageFile } : { reset_logo: true },
        callback: () => dispatch(fetchCompanyData()),
      })
    );
  };

  const handleOnPreviewCompany = () => {
    const companyPage = `${location.origin}/companies/${companyUrlKey}`;
    window.open(companyPage, "_blank");
  };

  const onSuccessUpdateCompanyData = () => {
    dispatch(fetchCompanyData());
    dispatch(
      addMessage({
        type: "success",
        title: t("company_settings.snackbar_title"),
      })
    );
  };

  const handleChangeBenefits = (newBenefits: string[]) => {
    setBenefits(newBenefits);
  };

  const handleOnSubmit = () => {
    const formValues = getValues();

    dispatch(
      updateCompanyData({
        type: "form",
        data: {
          ...formValues,
          employees_id: formValues.employees,
          color: formValues.company_color,
          benefits,
          reset_benefits: benefits.length ? undefined : true,
          reset_picture_one: formValues.picture_one ? undefined : true,
          reset_picture_two: formValues.picture_two ? undefined : true,
          reset_picture_three: formValues.picture_three ? undefined : true,
        },
        callback: onSuccessUpdateCompanyData,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchRegistrationData());
    if (!company) {
      dispatch(fetchCompanyData());
    }
  }, []);

  useEffect(() => {
    if (company) {
      const companyDataKeys = Object.keys(
        formDefaultValues
      ) as unknown as (keyof TCareerPageFormDefaultValue)[];
      companyDataKeys.forEach(
        (companyKey: keyof TCareerPageFormDefaultValue) => {
          if (companyKey === "employees") {
            const employeesDefaultValue = employees.find(
              (item) => item.label == company?.employees
            )?.value;
            setValue(companyKey, employeesDefaultValue as string);
          } else if (companyKey === "industry_id") {
            const industryDefaultValue = industries.find(
              (item) => item.value == company?.industry_id
            )?.value;
            setValue(companyKey, industryDefaultValue as string);
          } else {
            setValue(companyKey, company[companyKey]);
          }
        }
      );
    }
    if (company?.benefits) {
      setBenefits(company.benefits as string[]);
    }
  }, [company, industries, employees]);

  useEffect(() => {
    const subscription = watch((formValues) => {
      setIsFormChanged(!isEqualObjects(careerData, formValues));
    });
    return () => subscription.unsubscribe();
  }, [watch, careerData]);

  useEffect(() => {
    setIsFormChanged(false);
  }, [careerData]);

  useEffect(() => {
    if (company?.benefits) {
      const checkIsEqualBeneftis = isArraysEqual(
        company?.benefits as string[],
        benefits
      );
      setIsBenefitsChanged(!checkIsEqualBeneftis);
    }
  }, [benefits, company]);

  return (
    <CareerPageComponent
      t={t}
      control={control}
      employees={employees}
      errors={errors}
      industries={industries}
      logo={company?.logo}
      isFormDataLoading={isFormDataLoading}
      isFormChanged={isDataChaged}
      benefits={benefits}
      register={register}
      onChangeBenefits={handleChangeBenefits}
      onDeleteLogo={() => handleOnUpdateLogo()}
      onOpenUploadDialog={handleOnOpenUploadDialog}
      onPreview={handleOnPreviewCompany}
      onSubmit={handleOnSubmit}
    />
  );
};

export default CareerPage;
