import React from "react";
import { Box, InputLabel, Stack, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import AutocompleteFilter from "../../filters/AutocompleteFilter";
import { StyledSubSectionContainer, StyledOptionalLabel } from "../styles";
import {
  formatSalaryFieldValue,
  formatSalaryValue,
  removeNonNumeric,
} from "../../../utils";
import { IJobDetailsSalaryComponent } from "../../../models/JobEditor";

const SalarySection = ({
  t,
  control,
  salaryTypes,
  salaryCurrencies,
}: IJobDetailsSalaryComponent) => (
  <>
    <Stack direction="row" alignItems="center">
      <InputLabel> {t("create_job_second_step.salary")} </InputLabel>
      <StyledOptionalLabel mb="0px !important">Optional</StyledOptionalLabel>
    </Stack>
    <StyledSubSectionContainer direction={"row"} spacing={2}>
      <Stack sx={{ flex: 1 }}>
        <InputLabel>{t("create_job_second_step.minimum_salary")}</InputLabel>
        <Controller
          name="min_salary"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              placeholder={
                t("create_job_second_step.minimum_salary_placeholder") as string
              }
              fullWidth
              value={formatSalaryValue(value as unknown as number)}
              onChange={(e) =>
                onChange(
                  formatSalaryFieldValue(removeNonNumeric(e.target.value))
                )
              }
            />
          )}
        />
      </Stack>
      <Stack sx={{ flex: 1 }}>
        <InputLabel>{t("create_job_second_step.maximum_salary")}</InputLabel>
        <Controller
          name="max_salary"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              placeholder={
                t("create_job_second_step.maximum_salary_placeholder") as string
              }
              fullWidth
              value={formatSalaryValue(value as unknown as number)}
              onChange={(e) =>
                onChange(
                  formatSalaryFieldValue(removeNonNumeric(e.target.value))
                )
              }
            />
          )}
        />
      </Stack>
      <Box sx={{ flex: 1 }}>
        <InputLabel>
          {t("create_job_second_step.currency") as string}
        </InputLabel>
        <Controller
          name={"salary_currency"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <AutocompleteFilter
              onChange={onChange}
              value={value}
              options={salaryCurrencies}
              placeholder={t("create_job_second_step.currency")}
            />
          )}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <InputLabel>
          {t("create_job_second_step.interval") as string}
        </InputLabel>
        <Controller
          name={"salary_type"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <AutocompleteFilter
              onChange={onChange}
              value={value}
              options={salaryTypes}
              placeholder={t("create_job_second_step.interval")}
            />
          )}
        />
      </Box>
    </StyledSubSectionContainer>
  </>
);

export default SalarySection;
