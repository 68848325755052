import React from "react";
import { Typography, Stack, InputLabel, Chip, Tooltip } from "@mui/material";
import { lighten } from "polished";
import Media from "./Media";
import { StyledChipsContainer } from "../../components/ChipsManager/styles";
import { StyledAboutContainer } from "./style";
import { TCompanyInfoMedia } from "../../models/CompanyInfo";

const About = ({
  t,
  company,
  desktop,
  isShowMore,
  isShowMoreExist,
  shortText,
  fullText,
  onShowMoreLess,
}: TCompanyInfoMedia) => (
  <Stack
    direction={desktop ? "row" : "column"}
    pt={3}
    width={desktop ? "100%" : "90%"}
    margin="auto"
    maxWidth={desktop ? 1128 : "auto"}
    spacing={3}
  >
    <Stack flex={1} spacing={1.25} width="100%">
      {company?.about_us &&
        company?.about_us.replace(/<p>\s*(<br>)?\s*<\/p>/g, "") !== "" && (
          <Stack spacing={0.5} width="100%">
            <InputLabel sx={{ right: 4 }}>
              {t("company_settings.about_us")}
            </InputLabel>
            <StyledAboutContainer
              dangerouslySetInnerHTML={{
                __html: isShowMore ? fullText : shortText,
              }}
            />
            {isShowMoreExist && (
              <Typography
                display="inline"
                variant="subtitle2"
                color={company.color}
                whiteSpace="nowrap"
                sx={{ cursor: "pointer" }}
                onClick={onShowMoreLess}
              >
                {isShowMore
                  ? t("company_page.show_less")
                  : t("company_page.show_more")}
              </Typography>
            )}
          </Stack>
        )}
      {!!company.benefits.length && (
        <Stack width="100%">
          <InputLabel sx={{ right: 4 }}>Benefits</InputLabel>
          <StyledChipsContainer sx={{ border: "none" }}>
            {company.benefits.map((chip: string, index: number) => (
              <Tooltip title={chip} key={index}>
                <Chip
                  className="chips-manager"
                  size="medium"
                  label={chip}
                  sx={{
                    cursor: "default",
                    backgroundColor: `${lighten(0.4, company.color as string)} !important`,
                    color: `${company?.color} !important`,
                  }}
                />
              </Tooltip>
            ))}
          </StyledChipsContainer>
        </Stack>
      )}
    </Stack>
    {desktop &&
      (company.video_link ||
      company.picture_one ||
      company.picture_two ||
      company.picture_three ? (
        <Stack flex={1}>
          <Media company={company} />
        </Stack>
      ) : null)}
  </Stack>
);

export default About;
