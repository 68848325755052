import { styled } from "@mui/system";
import { Stack } from "@mui/material";

export const StyledKoQuestion = styled(Stack)({
  ".required-label": {
    textTransform: "capitalize",
    "&::after": {
      content: '"*"',
      position: "absolute",
      marginLeft: 4,
      color: "#EF4444",
      fontSize: 20,
    },
  },
});

export const StyledKOQuestionsContainer = styled(Stack)(
  ({ companyColor }: { companyColor?: string }) => ({
    ".input-selector-filter": {
      "& .MuiInputBase-input": {
        lineHeight: "40px",
        height: "40px !important",
      },
    },
    ".react-tel-input": {
      input: {
        "&:focus": {
          borderColor: `${companyColor} !important`,
          boxShadow: `0 0 3px 1.25px ${companyColor}`,
        },
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: `${companyColor} !important`,
        boxShadow: `0 0 3px 1.25px ${companyColor}`,
      },
    },
    ".check-status-button": {
      background: companyColor,
      "&:hover": {
        background: companyColor,
        opacity: 0.8,
      },
    },
    ".submit-button": {
      background: companyColor,
      "&:hover": {
        background: companyColor,
        opacity: 0.8,
      },
    },
    ".skip-button": {
      color: companyColor,
      borderColor: `${companyColor} !important`,
    },
    ".Mui-checked": {
      color: `${companyColor} !important`,
    },
    ".company-logo": {
      objectFit: "contain",
    },
  })
);

export const StyledKOQuestionsFieldContainer = styled(Stack)(
  ({ companyColor }: { companyColor?: string }) => ({
    ".input-field": {
      width: "100%",
      ".MuiAutocomplete-tag": {
        background: companyColor,
        color: companyColor ? "#fff" : "#7F7F7F",
        border: companyColor ? "#fff" : "#7F7F7F",
        "&:hover": {
          opacity: 0.8,
        },
      },
      ".skills-autocomplete": {
        ".MuiAutocomplete-input": {
          position: "relative",
          width: "100%",
        },
      },
      "& .Mui-checked": {
        color: `${companyColor} !important`,
      },
      "& .MuiRadio-root": {
        color: companyColor,
      },
    },
    ".input-selector-filter": {
      width: "100%",
      textDecoration: "none",
      textTransform: "none",
      "[data-testid=CloseIcon]": {
        cursor: "pointer",
        width: 18,
        height: 18,
        marginRight: 8,
      },
    },
  })
);

export const StyledKoQuestionContainer = styled(Stack)(
  ({ companyColor }: { companyColor?: string }) => ({
    ".MuiChip-deleteIcon": {
      background: "transparent !important",
      fill: `#fff !important`,
      stroke: "#fff !important",
    },
    ".MuiAutocomplete-tag": {
      background: `${companyColor} !important`,
      color: companyColor ? "#fff !important" : "#7F7F7F !important",
      border: companyColor ? "#fff" : "#7F7F7F",
      "&:hover": {
        opacity: 0.8,
      },
    },
    ".required-label": {
      textTransform: "capitalize",
      "&::after": {
        content: '"*"',
        position: "absolute",
        marginLeft: 4,
        color: "#EF4444",
        fontSize: 20,
      },
    },
  })
);

export const StyledLanguagesContainer = styled(Stack)({
  ".input-selector-filter": {
    width: "100%",
    textDecoration: "none",
    textTransform: "none",
    "& .MuiInputBase-input": {
      width: 60,
      lineHeight: "40px",
      height: "40px !important",
    },
    "[data-testid=CloseIcon]": {
      cursor: "pointer",
      width: 18,
      height: 18,
      marginRight: 8,
    },
  },
});
