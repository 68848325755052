import React from "react";
import {
  Divider,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { IJobDocumentsSection } from "../../../models/JobEditor";
import { StyledBasicQuestionsContainer } from "../styles";

const JobDocumentsSection = ({ t, control }: IJobDocumentsSection) => (
  <>
    <Typography variant="body2">
      {t("create_job_third_step.documents")}
    </Typography>
    <StyledBasicQuestionsContainer spacing={1}>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2" color="textSecondary">
            CV
          </Typography>
          <Stack width={126} alignItems="flex-start">
            <Controller
              name="cv_required"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  row
                  value={value}
                  onChange={(_, newValue) => onChange(newValue)}
                >
                  <Tooltip title={t("create_job_third_step.ko_question")}>
                    <Radio
                      value="0"
                      color="secondary"
                      sx={{
                        "&:hover": {
                          backgroundColor: "#9f9d9d29 !important",
                        },
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={t("status.active")}>
                    <Radio
                      value="1"
                      sx={{
                        color: "#22C55E !important",
                        "&.Mui-checked": { color: "#22C55E !important" },
                        "&:hover": {
                          backgroundColor: "#9f9d9d29 !important",
                        },
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={t("status.inactive")}>
                    <Radio
                      value="2"
                      sx={{
                        color: "#EF4444 !important",
                        "&.Mui-checked": { color: "#EF4444 !important" },
                        "&:hover": {
                          backgroundColor: "#9f9d9d29 !important",
                        },
                      }}
                    />
                  </Tooltip>
                </RadioGroup>
              )}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2" color="textSecondary">
            {t("create_job_third_step.additional_documents")}
          </Typography>
          <Controller
            name={"other_documents_required"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                row
                value={value}
                onChange={(_, newValue) => onChange(newValue)}
              >
                <Tooltip title={t("create_job_third_step.ko_question")}>
                  <Radio
                    value="0"
                    color="secondary"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#9f9d9d29 !important",
                      },
                    }}
                  />
                </Tooltip>
                <Tooltip title={t("status.active")}>
                  <Radio
                    value="1"
                    sx={{
                      color: "#22C55E !important",
                      "&.Mui-checked": { color: "#22C55E !important" },
                      "&:hover": {
                        backgroundColor: "#9f9d9d29 !important",
                      },
                    }}
                  />
                </Tooltip>
                <Tooltip title={t("status.inactive")}>
                  <Radio
                    value="2"
                    sx={{
                      color: "#EF4444 !important",
                      "&.Mui-checked": { color: "#EF4444 !important" },
                      "&:hover": {
                        backgroundColor: "#9f9d9d29 !important",
                      },
                    }}
                  />
                </Tooltip>
              </RadioGroup>
            )}
          />
        </Stack>
      </Stack>
    </StyledBasicQuestionsContainer>
  </>
);

export default JobDocumentsSection;
