import React from "react";
import { Button, Chip, Stack, TextField, Tooltip } from "@mui/material";
import { IChipsManagerComponent } from "../../models/common";
import { StyledChipsContainer, StyledChipsManagerContainer } from "./styles";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ChipsManagerComponent = ({
  chip,
  chipList,
  placeholder,
  showListAlways = false,
  errorMessage,
  listHeight,
  onAdd,
  onDelete,
  onChange,
}: IChipsManagerComponent) => (
  <StyledChipsManagerContainer spacing={2}>
    <Stack direction={"row"} spacing={2}>
      <TextField
        value={chip}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        error={Boolean(errorMessage)}
        placeholder={placeholder as string}
        helperText={errorMessage}
        fullWidth
      />
      <Button
        sx={{ fontWeight: 300 }}
        variant="outlined"
        onClick={onAdd}
        disabled={!chip.trim().length}
      >
        +
      </Button>
    </Stack>
    {showListAlways ? (
      <StyledChipsContainer height={listHeight ?? "auto"}>
        {chipList?.map((chip: string, index: number) => (
          <Tooltip key={chip + index} title={chip}>
            <Chip
              deleteIcon={<XMarkIcon width={16} height={16} stroke="#178CF2" />}
              className="chips-manager"
              size="medium"
              key={chip + index}
              label={chip}
              onDelete={() => onDelete(index)}
            />
          </Tooltip>
        ))}
      </StyledChipsContainer>
    ) : chipList?.length ? (
      <StyledChipsContainer>
        {chipList.map((chip: string, index: number) => (
          <Tooltip key={chip + index} title={chip}>
            <Chip
              deleteIcon={<XMarkIcon width={16} height={16} stroke="#178CF2" />}
              className="chips-manager"
              size="medium"
              label={chip}
              onDelete={() => onDelete(index)}
            />
          </Tooltip>
        ))}
      </StyledChipsContainer>
    ) : null}
  </StyledChipsManagerContainer>
);

export default ChipsManagerComponent;
