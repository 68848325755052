import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/de";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Languages from "../../../AdditionalQuestions/components/Languages";
import Skills from "../../../AdditionalQuestions/components/Skills";
import AdditionalQuestionsDriverLicenses from "../../../AdditionalQuestions/components/DriverLicenses";
import AdditionalQuestionsQuestions from "../../../AdditionalQuestions/components/Questions";
import { TAdditionalQuestionsFormFields } from "../../../../models/JobPreview";
import { IApplicationAdditionalInfoComponent } from "../../../../models/ApplicationPage";
import {
  formatSalaryFieldValue,
  formatSalaryValue,
  removeNonNumeric,
} from "../../../../utils";

const AdditionalInfoEditModalComponent = ({
  t,
  control,
  isOpen,
  isLoading,
  locale,
  register,
  handleSubmit,
  onSubmit,
  onClose,
}: IApplicationAdditionalInfoComponent) => (
  <Dialog onClose={onClose} open={isOpen} fullWidth>
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>{t("candidate_questions.editAdditionalInfo")}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Box>
            <InputLabel>{t("candidate_questions.eu_permit")}</InputLabel>
            <Controller
              name="working_permit_eu"
              control={control}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  row
                  value={value}
                  onChange={(_, newValue) => onChange(newValue)}
                >
                  <Tooltip title={t("yes")}>
                    <Radio
                      value="1"
                      sx={{
                        color: "#22C55E !important",
                        "&.Mui-checked": { color: "#22C55E !important" },
                        "&:hover": {
                          backgroundColor: "#9f9d9d29 !important",
                        },
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={t("no")}>
                    <Radio
                      value="2"
                      sx={{
                        color: "#EF4444 !important",
                        "&.Mui-checked": { color: "#EF4444 !important" },
                        "&:hover": {
                          backgroundColor: "#9f9d9d29 !important",
                        },
                      }}
                    />
                  </Tooltip>
                </RadioGroup>
              )}
            />
          </Box>
          <Box>
            <InputLabel>{t("candidate_questions.highest_degree")}</InputLabel>
            <TextField
              {...register("highest_degree")}
              placeholder={t("candidate_questions.highest_degree") as string}
              fullWidth
            />
          </Box>
          <Box>
            <InputLabel>
              {t("candidate_questions.current_professional_status")}
            </InputLabel>
            <TextField
              {...register("current_professional_status")}
              placeholder={
                t("candidate_questions.current_professional_status") as string
              }
              fullWidth
            />
          </Box>
          <Box>
            <InputLabel>
              {t("candidate_questions.earliest_start_date")}
            </InputLabel>
            <Controller
              name={"earliest_start_date"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box className={"input-field"}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                  >
                    <DatePicker
                      disablePast
                      sx={{ width: "100%" }}
                      format="DD.MM.YYYY"
                      value={
                        value && value != "0000-00-00 00:00:00"
                          ? dayjs(value)
                          : null
                      }
                      onChange={onChange}
                    />
                  </LocalizationProvider>
                </Box>
              )}
            />
          </Box>
          <Box>
            <InputLabel>
              {t("candidate_questions.salary_expectation")}
            </InputLabel>
            <Controller
              name="salary_expectation"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  placeholder={
                    t("candidate_questions.salary_expectation") as string
                  }
                  fullWidth
                  value={formatSalaryValue(value as unknown as number)}
                  onChange={(e) =>
                    onChange(
                      formatSalaryFieldValue(removeNonNumeric(e.target.value))
                    )
                  }
                />
              )}
            />
          </Box>
          <Box>
            <AdditionalQuestionsDriverLicenses
              boldHeading
              control={
                control as unknown as Control<TAdditionalQuestionsFormFields>
              }
              desktop={false}
              companyColor={"#178CF2"}
            />
          </Box>
          <Box>
            <Languages
              boldHeading
              control={
                control as unknown as Control<TAdditionalQuestionsFormFields>
              }
              desktop={false}
              isEditDialog
              companyColor={"#178CF2"}
            />
          </Box>
          <Box>
            <Skills
              boldHeading
              control={
                control as unknown as Control<TAdditionalQuestionsFormFields>
              }
              desktop={false}
              companyColor={"#178CF2"}
            />
          </Box>
          <Box>
            <AdditionalQuestionsQuestions
              boldHeading
              control={
                control as unknown as Control<TAdditionalQuestionsFormFields>
              }
              desktop={false}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button variant={"outlined"} onClick={onClose} disabled={isLoading}>
          {t("button.secondCancel")}
        </Button>
        <Button
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          variant={"contained"}
          disabled={isLoading}
          type={"submit"}
        >
          {t("button.save")}
        </Button>
      </DialogActions>
    </form>
  </Dialog>
);

export default AdditionalInfoEditModalComponent;
