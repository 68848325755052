import React from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { IconButton, Stack, Typography } from "@mui/material";
import { TStyledSectionTitle } from "../../models/common";

const StyledSectionTitle = ({
  title,
  onClick,
  onClose,
}: TStyledSectionTitle) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Typography fontSize={18} color={"#0F141B"} fontWeight={500}>
      {title}
    </Typography>
    {onClick && (
      <IconButton onClick={onClick}>
        <PencilSquareIcon width={22} height={22} stroke="#178CF2" />
      </IconButton>
    )}
    {onClose && (
      <IconButton onClick={onClose}>
        <PencilSquareIcon width={22} height={22} />
      </IconButton>
    )}
  </Stack>
);

export default StyledSectionTitle;
