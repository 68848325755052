import * as yup from "yup";
import { TFunction } from "i18next";
import { TJobActionKOQuestionFormFields } from "../../../models/JobPreview";

export const jobActionKOQuestionFormFields = ({
  t,
  currentJobRequired,
  driverLicenseRequired,
  earliestStartDateRequired,
  highestDegreeRequired,
  langsRequired,
  locationRequired,
  otherDocumentsRequired,
  phoneRequired,
  salaryExpectationRequired,
  skillsRequired,
}: {
  t: TFunction;
  currentJobRequired: boolean;
  driverLicenseRequired: boolean;
  earliestStartDateRequired: boolean;
  highestDegreeRequired: boolean;
  langsRequired: boolean;
  locationRequired: boolean;
  otherDocumentsRequired: boolean;
  phoneRequired: boolean;
  salaryExpectationRequired: boolean;
  skillsRequired: boolean;
}) => {
  return yup.object().shape({
    currentJob: currentJobRequired
      ? yup.string().required(t("register.message") as string)
      : yup.string(),
    driverLicense: driverLicenseRequired
      ? yup
          .array()
          .of(
            yup.object().shape({
              label: yup.string(),
              value: yup.string(),
            })
          )
          .nullable()
          .transform((val) => (!val ? [] : val))
          .min(1, t("register.message") as string)
      : yup
          .array()
          .of(
            yup.object().shape({
              label: yup.string(),
              value: yup.string(),
            })
          )
          .nullable()
          .transform((val) => (!val ? [] : val)),
    earliestStartDate: earliestStartDateRequired
      ? yup.string().required(t("register.message") as string)
      : yup.string(),
    highestDegree: highestDegreeRequired
      ? yup.string().required(t("register.message") as string)
      : yup.string(),
    languages: langsRequired
      ? yup
          .array()
          .of(
            yup.object().shape({
              language: yup.string().required(t("register.message") as string),
              level: yup.string().required(t("register.message") as string),
            })
          )
          .nullable()
          .min(1, t("register.message") as string)
      : yup
          .array()
          .of(
            yup.object().shape({
              name: yup.string().nullable(),
              level: yup.string().nullable(),
            })
          )
          .nullable(),
    location: locationRequired
      ? yup
          .object()
          .required(t("register.message") as string)
          .typeError(t("register.message") as string)
      : yup.object().nullable(),
    workingPermitEU: yup.string(),
    otherDocuments: yup
      .array()
      .of(yup.mixed<File>().nullable())
      .nullable()
      .test(
        "at-least-one-document",
        t("requiredDocument") as string,
        function (value) {
          if (otherDocumentsRequired) {
            return value ? value.some((file) => file instanceof File) : false;
          }
          return true;
        }
      ),
    phone: phoneRequired
      ? yup.string().required(t("register.message") as string)
      : yup.string(),
    salaryExpectation: salaryExpectationRequired
      ? yup.string().required(t("register.message") as string)
      : yup.string(),
    skills: skillsRequired
      ? yup
          .array()
          .of(
            yup.object().shape({
              label: yup.string(),
              value: yup.string(),
            })
          )
          .nullable()
          .transform((val) => (!val ? [] : val))
          .min(1, t("register.message") as string)
      : yup
          .array()
          .of(
            yup.object().shape({
              label: yup.string(),
              value: yup.string(),
            })
          )
          .nullable()
          .transform((val) => (!val ? [] : val)),
    agreement: yup.bool().oneOf([true], t("accept_terms_text") as string),
  });
};

export const jobActionKoQuestionDefaultValues: TJobActionKOQuestionFormFields =
  {
    currentJob: "",
    driverLicense: [],
    earliestStartDate: "",
    highestDegree: "",
    languages: [{ language: "", level: "" }],
    location: null,
    workingPermitEU: "1",
    otherDocuments: [],
    phone: "",
    salaryExpectation: "",
    skills: [],
    agreement: false,
  };
