import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../config";

export const getCurrentUserState = (state: RootState) => state.currentUser;

export const getCurrentUserLoadingState = createSelector(
  [getCurrentUserState],
  (currentUser) => currentUser.isLoading
);

export const getCurrentUserEmailLoadingState = createSelector(
  [getCurrentUserState],
  (currentUser) => currentUser.isEmailLoading
);

export const getCurrentUserNavigationState = createSelector(
  [getCurrentUserState],
  (currentUser) => currentUser.navigation
);

export const getCurrentUserUpdateLoadingState = createSelector(
  [getCurrentUserState],
  (currentUser) => currentUser.isUpdateLoading
);

export const getCurrentUserIsCandidate = createSelector(
  [getCurrentUserState],
  (currentUser) => currentUser.isCandidate
);

export const getCurrentUserData = createSelector(
  [getCurrentUserState],
  (currentUser) => currentUser.userData
);

export const getCurrentUserJassId = createSelector(
  [getCurrentUserState],
  (currentUser) => currentUser.jassId
);

export const getCurrentUserToken = createSelector(
  [getCurrentUserState],
  (currentUser) => currentUser.token
);

export const getStatuses = createSelector(
  [getCurrentUserState],
  (currentUser) => currentUser.statuses
);

export const getActiveProduct = createSelector(
  [getCurrentUserState],
  (currentUser) => currentUser.activeProduct
);
