import {
  TApiDeleteMessage,
  TApiGetMessages,
  TApiPostMessage,
  TApiUpdateMessage,
} from "../models/Messages";
import { apiRequest } from "./config";

export const apiGetLatestMessages = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: {
    filter: string;
  };
}) => apiRequest({ type: "post", url, apiPayload });

export const apiGetMessages = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiGetMessages;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiAddMessage = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiPostMessage;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiUpdateMessage = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiUpdateMessage;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiDeleteMessage = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: TApiDeleteMessage;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiCompanyReadUnreadMessage = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: any;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiCandidateReadUnreadMessage = ({
  url,
  apiPayload,
}: {
  url: string;
  apiPayload: any;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiRefresh = ({ url }: { url: string }) =>
  apiRequest({ type: "post", url });
