import React, { useState, useEffect, useMemo } from "react";
import { createFilterOptions } from "@mui/material";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import KOQuestionComponent from "./component";
import { jobActionKOQuestionFormFields } from "./config";
import { getJobPreviewState } from "../../../store/selectors/JobPreview";
import { applyToJob } from "../../../store/reducers/JobPreview";
import {
  TJobActionKOQuestionFormFields,
  TJobActionRegisterFormFields,
} from "../../../models/JobPreview";
import { jobActionKoQuestionDefaultValues } from "./config";
import { TListOption } from "../../../models/common";
import { getCurrentUserData } from "../../../store/selectors/CurrentUser";

const KOQuestionForm = ({
  companyColor,
}: {
  readonly companyColor: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { key: jobUrlKey } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const { isApplyInProgress, jobData, jobTemplate, applyData } =
    useSelector(getJobPreviewState);
  const { firstName } = useSelector(getCurrentUserData);

  const utm_source = searchParams.get("utm_source") || "";

  const [isVisible, setIsVisible] = useState(false);
  const [locale, setLocale] = useState("de");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(
      jobActionKOQuestionFormFields({
        t,
        currentJobRequired: jobData.job.current_job_required,
        driverLicenseRequired: jobData.job.drivers_license_required,
        earliestStartDateRequired: jobData.job.earliest_start_date_required,
        highestDegreeRequired: jobData.job.highest_degree_required,
        langsRequired: jobData.job.langs_required,
        locationRequired: jobData.job.location_required,
        otherDocumentsRequired: jobData.job.other_documents_required,
        phoneRequired: jobData.job.phone_required,
        salaryExpectationRequired: jobData.job.salary_expectation_required,
        skillsRequired: jobData.job.skills_required,
      })
    ),
    defaultValues: jobActionKoQuestionDefaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "languages",
  });

  const showCompanyAgreementText =
    jobData && jobData?.pp_link?.length && jobData?.tc_link?.length;

  const containerStyle = {
    opacity: isVisible ? 1 : 0,
    transition: "opacity 0.5s ease-in-out",
  };

  const modifiedDriverLicenses: TListOption[] = useMemo(() => {
    if (jobData.driversLicenses)
      return jobData.driversLicenses.map(
        (dl: { id: string; name: string }) => ({
          value: dl.id,
          label: dl.name,
        })
      );
    return [];
  }, [jobData]);

  const modifiedSkills: TListOption[] = useMemo(() => {
    if (jobData.skills)
      return jobData.skills.map((dl: { id: string; title: string }) => ({
        value: dl.id,
        label: dl.title,
      }));
    return [];
  }, [jobData]);

  const { modifiedLanguages, modifiedLanguageLevels } = useMemo(() => {
    let modifiedLanguages;
    let modifiedLanguageLevels;
    if (jobData.languages)
      modifiedLanguages = jobData.languages.map(
        (lang: { id: string; name: string }) => ({
          label: lang.name,
          value: lang.name,
        })
      );
    if (jobData.languageLevels)
      modifiedLanguageLevels = jobData.languageLevels.map(
        (lang: { id: string; name: string }) => ({
          label: lang.name,
          value: lang.name,
        })
      );

    return { modifiedLanguages, modifiedLanguageLevels };
  }, [jobData]);

  const filterOptions = createFilterOptions({
    stringify: (option: TListOption) => option.label,
    ignoreCase: true,
    matchFrom: "any",
    limit: 10,
  });

  const handleAddNewLanguage = () => {
    append({ language: "", level: "" });
  };

  const onSuccessApplyToJob = (candidateUrlKey: string, jobUrlKey: string) => {
    sessionStorage.setItem("jobUrlKey", jobUrlKey);
    sessionStorage.setItem("candidateUrlKey", candidateUrlKey);
    if (firstName) {
      navigate(`/candidate/login/additional-questions/${jobUrlKey}`);
    } else {
      navigate("/successful-application");
    }
  };

  const onSubmit = (formFields: TJobActionKOQuestionFormFields) => {
    const body = {
      ...applyData,
      ...(utm_source && { utm_source }),
    };

    const formattedData = {
      ...body,
      ...formFields,
      country: formFields.location?.countrycode as string,
      city: formFields.location?.name as string,
      salary: formFields.salaryExpectation.replace(".", ""),
      professionalStatus: formFields.currentJob as string,
      education: formFields.highestDegree as string,
      photo: null as any,
      questions: [],
      working_permit_eu: jobData.job.working_permit_eu_required
        ? formFields.workingPermitEU == "1"
          ? 1
          : false
        : null,
      files: applyData?.cv ? [applyData?.cv] : undefined,
    };
    if (jobUrlKey)
      dispatch(
        applyToJob({
          jobUrlKey,
          body: formattedData as TJobActionRegisterFormFields,
          callback: ({
            candidateUrlKey,
            jobUrlKey,
          }: {
            candidateUrlKey: string;
            jobUrlKey: string;
          }) => onSuccessApplyToJob(candidateUrlKey, jobUrlKey),
        })
      );
  };

  useEffect(() => {
    const language = localStorage.getItem("lang") || "de_DE";
    if (language === "en_US") {
      setLocale("en");
    }
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <KOQuestionComponent
      t={t}
      control={control}
      errors={errors}
      jobData={jobData}
      jobTemplate={jobTemplate}
      isVisible={isVisible}
      containerStyle={containerStyle}
      isApplyInProgress={isApplyInProgress}
      companyColor={companyColor}
      fields={fields}
      driverLicenses={modifiedDriverLicenses}
      skills={modifiedSkills}
      languages={modifiedLanguages}
      levels={modifiedLanguageLevels}
      showCompanyAgreementText={showCompanyAgreementText}
      locale={locale}
      onAddLanguage={handleAddNewLanguage}
      onRemoveLanguage={remove}
      filterOptions={filterOptions}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    />
  );
};

export default KOQuestionForm;
