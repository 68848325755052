import {
  TApiUpdateCurrentUserData,
  TApiUpdateCurrentUserPassword,
  TApiUpdateCurrentUserPhoto,
} from "../models/CurrentUser";
import { apiRequest } from "./config";

export const apiUpdateCurrentUserData = ({
  url,
  apiPayload,
}: {
  readonly url: string;
  readonly apiPayload: TApiUpdateCurrentUserData;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiUpdateCurrentUserPhoto = ({
  url,
  apiPayload,
}: {
  readonly url: string;
  readonly apiPayload: TApiUpdateCurrentUserPhoto;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiUpdateCurrentUserPassword = ({
  url,
  apiPayload,
}: {
  readonly url: string;
  readonly apiPayload: TApiUpdateCurrentUserPassword;
}) => apiRequest({ type: "post", url, apiPayload });

export const apiAuthGmail = () =>
  apiRequest({
    type: "post",
    url: "/company/gmail/auth",
  });

export const apiConfirmGmail = ({
  apiPayload,
}: {
  readonly apiPayload: {
    code: string;
  };
}) =>
  apiRequest({
    type: "post",
    url: "/company/gmail/confirm",
    apiPayload,
  });

export const apiDisconnectGmail = () =>
  apiRequest({
    type: "post",
    url: "/company/gmail/revoke",
  });

export const apiAuthOutlook = () =>
  apiRequest({
    type: "post",
    url: "/company/microsoft/auth",
  });

export const apiConfirmOutlook = ({
  apiPayload,
}: {
  readonly apiPayload: {
    code: string;
  };
}) =>
  apiRequest({
    type: "post",
    url: "/company/microsoft/confirm",
    apiPayload,
  });

export const apiDisconnectOutlook = () =>
  apiRequest({
    type: "post",
    url: "/company/microsoft/revoke",
  });
