import * as yup from "yup";
import { TFunction } from "i18next";
import { TJobActionLoggedCandidateFormFields } from "../../../models/JobPreview";

export const jobActionLoggedCandidateFormFields = (
  t: TFunction,
  isCvRequired: boolean
) => {
  return yup.object().shape({
    cv: isCvRequired
      ? yup.mixed().required(t("register.message") as string)
      : yup.mixed().nullable(),
  });
};

export const jobActionLoggedCandidateFormDefaultValues: TJobActionLoggedCandidateFormFields =
  {
    cv: null,
  };
