import { TFunction } from "i18next";
import { DIALOG_TYPE } from "./common";

export interface ICustomersState {
  readonly filters: TCustomersFilters;
  readonly customers: TCustomers;
  readonly selectedCustomer?: TSelectedCustomer;
  readonly allActiveCustomers: TCustomers;
  readonly error: unknown;
  readonly isLoading: boolean;
  readonly isListLoading: boolean;
  readonly jassCustomers: TJassCustomer[];
  readonly selectedImportCustomers: TJassCustomer[];
}

export interface TJassCustomer {
  readonly firstname: string;
  readonly lastname: string;
  readonly phone: string;
  readonly company: string;
  readonly company_logo?: string | null;
  readonly email: string;
  readonly website?: string | null;
  readonly plz: string;
  readonly city: string;
  readonly country: string;
  readonly street: string;
  readonly street_number: string;
}

export type TCustomers = {
  readonly list: TCustomer[];
};

export type TSelectedCustomer = {
  readonly city: string;
  readonly connection_date: string;
  readonly contact_email: string;
  readonly email: string;
  readonly contact_firstname: string;
  readonly contact_lastname: string;
  readonly contact_phone: string;
  readonly country: string;
  readonly name: string;
  readonly agency_name: string;
  readonly plz: string;
  readonly status: string;
  readonly street: string;
  readonly street_number: string;
};

export interface TCustomer {
  readonly companyLogo: string;
  readonly companyName: string;
  readonly country: string;
  readonly email: string;
  readonly id: string | number;
  readonly is_active: boolean;
  readonly jass_id?: string;
  readonly location: string;
  readonly name: string;
  readonly is_agency_managed: number;
}

export type TCustomersFilters = {
  readonly quickSearch: string;
  readonly location: string;
};

export interface ICustomerComponent {
  readonly t: TFunction;
  readonly isLoading: boolean;
  readonly isListLoading: boolean;
  readonly list: TCustomer[];
  readonly openedDialogType: DIALOG_TYPE | null;
  readonly emailsToInvite: string[];
  readonly jassId?: string | number;
  readonly quickSearch: string;
  readonly location: string;
  readonly jassCustomers: TJassCustomer[];
  readonly onChangeFilters: (data: {
    readonly value: string;
    readonly filterType: keyof TCustomersFilters;
  }) => void;
  readonly selectedImportCustomers: TJassCustomer[];
  readonly onSelectCustomer: (id: string) => void;
  readonly onCloseDialog: () => void;
  readonly onEmailsChange: (emails: string[]) => void;
  readonly onOpenInviteDialog: () => void;
  readonly onOpenImportCustomersDialog: () => void;
  readonly onInvite: () => void;
  readonly onImportCustomers: () => void;
  readonly onSelectDescelectCustomer: (val: TJassCustomer) => void;
  readonly onLogInAsCustomer: (val: TCustomer) => void;
}

export interface IFiltersAndButtonsComponent {
  readonly t: TFunction;
  readonly jassId?: string | number;
  readonly quickSearch: string;
  readonly isLoading: boolean;
  readonly location: string;
  readonly onChangeFilters: (data: {
    readonly value: string;
    readonly filterType: keyof TCustomersFilters;
  }) => void;
  readonly onImportCustomers: () => void;
  readonly onOpenDialog: () => void;
}

export interface INoCustomers {
  readonly t: TFunction;
  readonly jassId?: string | number;
  readonly onOpenDialog: () => void;
  readonly onOpenImportCustomersDialog: () => void;
}

export interface IConnectAgencyHeader {
  readonly t: TFunction;
  readonly agencyName: string | null;
  readonly connectionDate: string | null;
  readonly openDisconnectDialog: () => void;
}

export enum CUSTOMER_STATUSES {
  ONLINE = "online",
  OFFLINE = "offline",
}
