import React from "react";
import { Stack, Dialog, IconButton } from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { IFullScreenImageDialog } from "../../../models/dialogs";

const FullScreenImageDialog = ({
  isOpen,
  selectedImage,
  onClose,
}: IFullScreenImageDialog) => (
  <Dialog open={isOpen} onClose={onClose} maxWidth="md">
    <Stack position="relative">
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          width: 24,
          height: 24,
          padding: 0,
          top: 8,
          right: 8,
          backgroundColor: "grey",
          "&:hover": {
            backgroundColor: "lightgrey",
          },
        }}
      >
        <XMarkIcon width={16} height={16} stroke="#fff" />
      </IconButton>
      <img src={selectedImage as string} width="100%" height="auto" />
    </Stack>
  </Dialog>
);

export default FullScreenImageDialog;
