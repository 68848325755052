import { Box, IconButton, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledJobDialogFormSection = styled(Stack)({
  padding: 24,
  backgroundColor: "#fff",
  borderRadius: "6px",
});

export const StyledLocationsRowFieldsContainer = styled(Stack)({
  position: "relative",
  alignItems: "flex-start",
  paddingTop: 8,
  gap: 16,
  svg: {
    width: 24,
    height: 24,
    cursor: "pointer",
    "&.add-icon-button": {
      stroke: "#178CF2",
    },
    "&.remove-icon-button": {
      stroke: "#EF4444",
    },
  },
});

export const StyledSubSectionContainer = styled(Stack)({
  background: "#F9FAFB",
  borderRadius: 12,
  padding: 16,
  marginTop: 12,
  ".MuiFormControl-root": {
    width: "100%",
  },
});

export const StyledBasicQuestionsContainer = styled(Stack)({
  marginTop: 10,
  padding: "8px 24px",
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  button: {
    alignSelf: "center",
    maxWidth: "max-content",
  },
  hr: {
    borderColor: "#E5E7EB",
  },
});

export const StyledColorPickersContainer = styled(Stack)({
  height: 96,
  margin: "8px 0",
  justifyContent: "space-between",
});

export const StyledPreviewButtonContainer = styled(Stack)({
  position: "fixed",
  marginTop: 90,
  marginRight: -50,
  width: 40,
  height: 40,
  borderRadius: 1.5,
  justifyContent: "center",
  alignItems: "center",
});

export const StyledPreviewButton = styled(IconButton)({
  width: 40,
  height: 40,
  minWidth: 40,
  background: "#178CF2",
  borderRadius: 6,
  "&:hover": {
    background: "#178CF2",
  },
  svg: {
    width: 24,
    height: 24,
    strokeWidth: 1.5,
    stroke: "#fff",
  },
  "&.Mui-disabled": {
    background: "grey",
    pointerEvents: "none",
  },
});

export const StyledOptionalLabel = styled(Box)(
  ({ companyColor }: { companyColor?: string }) => ({
    marginLeft: 10,
    marginBottom: 4,
    padding: "0px 8px 0px 8px",
    backgroundColor: companyColor ?? "#EEF2FF",
    fontSize: 12,
    color: companyColor ? "#fff" : "#818CF8",
    borderRadius: "100px",
    width: 61,
    height: 20,
    justifyContent: "center",
  })
);

export const StyledRadioButtonContainer = styled(Stack)(
  ({ selected }: { selected?: boolean }) => ({
    background: selected ? "#EEF2FF" : "#FFF",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "center",
    border: "1px solid #E5E7EB",
    borderRadius: 6,
    padding: "0 12px",
    height: 40,
    cursor: "pointer",
    ".MuiFormControlLabel-label": {
      fontSize: "14px!important",
      color: "#6B7280",
    },
  })
);
