import React, { useRef } from "react";
import { Button, CircularProgress, Dialog } from "@mui/material";
import { TFunction } from "i18next";
import { useSelector } from "react-redux";
import JobApplicationProcessForm from "../../../../components/JobDialogs/JobApplicationProcess";
import { TJobBasicQuestionsFormFields } from "../../../../models/JobEditor";
import { SELECTED_EDIT_SECTIONS } from "../../../../models/SingleJob";
import { getJobEditorState } from "../../../../store/selectors/JobEditor";
import { getSingleJobState } from "../../../../store/selectors/SingleJob";
import { StyledDialogActions } from "../../styles";

const JobBasicQuestionsEditDialog = ({
  t,
  isOpen,
  onClose,
  onUpdateJob,
}: {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
  onUpdateJob: (data: TJobBasicQuestionsFormFields) => void;
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { jobDetails } = useSelector(getSingleJobState);
  const { isLoading } = useSelector(getJobEditorState);
  const {
    phone_required,
    phone_show,
    location_required,
    location_show,
    working_permit_eu_required,
    working_permit_eu_show,
    salary_expectation_required,
    salary_expectation_show,
    earliest_start_date_required,
    earliest_start_date_show,
    drivers_license_required,
    drivers_license_show,
    current_job_required,
    current_job_show,
    highest_degree_required,
    highest_degree_show,
    langs_required,
    langs_show,
    skills_required,
    skills_show,
  } = jobDetails || {};

  const formattedDefaultValues = {
    phone_required: phone_required ? "0" : phone_show ? "1" : "2",
    location_required: location_required ? "0" : location_show ? "1" : "2",
    working_permit_eu_required: working_permit_eu_required
      ? "0"
      : working_permit_eu_show
        ? "1"
        : "2",
    salary_expectation_required: salary_expectation_required
      ? "0"
      : salary_expectation_show
        ? "1"
        : "2",
    earliest_start_date_required: earliest_start_date_required
      ? "0"
      : earliest_start_date_show
        ? "1"
        : "2",
    drivers_license_required: drivers_license_required
      ? "0"
      : drivers_license_show
        ? "1"
        : "2",
    current_job_required: current_job_required
      ? "0"
      : current_job_show
        ? "1"
        : "2",
    highest_degree_required: highest_degree_required
      ? "0"
      : highest_degree_show
        ? "1"
        : "2",
    langs_required: langs_required ? "0" : langs_show ? "1" : "2",
    skills_required: skills_required ? "0" : skills_show ? "1" : "2",
  };

  const handleOnSubmit = (data: TJobBasicQuestionsFormFields) => {
    onUpdateJob(data);
  };

  return (
    <Dialog open={isOpen} maxWidth={"md"} fullWidth onClose={onClose}>
      <JobApplicationProcessForm
        t={t}
        defaultValues={formattedDefaultValues}
        selectedSections={[
          SELECTED_EDIT_SECTIONS.JOB_APPLICATION_PROCESS_BASIC_QUESTIONS,
        ]}
        formRef={formRef}
        onSubmit={handleOnSubmit}
      />
      <StyledDialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>
          {t("button.secondCancel")}
        </Button>
        <Button
          variant="contained"
          type="submit"
          endIcon={
            isLoading ? (
              <CircularProgress
                size={16}
                style={{
                  color: "#9A9FAF",
                }}
              />
            ) : null
          }
          disabled={isLoading}
          onClick={() => formRef.current?.requestSubmit()}
        >
          {t("button.save")}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default JobBasicQuestionsEditDialog;
