import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IKOQuestionStartDateField } from "../../../models/JobPreview";
import { StyledKoQuestionContainer } from "./styles";

const KOQuestionStartDate = ({
  t,
  control,
  errors,
  locale,
}: IKOQuestionStartDateField) => (
  <StyledKoQuestionContainer>
    <InputLabel className="required-label">
      {t("create_job_third_step.earliest_start_date")}{" "}
    </InputLabel>
    <Controller
      name="earliestStartDate"
      control={control}
      render={({ field: { onChange, value } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          <DatePicker
            disablePast
            sx={{ width: "100%" }}
            format="DD.MM.YYYY"
            value={value ? dayjs(value) : null}
            onChange={(date) => {
              onChange(
                date && dayjs(date).isValid()
                  ? dayjs(date).format("YYYY-MM-DD")
                  : null
              );
            }}
            slotProps={{
              textField: {
                error: !!errors.earliestStartDate,
                helperText: errors.earliestStartDate?.message,
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  </StyledKoQuestionContainer>
);

export default KOQuestionStartDate;
