import React from "react";
import { InputLabel } from "@mui/material";
import { Controller, FieldError } from "react-hook-form";
import AutocompleteFilter from "../../../components/filters/AutocompleteFilter";
import { IKOQuestionControlledMultipleAutocomplete } from "../../../models/JobPreview";
import { StyledKoQuestionContainer } from "./styles";

const KoQuestionMultipleSelection = ({
  control,
  value,
  options,
  filterOptions,
  title,
  error,
  placeholder,
  companyColor,
}: IKOQuestionControlledMultipleAutocomplete) => (
  <StyledKoQuestionContainer companyColor={companyColor}>
    <InputLabel className="required-label">{title}</InputLabel>
    <Controller
      name={value}
      control={control}
      render={({ field: { onChange, value } }) => (
        <AutocompleteFilter
          multiple
          size={"small"}
          onChange={onChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={value || []}
          options={options}
          filterOptions={filterOptions}
          placeholder={value.length > 0 ? "" : placeholder}
          errors={error as FieldError}
        />
      )}
    />
  </StyledKoQuestionContainer>
);

export default KoQuestionMultipleSelection;
