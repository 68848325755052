import React from "react";
import { Stack } from "@mui/material";
import MainInfo from "./MainInfo";
import Loading from "../../components/Loading";
import ConnectProfile from "./ConnectProfile";
import ConnectEmail from "./ConnectEmail";
import { IUserSettingsComponent } from "../../models/CurrentUser";

const UserSettingsComponent = ({
  t,
  isLoading,
  desktop,
  oldValue,
  newValue,
  isCandidate,
  language,
  languages,
  photo,
  outlookEmail,
  gmailEmail,
  isSaveUserDataButtonDisabled,
  isConnectAccountButtonDisabled,
  onAuthDisconnectGmail,
  onAuthDisconnectOutlook,
  onRemovePhoto,
  onOpenCloseDialog,
  onChangeValue,
  onUpdateData,
}: IUserSettingsComponent) => (
  <Stack mt={3.75} spacing={3}>
    {isLoading ? (
      <Loading />
    ) : (
      <>
        <MainInfo
          t={t}
          desktop={desktop}
          isCandidate={Boolean(isCandidate)}
          oldValue={oldValue}
          photo={photo}
          language={language}
          languages={languages}
          newValue={newValue}
          isSaveUserDataButtonDisabled={isSaveUserDataButtonDisabled}
          onOpenCloseDialog={onOpenCloseDialog}
          onChangeValue={onChangeValue}
          onUpdateData={onUpdateData}
          onRemovePhoto={onRemovePhoto}
        />
        {!isCandidate && (
          <ConnectEmail
            t={t}
            gmailEmail={gmailEmail}
            outlookEmail={outlookEmail}
            onAuthDisconnectGmail={onAuthDisconnectGmail}
            onAuthDisconnectOutlook={onAuthDisconnectOutlook}
          />
        )}
        {isCandidate && (
          <ConnectProfile
            t={t}
            desktop={desktop}
            oldValue={oldValue}
            newValue={newValue}
            isConnectAccountButtonDisabled={isConnectAccountButtonDisabled}
            onChangeValue={onChangeValue}
            onUpdateData={onUpdateData}
          />
        )}
      </>
    )}
  </Stack>
);

export default UserSettingsComponent;
