import React from "react";
import { Stack } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import ImageUpload from "../../components/ImageUpload";
import { TFunction } from "i18next";
import { TCareerPageFormDefaultValue } from "../../models/CompanySettings";

const CompanyImagesBox = ({
  t,
  control,
}: {
  readonly t: TFunction;
  readonly control: Control<TCareerPageFormDefaultValue>;
}) => (
  <Stack width="100%" direction="row" spacing={3}>
    <Stack width="33%">
      <Controller
        name="picture_one"
        control={control}
        render={({ field: { onChange, value } }) => (
          <ImageUpload
            isCareerPage
            title={t("create_job_first_step.upload_photo", {
              number: 1,
            })}
            subtitle={t("create_job_first_step.photo_size")}
            onChange={(file: File | undefined) => {
              onChange(file || null);
            }}
            image={value}
          />
        )}
      />
    </Stack>
    <Stack width="33%">
      <Controller
        name={"picture_two"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <ImageUpload
            isCareerPage
            title={t("create_job_first_step.upload_photo", {
              number: 2,
            })}
            subtitle={t("create_job_first_step.photo_size")}
            onChange={(file: File | undefined) => {
              onChange(file || null);
            }}
            image={value}
          />
        )}
      />
    </Stack>
    <Stack width="33%">
      <Controller
        name={"picture_three"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <ImageUpload
            isCareerPage
            title={t("create_job_first_step.upload_photo", {
              number: 3,
            })}
            subtitle={t("create_job_first_step.photo_size")}
            onChange={(file: File | undefined) => {
              onChange(file || null);
            }}
            image={value}
          />
        )}
      />
    </Stack>
  </Stack>
);

export default CompanyImagesBox;
