import { TFunction } from "i18next";
import { KeyboardEventHandler, MouseEventHandler } from "react";

// api

export type TApiGetMessages = {
  readonly job_id?: string | number | null;
  readonly application_id?: string | number | null;
  readonly recruiter_id?: string | number | null;
  readonly candidate_id?: string | number | null;
};

export type TApiPostMessage = {
  readonly job_id?: string | number | null;
  readonly application_id?: string | number | null;
  readonly recruiter_id?: string | number | null;
  readonly candidate_id?: string | number | null;
  readonly text: string;
};

export type TApiDeleteMessage = {
  readonly job_id?: string | number | null;
  readonly application_id?: string | number | null;
  readonly message_id: number | null;
  readonly snackbar_title: string;
};

export type TApiUpdateMessage = {
  readonly job_id?: string | number | null;
  readonly application_id?: string | number | null;
  readonly message_id: number | null;
  readonly text: string;
};

export type TApiCompanyReadUnreadMessage = {
  readonly application_id: string | number;
  readonly candidate_id: string | number;
  readonly job_id: string | number;
  readonly isNewMessage: boolean;
};

export type TApiCandidateReadUnreadMessage = {
  readonly application_id: string | number;
  readonly recruiter_id: string | number;
  readonly job_id: string | number;
  readonly isNewMessage: boolean;
};

//
export interface IMessagesState {
  readonly isLoading: boolean;
  readonly error: boolean;
  readonly messages: TMessages;
  readonly latestMessages?: TLatestMessage[];
  readonly jobId?: string | number | null;
  readonly recruiterId?: string | number | null;
  readonly applicationId?: string | number | null;
  readonly messagesInfo?: TMessagesInfo | null;
  readonly filter: string;
}

export interface IMessagesPage {
  readonly desktop: boolean;
}

export interface IMessagesComponent extends IMessagesPage {
  readonly t: TFunction;
  readonly recruiterId?: string | number | null;
  readonly messagesInfo?: TMessagesInfo | null;
  readonly messages: TMessages;
  readonly latestMessages: TLatestMessage[];
  readonly firstname?: string;
  readonly lastname?: string;
  readonly isCandidate?: boolean;
  readonly editMessageId: number | null;
  readonly deleteMessageId: number | null;
  readonly profilePhoto?: string;
  readonly openedDialogType: MESSAGES_DIALOG_TYPE | null;
  readonly activeMessageId: number | null;
  readonly editMessageDefaultValue: string;
  readonly anchorEl: HTMLDivElement | null;
  readonly isSearchActive: boolean;
  readonly onActivateSearch: () => void;
  readonly handleReadUnread: (
    user: TLatestMessage,
    isNewMessage: boolean
  ) => void;
  readonly setActiveMessageId: (id: number | null) => void;
  readonly setEditMessageId: (id: number | null) => void;
  readonly setDeleteMessageId: (id: number | null) => void;
  readonly openJob: () => void;
  readonly handleEdit: (event: React.MouseEvent<SVGSVGElement>) => void;
  readonly handleSetActiveUser: (user: TApiGetMessages) => void;
  readonly onOpenDialog: ({ type }: { type: MESSAGES_DIALOG_TYPE }) => void;
  readonly onCloseDialog: () => void;
  readonly onSaveDialog: () => void;
  readonly handleSendMessage: (e: KeyboardEvent) => void;
  readonly handleCloseUserMenu: () => void;
  readonly handleOpenUserMenu: (
    event: React.TouchEvent<HTMLDivElement>
  ) => void;
}

export interface IHeaderComponent {
  readonly t: TFunction;
  readonly recruiterId?: string | number | null;
  readonly messagesInfo?: TMessagesInfo | null;
  readonly desktop: boolean;
  readonly isSearchActive: boolean;
  readonly onActivateSearch: () => void;
  readonly onOpenDialog: ({ type }: { type: MESSAGES_DIALOG_TYPE }) => void;
  readonly openJob: () => void;
}

export interface IUserListComponent {
  readonly t: TFunction;
  readonly desktop: boolean;
  readonly recruiterId?: string | number | null;
  readonly latestMessages: TLatestMessage[];
  readonly anchorEl: HTMLDivElement | null;
  readonly isSearchActive: boolean;
  readonly handleCloseUserMenu: () => void;
  readonly handleOpenUserMenu: (
    event: React.TouchEvent<HTMLDivElement>
  ) => void;
  readonly handleReadUnread: (
    user: TLatestMessage,
    isNewMessage: boolean
  ) => void;
  readonly handleSetActiveUser: (user: TApiGetMessages) => void;
}

export interface IUserComponent {
  readonly t: TFunction;
  readonly recruiterId?: string | number | null;
  readonly user: TLatestMessage;
  readonly desktop: boolean;
  readonly users: TLatestMessage[];
  readonly anchorEl: HTMLDivElement | null;
  readonly index: number;
  readonly handleCloseUserMenu: () => void;
  readonly handleOpenUserMenu: (
    event: React.TouchEvent<HTMLDivElement>
  ) => void;
  readonly handleReadUnread: (
    user: TLatestMessage,
    isNewMessage: boolean
  ) => void;
  readonly handleSetActiveUser: (user: TApiGetMessages) => void;
}

export interface IUserMenuComponent {
  readonly t: TFunction;
  readonly anchorEl: HTMLDivElement | null;
  readonly currentUser: TLatestMessage;
  readonly handleReadUnread: (
    user: TLatestMessage,
    isNewMessage: boolean
  ) => void;
  readonly handleCloseUserMenu: () => void;
}

export interface IMessagesListComponent {
  readonly t: TFunction;
  readonly desktop: boolean;
  readonly recruiterId?: string | number | null;
  readonly firstname?: string;
  readonly lastname?: string;
  readonly isCandidate?: boolean;
  readonly messagesInfo?: TMessagesInfo | null;
  readonly messages: TMessages;
  readonly editMessageId: number | null;
  readonly deleteMessageId: number | null;
  readonly profilePhoto?: string;
  readonly activeMessageId: number | null;
  readonly editMessageDefaultValue: string;
  readonly isSearchActive: boolean;
  readonly setActiveMessageId: (id: number | null) => void;
  readonly handleEdit: (event: React.MouseEvent<SVGSVGElement>) => void;
  readonly setDeleteMessageId: (id: number | null) => void;
  readonly onOpenDialog: ({ type }: { type: MESSAGES_DIALOG_TYPE }) => void;
  readonly handleSendMessage: (e: KeyboardEvent) => void;
}

export type TMessage = {
  readonly text: string;
  readonly created_at: string;
  readonly updated_at: string;
  readonly message_id: number;
  readonly origin: string;
};

export type IEditMessageComponent = {
  readonly t: TFunction;
  readonly item: TMessage;
  readonly messagesInfo?: TMessagesInfo | null;
  readonly loggedUser: string | undefined;
  readonly firstname?: string;
  readonly lastname?: string;
  readonly profilePhoto?: string;
  readonly editMessageDefaultValue: string;
  readonly onOpenDialog: ({ type }: { type: MESSAGES_DIALOG_TYPE }) => void;
};

export type IMessageComponent = {
  readonly t: TFunction;
  readonly message: TMessage;
  readonly messagesInfo?: TMessagesInfo | null;
  readonly loggedUser: string | undefined;
  readonly firstname?: string;
  readonly lastname?: string;
  readonly profilePhoto?: string;
  readonly activeMessageId: number | null;
  readonly setActiveMessageId: (id: number | null) => void;
  readonly handleEdit: (event: React.MouseEvent<SVGSVGElement>) => void;
  readonly setDeleteMessageId: (id: number | null) => void;
  readonly onOpenDialog: ({ type }: { type: MESSAGES_DIALOG_TYPE }) => void;
};

export type INewMessageComponent = {
  readonly t: TFunction;
  readonly desktop: boolean;
  readonly sendMessage:
    | ((e: KeyboardEvent) => void)
    | KeyboardEventHandler<HTMLTextAreaElement>
    | MouseEventHandler<SVGSVGElement>
    | undefined;
};

export type IDialogComponent = {
  readonly t: TFunction;
  readonly openedDialogType: MESSAGES_DIALOG_TYPE | null;
  readonly onCloseDialog: () => void;
  readonly onSaveDialog: () => void;
};

export type TUserPhoto = string | null | undefined;

export type TMessages = TMessage[];

export type TLatestMessage = {
  readonly recruiter_id: number | string;
  readonly candidate_id: number | string;
  readonly recruiter_name: string;
  readonly candidate_name: string;
  readonly recruiter_photo: string;
  readonly candidate_photo: string;
  readonly text: string;
  readonly job_title: string;
  readonly job_id: number;
  readonly application_id: number | string;
  readonly created_at: string;
  readonly is_new_messages: string | number;
  readonly is_candidate_replied: boolean;
  readonly is_recruiter_replied: boolean;
  readonly company_name?: string | null;
};

export type TMessagesInfo = {
  readonly application_id: number;
  readonly member_id: number;
  readonly firstname: string;
  readonly lastname: string;
  readonly profile_photo: string;
  readonly company_name: string;
  readonly job_title: string;
  readonly job_id: number;
  readonly job_url_key: string;
  readonly applied_date: string;
};

export enum MESSAGES_DIALOG_TYPE {
  DISCARD = "discard",
  CONFIRMATION = "confirmation",
  DELETE = "delete",
  NEW_CHAT = "new-chat",
}
