import React from "react";
import FirstStepsBox from "./FirstStepsBox";
import SupportBox from "./SupportBox";
import DashboardJobs from "./Jobs";
import DashboardApplications from "./Applications";
import { StyledDashboardRow, StyledDashboardWrapper } from "./styles";
import { TFunction } from "i18next";

const DashboardComponent = ({
  t,
  isAgency,
}: {
  t: TFunction;
  isAgency: boolean;
}) => (
  <StyledDashboardWrapper>
    <StyledDashboardRow>
      <FirstStepsBox />
      <SupportBox t={t} />
    </StyledDashboardRow>
    <StyledDashboardRow maxHeight={359 + 48}>
      {/* 359px - min height of application block + 48px application block total padding */}
      <DashboardJobs />
      {!isAgency && <DashboardApplications />}
    </StyledDashboardRow>
  </StyledDashboardWrapper>
);

export default DashboardComponent;
