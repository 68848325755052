import React, { useState, useEffect } from "react";
import { debounce } from "debounce";
import { Stack, Button, Avatar, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  StyledHeaderContainer,
  StyledLeftHeaderSideContainer,
  StyledRightHeaderSideContainer,
  StyledHeaderJobTitleTypography,
  StyledNameTypography,
  StyledSubtitleTypography,
  StyledMessageButton,
} from "./styles";
import { IHeaderComponent, MESSAGES_DIALOG_TYPE } from "../../models/Messages";
import { generateFullDate } from "../../utils";
import { getAllApplications } from "../../store/selectors/Applications";
import { TApplication } from "../../models/Applications";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
} from "../../store/selectors/CurrentUser";
import { MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { setMessageFilter } from "../../store/reducers/Messages";

const HeaderComponent = ({
  t,
  recruiterId,
  messagesInfo,
  desktop,
  isSearchActive,
  onActivateSearch,
  onOpenDialog,
  openJob,
}: IHeaderComponent) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeCandidate, setActiveCandidate] = useState<TApplication | null>(
    null
  );

  const allApplications = useSelector(getAllApplications);
  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const { email } = useSelector(getCurrentUserData);
  const jobTitle = messagesInfo?.job_title;

  const debouncedHandleChangeFilter = debounce((value: string) => {
    dispatch(setMessageFilter(value));
  }, 500);

  const handleOpenCandidate = () => {
    if (isCandidate) {
      navigate(`/job/${messagesInfo?.job_url_key}`);
    } else {
      navigate(
        `/applications/candidate/${activeCandidate?.candidate_url_key}/${activeCandidate?.job_id}`
      );
    }
  };

  useEffect(() => {
    if (isCandidate) {
      const findCandidate: any = allApplications?.find((item: TApplication) => {
        return item.id == messagesInfo?.application_id;
      });
      if (findCandidate) {
        const fullCandidate: TApplication = {
          email,
          ...findCandidate,
        };
        setActiveCandidate(fullCandidate);
      }
    } else {
      const findCandidate = allApplications?.find(
        (item) => Number(item?.application_id) == messagesInfo?.application_id
      );
      if (findCandidate) {
        setActiveCandidate(findCandidate);
      }
    }
  }, [messagesInfo]);

  return (
    <StyledHeaderContainer isSearchActive={isSearchActive}>
      <Stack
        height="auto"
        p={3}
        width={345}
        spacing={1.5}
        border="1px solid #E5E7EB"
      >
        <StyledLeftHeaderSideContainer>
          <StyledNameTypography>Chat</StyledNameTypography>
          <Stack direction="row" spacing={1.5}>
            <StyledMessageButton variant="contained" onClick={onActivateSearch}>
              <MagnifyingGlassIcon />
            </StyledMessageButton>
            {isCandidate === false && (
              <StyledMessageButton
                variant="contained"
                onClick={() =>
                  onOpenDialog({ type: MESSAGES_DIALOG_TYPE.NEW_CHAT })
                }
              >
                <PlusIcon />
              </StyledMessageButton>
            )}
          </Stack>
        </StyledLeftHeaderSideContainer>
        {isSearchActive && (
          <TextField
            id="message-search"
            onChange={(e) => debouncedHandleChangeFilter(e.target.value)}
            placeholder={t("applications.search_placeholder") as string}
          />
        )}
      </Stack>
      {recruiterId && messagesInfo && (
        <StyledRightHeaderSideContainer desktop={desktop}>
          <Stack direction="row" justifyContent="center" spacing={1.25}>
            <Avatar src={messagesInfo?.profile_photo} />
            <Stack height="100%" justifyContent="space-between">
              <Stack direction="row" spacing={0.5}>
                <StyledNameTypography>
                  {messagesInfo?.firstname} {messagesInfo?.lastname}{" "}
                </StyledNameTypography>
                {isCandidate && messagesInfo && (
                  <StyledNameTypography>
                    - {messagesInfo?.company_name}
                  </StyledNameTypography>
                )}
              </Stack>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <StyledSubtitleTypography>
                  {t("messages_page.applied_to_job_one")}{" "}
                </StyledSubtitleTypography>
                <StyledHeaderJobTitleTypography onClick={openJob}>
                  {jobTitle}{" "}
                </StyledHeaderJobTitleTypography>
                <StyledSubtitleTypography>
                  {t("messages_page.on")}{" "}
                  {generateFullDate(messagesInfo?.applied_date)}
                </StyledSubtitleTypography>
              </Stack>
            </Stack>
          </Stack>
          <Button variant="contained" onClick={handleOpenCandidate}>
            {isCandidate
              ? t("messages_page.show_job_details")
              : t("messages_page.show_profile")}
          </Button>
        </StyledRightHeaderSideContainer>
      )}
    </StyledHeaderContainer>
  );
};

export default HeaderComponent;
